import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class ShutAccount extends Component {
  state = {};
  render() {
    const { isAuthenticated, user } = this.props.auth;
    if (!isAuthenticated || typeof user.email === "undefined") return;
    return (
      <div className="dangerous-opt">
        <form>
          <button type="submit" className="btn btn-danger ">
            <FormattedMessage
              id="daccount.del"
              defaultMessage="Delete Account"
            />{" "}
            ({user.email})
          </button>
          <h6 className="m-2">
            <FormattedMessage
              id="daccount.info"
              defaultMessage="This operation will remove your account, your profile, but not the comments, articles etc that you have published earlier"
            />
          </h6>
        </form>
      </div>
    );
  }
}
ShutAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {}
)(ShutAccount);
