import axios from "axios";

// function get(path, params = null) {
//   return axios({
//     method: "get",
//     url: path,
//     params,
//     withCredentials: true
//   }).then(resp => resp.data);
// }

// function post(path, data, params = null) {
//   return axios({
//     method: "post",
//     url: path,
//     data,
//     params,
//     withCredentials: true
//   });
// }

// axios.interceptors.request.use(
//   success => {
//     console.log(
//       `Request: ${success.method.toUpperCase()} ${success.url} ${JSON.stringify(
//         success.data
//       )}`
//     );

//     return success;
//   },
//   error => {
//     console.log("CLIENT ERROR: ", error);
//     return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(
//   success => {
//     console.log(`Response: ${success.status} ${JSON.stringify(success.data)}`);
//     return success;
//   },
//   error => {
//     const expectedError =
//       error.response &&
//       error.response.status >= 400 &&
//       error.response.status < 500;

//     if (!expectedError) {
//       console.log("SERVER ERROR: ", error);
//     }

//     return Promise.reject(error);
//   }
// );

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
};
