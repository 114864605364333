import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape
} from "react-intl";
import Opt from "./Opt";
import { getAvatar, getFlag } from "../../assets/utils/func";

class Card extends Component {
  renderCard = someone => {
    const { avatar, name, nationality, birthday, gender, location } = someone;
    return (
      <div className="normal-intro">
        <img src={getAvatar(avatar)} alt={name} className="img-fluid" />
        <span className="name-info">{name}</span>
        <span className="age-info">
          {birthday && moment(birthday).toNow(true)}{" "}
          {gender && (
            <i
              className={`fas fa-${
                gender.toLowerCase() === "male" ? "mars male" : "venus female"
              }`}
            />
          )}
        </span>
        <span className="location-info">
          <i className="fas fa-map" /> {location}
        </span>
        <span className="nationality-info">
          <img
            src={getFlag(nationality)}
            className="img-flag"
            alt={nationality}
          />
        </span>
      </div>
    );
  };
  getStage = stage => {
    switch (stage) {
      case "Begging":
        return "quarter";
      case "Intermediate":
        return "half";
      case "Fluent":
        return "three-quarters";
      default:
        return "full";
    }
  };
  renderLang = language => {
    if (!language) {
      return null;
    }
    return (
      <div className="lang-intro">
        {language.map(lang => (
          <span className="lang-item" key={lang._id}>
            {lang.lang}{" "}
            <i className={`fas fa-battery-${this.getStage(lang.stage)}`} />
          </span>
        ))}
      </div>
    );
  };
  renderSocial = links => {
    if (!links || Object.keys(links).length === 0) {
      return null;
    }
    return (
      <div className="social-intro">
        <p className="text-muted">
          <FormattedMessage id="profile.social" defaultMessage="Social links" />
        </p>
        {Object.keys(links).map(link => (
          <Link className="pr-2 social-link" to={links[link]} key={link}>
            <i className={`fab fa-${link} fa-2x`} />
          </Link>
        ))}
      </div>
    );
  };
  renderHobbies = hobbies => {
    if (hobbies && hobbies.length === 0) {
      return null;
    }
    return (
      <div className="hobbies-intro mb-3">
        <p className="text-muted">
          <FormattedMessage
            id="profile.hobby"
            defaultMessage="{num, number} {num, plural, one {hobby} other {hobbies}}"
            values={{ num: hobbies.length }}
          />
        </p>
        <input type="checkbox" className="read-more-state" id="read-more-1" />
        <div className="read-more-wrapper">
          {hobbies.map((hobby, i) => (
            <span
              className={`hobby-item pr-2 ${i > 4 ? "read-more-target" : ""}`}
              key={hobby}
            >
              <i className="fa fa-check" /> {hobby}
            </span>
          ))}
        </div>
        <label
          htmlFor="read-more-1"
          className="read-more"
          data-more={this.props.intl.formatMessage({ id: "profile.more" })}
          data-less={this.props.intl.formatMessage({ id: "profile.less" })}
        />
      </div>
    );
  };
  renderBio = someone => {
    if (someone.bio === "") {
      return (
        <div className="bio-info">
          <p className="text-muted">
            <FormattedHTMLMessage
              id="profile.bio"
              defaultMessage="It looks like that <i>{name}</i> has nothing to say ..."
              values={{ name: someone.name }}
            />
          </p>
        </div>
      );
    }
    return (
      <div className="bio-info">
        <p className="text-muted">{someone.bio}</p>
      </div>
    );
  };
  renderFriends = friends => {
    if (friends.length === 0) {
      return null;
    }
    return (
      <div className="friend-list">
        <p className="text-muted">
          <FormattedMessage
            id="profile.friend"
            defaultMessage="{num, number} {num, plural, one {friend} other {friends}}"
            values={{ num: friends.length }}
          />
        </p>
        {friends.map(friend => (
          <div className="friend" key={friend._id}>
            <Link to={`/profile/${friend._id}`} className="friend-link">
              <img
                src={getAvatar(friend.avatar)}
                className="img-circle-bg"
                alt={friend.name}
              />
              <p>{friend.name}</p>
            </Link>
          </div>
        ))}
      </div>
    );
  };
  render() {
    const { user, friends } = this.props;
    return (
      <div className="profile-detail">
        {this.renderCard(user)}
        {this.renderLang(user.language)}
        {this.renderSocial(user.social)}
        {this.renderHobbies(user.hobbies)}
        {this.renderBio(user)}
        {this.renderFriends(friends)}
        <div className="profile-operations">
          <Opt user={user} friends={friends} />
        </div>
      </div>
    );
  }
}
Card.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(Card);
