import React, { Component } from "react";
import { Link } from "react-router-dom";
import toastr from "toastr";
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape
} from "react-intl";
import http from "../../services/http";
import TextModernField from "../common/TextModern";
import { validateEmail } from "../../assets/utils/func";
import loader from "../../assets/img/loading.gif";
import "./auth.scss";

class PasswordRecover extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
      submit: false,
      emailStatus: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }
  onSubmit(e) {
    e.preventDefault();
    const { email, errors } = this.state;
    const bool = validateEmail(email);
    if (!bool) {
      errors.email = this.props.intl.formatMessage({ id: "pass.format" });
      this.setState({ errors });
      return null;
    }
    this.setState({ submit: true });
    this.doSubmit(email);
  }
  doSubmit = email => {
    http
      .post("/api/users/pwd-reset", { email })
      .then(res => {
        this.setState({ submit: false });
        if (typeof res.data !== undefined && !res.data.success) {
          toastr.warning(res.data.error);
        } else if (typeof res.data !== undefined && res.data.success) {
          toastr.success(this.props.intl.formatMessage({ id: "pass.success" }));
        }
      })
      .catch(err => {
        this.setState({ submit: false });
        toastr.warning(err);
      });
  };
  render() {
    const { email, errors, emailStatus, submit } = this.state;
    const { intl } = this.props;
    const emailPlacehoder = intl.formatMessage({
      id: "login.email-placeholder"
    });
    return (
      <div className="password-recover container">
        <div className="flex-center">
          <div className="note">
            <h2 className="m-2">
              <FormattedMessage
                id="pass.title"
                defaultMessage="Password Recover"
              />
            </h2>
            <p className="text-muted">
              <FormattedHTMLMessage
                id="pass.hint"
                defaultMessage="Note: If you didn't verify your email when setting up this account,we won't trust the email you submitted here, please contact support <b>support@donacoo.com</b> to recover your password"
              />
            </p>
            <br />
            {emailStatus ? (
              <div className="link-success">
                <h3>
                  <FormattedMessage
                    id="pass.success"
                    defaultMessage="Email sent, please check your mailbox and verify it in 2 hours"
                  />
                </h3>
                <Link to="/">
                  <FormattedMessage id="pass.link" defaultMessage="Home" />
                </Link>
              </div>
            ) : (
              <form onSubmit={this.onSubmit} noValidate className="pwd-recover">
                <TextModernField
                  name="email"
                  placeholder={emailPlacehoder}
                  value={email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <button type="submit" className="btn btn-ctl btn-block">
                  {submit ? (
                    <img className="refresh" src={loader} alt="refresh" />
                  ) : (
                    <span className="load">
                      <FormattedMessage id="pass.btn" defaultMessage="Send" />
                    </span>
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}
PasswordRecover.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(PasswordRecover);
