import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { FormattedRelative } from "react-intl";
import http from "../../services/http";
import {
  update_article_comments,
  delete_article_comments
} from "../../actions/commentActions";
import RatingComment from "../rating/RatingComment";
import { getAvatar, getCommentCharLimit } from "../../assets/utils/func";

class CommentSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: props.comment,
      editorState: EditorState.createEmpty(),
      readOnly: true,
      toolbarHidden: true,
      editable: false,
      length: 0,
      limit: 140
    };
  }
  componentDidMount() {
    const { comment, auth } = this.props;
    const { isAuthenticated, user } = auth;
    let editable = false;
    let limit = 140;
    if (isAuthenticated) {
      editable = user.id === comment.author._id ? true : false;
      const { level } = this.props.auth.user;
      limit = getCommentCharLimit(level);
    }
    const editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(comment.content))
    );
    this.setState({ editorState, editable, limit });
  }
  count = editorState => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return raw.blocks
      .map(b => b.text.length)
      .reduce((a, b) => {
        return a + b;
      }, 0);
  };
  onEditorStateChange = editorState => {
    let length = this.count(editorState);
    this.setState({
      editorState,
      length
    });
  };
  uploadImageCallBack = file => {
    let formData = new FormData();
    formData.append("comment", file);
    return http
      .post("/api/comments/comment/image", formData)
      .then(res => {
        return { data: { link: res.data } };
      })
      .catch(err => console.log(err.message));
  };
  startEdit = () => {
    this.setState({
      readOnly: false,
      toolbarHidden: false
    });
  };
  handleCancel = () => {
    const editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(this.state.comment.content))
    );
    this.setState({ editorState, readOnly: true, toolbarHidden: true });
  };
  handleUpdateComment = async (c_id, editorState) => {
    this.setState({ readOnly: true, toolbarHidden: true });
    let comment = {};
    comment.content = convertToRaw(editorState.getCurrentContent());
    try {
      await this.props.update_article_comments(c_id, comment);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Update comment failed");
      this.setState({ readOnly: false, toolbarHidden: false });
    }
  };
  handleDeleteComment = async c_id => {
    try {
      await this.props.delete_article_comments(c_id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Delete comment failed");
    }
  };
  render() {
    const {
      editorState,
      readOnly,
      toolbarHidden,
      editable,
      length,
      limit
    } = this.state;
    const { comment, suggestions } = this.props;
    const { name, avatar } = comment.author;

    return (
      <li className="media comment-list-item">
        <div className="comment-left">
          <img className="mr-3 img-media" src={getAvatar(avatar)} alt={name} />
          <br />
          {editable && readOnly && (
            <React.Fragment>
              <button type="button" className="btn" onClick={this.startEdit}>
                <i className="fas fa-edit" />
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => this.handleDeleteComment(comment._id)}
              >
                <i className="fas fa-trash" />
              </button>
            </React.Fragment>
          )}
          {!readOnly && (
            <React.Fragment>
              <button
                type="button"
                className="btn"
                onClick={() =>
                  this.handleUpdateComment(comment._id, editorState)
                }
              >
                <div className="fas fa-sync-alt" />
              </button>
              <button type="button" className="btn" onClick={this.handleCancel}>
                <div className="fas fa-undo" />
              </button>
            </React.Fragment>
          )}
        </div>
        <div className="media-body">
          <h5 className="mt-0 mb-1" style={{ display: "inline-block" }}>
            {name}
          </h5>
          <span className="m-2" style={{ float: "right" }}>
            <FormattedRelative value={comment.updatedAt} />
          </span>
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-comment-single"
            editorClassName="editor-comment-single"
            toolbarClassName="toolbar-comment-single"
            readOnly={readOnly}
            toolbarHidden={toolbarHidden}
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{
              options: ["inline", "colorPicker", "link", "emoji", "image"],
              link: { options: ["link"] },
              inline: { options: ["bold", "italic"] },
              image: {
                alt: { present: true, mandatory: true },
                previewImage: true,
                uploadCallback: this.uploadImageCallBack
              }
            }}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: suggestions
            }}
          />
          {!readOnly && (
            <p className="text-muted text-right">
              <span className={length > limit ? "text-danger" : ""}>
                {length}
              </span>
              /{limit}
            </p>
          )}
          {readOnly && <RatingComment c_id={comment._id} />}
        </div>
      </li>
    );
  }
}
CommentSingle.propTypes = {
  update_article_comments: PropTypes.func.isRequired,
  delete_article_comments: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { update_article_comments, delete_article_comments }
)(CommentSingle);
