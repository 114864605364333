import http from "../services/http";
import { ACTIVITY_LOADING, SET_ACTIVITY } from "./types";
import { handleError } from "../assets/utils/handleError";

export const get_full_activity = u_id => dispatch => {
  dispatch({ type: ACTIVITY_LOADING });
  http
    .get(`/api/activity/full/${u_id}`)
    .then(res =>
      dispatch({
        type: SET_ACTIVITY,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

/*
export const get_profile_comments = u_id => dispatch => {
  dispatch({ type: ACTIVITY_LOADING });
  http
    .get(`/api/comments/profile/${u_id}`)
    .then(res =>
      dispatch({
        type: SET_ACTIVITY,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const create_profile_comments = (u_id, data) => dispatch => {
  http
    .post(`/api/comments/profile/${u_id}`, data)
    .then(res =>
      dispatch({
        type: CREATE_ACTIVITY,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const update_profile_comments = (u_id, data) => dispatch => {
  http
    .put(`/api/comments/profile/${u_id}`, data)
    .then(res =>
      dispatch({
        type: UPDATE_ACTIVITY,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const delete_profile_comments = c_id => dispatch => {
  http
    .delete(`/api/comments/profile/${c_id}`)
    .then(res =>
      dispatch({
        type: DELETE_ACTIVITY,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
*/
