import React, { Component } from "react";
import Avatar from "./Avatar";
import Password from "./Password";
import ShutAccount from "./ShutAccount";
import "./account.scss";

class Account extends Component {
  render() {
    return (
      <div className="account pt-2">
        <Avatar />
        <hr />
        <Password />
        <hr />
        <ShutAccount />
      </div>
    );
  }
}

export default Account;
