import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  searchMemberArticle,
  searchCategoryArticle
} from "../../services/search";
import { switchCategory } from "../../assets/utils/func";
import InfiniteLoading from "../common/InfiniteLoading";
import Scroll from "./Scroll";

class ArticleMember extends Component {
  constructor() {
    super();
    this.state = {
      mems: [],
      cates: [],
      fetch: true
    };
  }
  async componentDidMount() {
    const { u_id, category } = this.props;
    try {
      const mems = await searchMemberArticle(u_id);
      const cates = await searchCategoryArticle(switchCategory(category));
      this.setState({ mems: mems.data, fetch: false, cates: cates.data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.setState({ fetch: false });
      }
    }
  }
  render() {
    const { mems, cates, fetch } = this.state;
    if (fetch) return <InfiniteLoading heightt={70} />;
    return (
      <div className="member-articles">
        <h2>
          <FormattedMessage
            id="article.member"
            defaultMessage="Articles wrote by the same author:"
          />
        </h2>
        <div className="wrapper" id="wrapper1">
          {mems.map(data => (
            <Scroll key={data._id} data={data} pos="wrapper1" />
          ))}
        </div>
        <h2>
          <FormattedMessage
            id="article.category"
            defaultMessage="Articles in the same category:"
          />
        </h2>
        <div className="wrapper" id="wrapper2">
          {cates.map(data => (
            <Scroll key={data._id} data={data} pos="wrapper2" />
          ))}
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

export default ArticleMember;
