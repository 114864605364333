import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { answer_friend_request } from "../../../actions/friendActions";
import { getAvatar } from "../../../assets/utils/func";

class FriendRequest extends Component {
  handleAcceptRequest = async request => {
    const friend = request.from;
    try {
      await this.props.answer_friend_request(
        request._id,
        { accept: "true", f_id: friend._id },
        request,
        friend
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Answer accept failed");
      }
      const msg = this.props.intl.formatMessage({
        id: "dfriend.accept-failure"
      });
      toastr.warning(msg);
    }
  };
  handleIgnoredRequest = async request => {
    const friend = request.from;
    try {
      await this.props.answer_friend_request(
        request._id,
        { ignored: "true" },
        request,
        friend
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Answer ignored failed");
      }
      const msgg = this.props.intl.formatMessage({
        id: "dfriend.ignore-failure"
      });
      toastr.warning(msgg);
    }
  };
  render() {
    const { request } = this.props;
    return (
      <li className="list-group-item request-item">
        <Link to={`/profile/${request.from._id}`} className="request-people">
          <img
            src={getAvatar(request.from.avatar)}
            className="img-media"
            alt={request.from.name}
          />
        </Link>
        <div className="request-info">
          <span className="initiator">{request.from.name}</span>{" "}
          <FormattedMessage
            id="dfriend.message"
            defaultMessage="wants to make friends"
          />
          <p className="invitation">
            <i>{request.invitation}</i>
          </p>
        </div>
        <div className="ctl">
          <button
            type="button"
            className="btn btn-ctl"
            onClick={() => this.handleAcceptRequest(request)}
          >
            <FormattedMessage id="dfriend.accept" defaultMessage="Accept" />
          </button>
          <button
            type="button"
            className="btn btn-ctl"
            onClick={() => this.handleIgnoredRequest(request)}
          >
            <FormattedMessage id="dfriend.ignore" defaultMessage="Ignore" />
          </button>
        </div>
      </li>
    );
  }
}
FriendRequest.propTypes = {
  intl: intlShape.isRequired,
  answer_friend_request: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { answer_friend_request }
)(injectIntl(FriendRequest));
