import {
  SET_CURRENT_USER,
  LOAD_CURRENT_USER,
  UPDATE_AVATAR,
  UPDATE_COVER,
  UPDATE_PROFILE,
  ADD_HOBBIES,
  REMOVE_HOBBIES,
  ADD_LANGUAGE,
  REMOVE_LANGUAGE
} from "../actions/types";
import { isEmpty } from "../assets/utils/func";

const initialState = {
  isAuthenticated: false,
  user: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case LOAD_CURRENT_USER:
      return {
        ...state,
        user: mapProfile(state.user, action.payload)
      };
    case UPDATE_AVATAR:
      return {
        ...state,
        user: { ...state.user, avatar: action.payload.avatar }
      };
    case UPDATE_COVER:
      return {
        ...state,
        user: { ...state.user, cover: action.payload.cover }
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload }
      };
    case ADD_HOBBIES:
      return {
        ...state,
        user: { ...state.user, hobbies: action.payload }
      };
    case REMOVE_HOBBIES:
      return {
        ...state,
        user: { ...state.user, hobbies: action.payload }
      };
    case ADD_LANGUAGE:
      return {
        ...state,
        user: { ...state.user, language: action.payload }
      };
    case REMOVE_LANGUAGE:
      return {
        ...state,
        user: { ...state.user, language: action.payload }
      };
    default:
      return state;
  }
}

const mapProfile = (user, profile) => {
  if (user.id !== profile._id) {
    return user;
  } else {
    if (!isEmpty(profile.handle)) user.handle = profile.handle;
    if (!isEmpty(profile.gender)) user.gender = profile.gender;
    if (!isEmpty(profile.birthday)) user.birthday = profile.birthday;
    if (!isEmpty(profile.nationality)) user.nationality = profile.nationality;
    if (!isEmpty(profile.location)) user.location = profile.location;
    if (!isEmpty(profile.bio)) user.bio = profile.bio;
    if (!isEmpty(profile.social)) user.social = profile.social;
    if (!isEmpty(profile.cover)) user.cover = profile.cover;
    if (!isEmpty(profile.hobbies)) user.hobbies = profile.hobbies;
    if (!isEmpty(profile.language)) user.language = profile.language;
    if (!isEmpty(profile.level)) user.level = profile.level;
    return user;
  }
};
