import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import es from "react-intl/locale-data/es";

import messages_fr from "./localization/fr.json";
import messages_en from "./localization/en.json";
import messages_es from "./localization/es.json";

addLocaleData([...en, ...fr, ...es]);

const messages = {
  en: messages_en,
  fr: messages_fr,
  es: messages_es
};

export default messages;
