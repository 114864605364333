import React from "react";
import "./Loading.css";

const InfiniteLoading = ({ heightt = 300, content = "" }) => {
  const myStyle = {
    margin: `${heightt}px auto`
  };
  return (
    <div className="loading-animation" style={myStyle}>
      <div className="spinner infinity" id="infinity">
        <div className="half">
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
        </div>
        <div className="half">
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
          <div className="marker" />
        </div>
      </div>
      {content && <h4 className="text-muted m-3">{content}</h4>}
    </div>
  );
};

export default InfiniteLoading;
