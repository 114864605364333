import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import toastr from "toastr";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Modal from "../common/Modal";
import http from "../../services/http";

class Opt extends Component {
  constructor() {
    super();
    this.state = {
      request: "Hi there ...",
      abuse: "",
      successRequest: false,
      successAbuse: false
    };
    this.handleChange = this.handleChange.bind(this);
  }
  sendFriendRequest = async e => {
    e.preventDefault();
    const { user } = this.props;
    const invitation = this.state.request;

    try {
      const { data } = await http.post(`/api/friendships/${user._id}`, {
        invitation: invitation
      });
      if (data.success) {
        this.setState({ successRequest: true });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Sending friend request failed");
      }
      toastr.danger(this.props.intl.formatMessage({ id: "profile.fr-fail" }));
    }
  };
  sendAbuseRequest = e => {
    e.preventDefault();
    console.log("Modal Abuse button clicked..");
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  isFriend = (arr = [], obj = {}) => {
    let res = false;
    const u_id = obj._id || obj.id;
    if (arr.map(a => a._id).includes(u_id)) res = true;
    return res;
  };
  render() {
    const { auth, user, friends, intl } = this.props;
    const { request, successRequest } = this.state;
    const loginRequired = intl.formatMessage({ id: "app.login" });
    const level6Required = intl.formatMessage({ id: "app.level6" });
    if (!auth.isAuthenticated) {
      return (
        <div className="opt">
          <span
            className="d-inline-block tip"
            tabIndex="0"
            data-toggle="tooltip"
            data-placement="top"
            title={loginRequired}
          >
            <button type="button" className="btn btn-add" disabled="disabled">
              <FormattedMessage id="profile.fr" defaultMessage="Add Friend" />
            </button>
          </span>
          <span
            className="d-inline-block tip"
            tabIndex="0"
            data-toggle="tooltip"
            data-placement="top"
            title={level6Required}
          >
            <button type="button" className="btn btn-chat" disabled="disabled">
              <FormattedMessage id="profile.chat" defaultMessage="Chat" />
            </button>
          </span>
        </div>
      );
    }
    const isFriend = this.isFriend(friends, auth.user);
    const htmlFriendContent = (
      <form onSubmit={e => this.sendFriendRequest(e)}>
        <div className="sender">
          <h5>To: {user.name}</h5>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="request"
            className="form-control"
            onChange={this.handleChange}
            value={request}
          />
        </div>
        {successRequest && (
          <p className="text-muted">
            <FormattedMessage
              id="profile.fr-success"
              defaultMessage="Request sent successfully"
            />
          </p>
        )}
      </form>
    );
    const htmlFriendButton = (
      <button
        type="submit"
        className="btn btn-send"
        onClick={e => this.sendFriendRequest(e)}
      >
        <FormattedMessage id="profile.fr-send" defaultMessage="Send" />
      </button>
    );
    /* const htmlAbuseContent = (
      <form onSubmit={e => this.sendAbuseRequest(e)}>
        <div className="sender">
          <h5>
            <FormattedMessage id="profile.report" defaultMessage="Report" />:{" "}
            {user.name}
          </h5>
        </div>
        <div className="form-group">
          <textarea
            name="abuse"
            className="form-control"
            onChange={this.handleChange}
            value={abuse}
          />
        </div>
        {successAbuse && (
          <p className="text-muted">
            <FormattedMessage
              id="profile.report-success"
              defaultMessage="Report send successfully"
            />
          </p>
        )}
      </form>
    );
    const htmlAbuseButton = (
      <button
        type="submit"
        className="btn btn-send"
        onClick={e => this.sendAbuseRequest(e)}
      >
        <FormattedMessage id="profile.report" defaultMessage="Report" />
      </button>
    );
    */
    return (
      <div className="opt">
        <Modal
          htmlID="friendModal"
          htmlTitle={intl.formatMessage({ id: "profile.fr" })}
          htmlContent={htmlFriendContent}
          htmlButton={htmlFriendButton}
        />
        {/*<Modal
          htmlID="abuseModal"
          htmlTitle={intl.formatMessage({id: "profile.abuse"})}
          htmlContent={htmlAbuseContent}
          htmlButton={htmlAbuseButton}
        />*/}
        {auth.user.id !== user._id && !isFriend && (
          <button
            type="button"
            className="btn btn-add"
            data-toggle="modal"
            data-target="#friendModal"
          >
            <FormattedMessage id="profile.fr" defaultMessage="Add Friend" />
          </button>
        )}
        {auth.user.id !== user._id && (
          <span
            className="d-inline-block tip"
            tabIndex="0"
            data-toggle="tooltip"
            data-placement="top"
            title={level6Required}
          >
            <button type="button" className="btn btn-chat" disabled="disabled">
              <FormattedMessage id="profile.chat" defaultMessage="Chat" />
            </button>
          </span>
        )}
        {/*auth.user.id !== user._id && (
          <button
            type="button"
            className="btn btn-abuse"
            data-toggle="modal"
            data-target="#abuseModal"
          >
            <FormattedMessage id="profile.abuse" defaultMessage="Report Abuse" />
          </button>
        )*/}
      </div>
    );
  }
}
Opt.propTypes = {
  intl: intlShape.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  friends: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  {}
)(injectIntl(Opt));
