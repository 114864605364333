import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import toastr from "toastr";
import { injectIntl, intlShape } from "react-intl";
import Cover from "./Cover";
import SideNav from "./SideNav";
import Account from "./account/Account";
import Setting from "./account/Setting";
import Content from "./content/Content";
import ArticleCreate from "./article/ArticleCreate";
import ArticleUser from "./article/ArticleUser";
import Message from "./message/Message";
import Friend from "./friend/Friend";
import { loadProfileField } from "../../actions/userActions";
import "./Dashboard.css";

class Dashboard extends Component {
  state = { isOpen: true };
  componentDidMount() {
    const { isAuthenticated, user } = this.props.auth;
    if (!isAuthenticated) {
      this.props.history.push("/login");
    } else {
      this.loadProfile(user.id);
    }
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 732) this.setState({ isOpen: false });
  }
  loadProfile = async u_id => {
    try {
      await this.props.loadProfileField(u_id);
    } catch (ex) {
      const msg = this.props.intl.formatMessage({ id: "dash.load-fail" });
      toastr.warning(msg);
    }
  };
  handleOpen = e => {
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  render() {
    const { match, auth } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="dashboard">
        <Cover user={auth.user} />
        <SideNav onOpen={this.handleOpen} isOpen={isOpen} />
        <div className={`dashboard-content ${isOpen ? "show" : ""}`}>
          <Switch>
            <Route
              path={`${match.path}/articles`}
              render={props => <ArticleUser {...props} />}
            />
            <Route
              path={`${match.path}/article-create`}
              render={props => <ArticleCreate {...props} />}
            />
            <Route
              path={`${match.path}/messages`}
              render={props => <Message {...props} />}
            />
            <Route
              path={`${match.path}/friends`}
              render={props => <Friend {...props} />}
            />
            <Route
              path={`${match.path}/settings`}
              render={props => <Setting {...props} />}
            />
            <Route
              path={`${match.path}/account`}
              render={props => <Account {...props} />}
            />
            <Route exact path={`${match.path}/`} component={Content} />
          </Switch>
        </div>
      </div>
    );
  }
}
Dashboard.propTypes = {
  intl: intlShape.isRequired,
  loadProfileField: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { loadProfileField }
)(injectIntl(Dashboard));
