import React, { Component } from "react";
import "./support.scss";

class Support extends Component {
  state = {};
  render() {
    return (
      <div className="support container">
        <div className="jumbotron">
          <h1 className="display-4">Support</h1>
          <p className="lead">Support panel will come soon</p>
          <hr className="my-4" />
          <p>
            https://mail.donacoo.com are only for editors use only, it will be
            open free in the future.
          </p>
          <p>For more question please contact support@donacoo.com</p>
        </div>
      </div>
    );
  }
}

export default Support;
