import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { get_article_comments } from "../../actions/commentActions";
import CommentBox from "./CommentBox";
import CommentSingle from "./CommentSingle";
import InfiniteLoading from "../common/InfiniteLoading";
import "./CommentArticle.css";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class CommentArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.comArt.loading,
      comments: props.comArt.comments,
      errors: {}
    };
  }
  async componentDidMount() {
    const { a_id } = this.props;
    await this.props.get_article_comments(a_id);
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.comArt !== this.props.comArt) {
      this.setState({
        loading: nextProps.comArt.loading,
        comments: nextProps.comArt.comments
      });
    }
    if (nextProps.errors) this.setState({ errors: nextProps.errors });
  };
  getSuggestions = () => {
    const { comments } = this.state;
    const { author } = this.props;
    let suggestions = [];
    if (comments.length !== 0) {
      suggestions = comments
        .map(c => c.author)
        .map(a => {
          return {
            text: a.name,
            value: a.name.toLowerCase(),
            url: `/profile/${a._id}`
          };
        });
    }
    return _.uniqBy(
      suggestions.concat({
        text: author.name,
        value: author.name.toLowerCase(),
        url: `/profile/${author._id}`
      }),
      "url"
    );
  };
  render() {
    const { loading, comments } = this.state;
    const { isAuthenticated } = this.props.auth;
    const suggestions = this.getSuggestions();
    if (loading)
      return (
        <div className="comment-article shadow mx-auto">
          <InfiniteLoading
            heightt={30}
            content={
              <FormattedMessage id="app.loading" defaultMessage="Loading" />
            }
          />
        </div>
      );
    if (comments.length === 0 && !loading) {
      return (
        <div className="comment-article mx-auto">
          <h5>
            <FormattedMessage
              id="comment.no-comment"
              defaultMessage="No comments yet"
            />
          </h5>
          {!isAuthenticated && (
            <p className="text-muted">
              <i className="fas fa-info-circle mr-2" />{" "}
              <FormattedMessage
                id="comment.info"
                defaultMessage="Please note that you have to login to make a comment"
              />
            </p>
          )}
          <CommentBox a_id={this.props.a_id} suggestions={suggestions} />
        </div>
      );
    }
    return (
      <div className="comment-article mx-auto">
        {!isAuthenticated && (
          <p className="text-muted">
            <i className="fas fa-info-circle mr-2" />
            <FormattedMessage
              id="comment.info"
              defaultMessage="Please note that you have to login to make a comment"
            />
          </p>
        )}
        <CommentBox a_id={this.props.a_id} suggestions={suggestions} />
        <div className="comment-list">
          <h5>
            <FormattedMessage
              id="comment.num"
              defaultMessage="`{num, number} {num, plural, one {comment} other {comments}}`"
              values={{ num: comments.length }}
            />
          </h5>
          <ul className="list-unstyled">
            {comments.map(comment => (
              <CommentSingle
                key={comment._id}
                comment={comment}
                suggestions={this.getSuggestions}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
CommentArticle.propTypes = {
  get_article_comments: PropTypes.func.isRequired,
  comArt: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object
};
const mapStateToProps = state => ({
  auth: state.auth,
  comArt: state.comArt,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  {
    get_article_comments
  }
)(CommentArticle);
