import http from "../services/http";
import {
  ARTICLE_COMMENTS_LOADING,
  ARTICLE_SET_COMMENTS,
  ARTICLE_CREATE_COMMENTS,
  ARTICLE_UPDATE_COMMENTS,
  ARTICLE_DELETE_COMMENTS
} from "./types";
import { handleError } from "../assets/utils/handleError";

export const get_article_comments = a_id => dispatch => {
  dispatch({ type: ARTICLE_COMMENTS_LOADING });
  http
    .get(`/api/comments/article/${a_id}`)
    .then(res =>
      dispatch({
        type: ARTICLE_SET_COMMENTS,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const create_article_comments = (a_id, data) => dispatch => {
  http
    .post(`/api/comments/article/${a_id}`, data)
    .then(res =>
      dispatch({
        type: ARTICLE_CREATE_COMMENTS,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const update_article_comments = (c_id, data) => dispatch => {
  http
    .put(`/api/comments/article/${c_id}`, data)
    .then(res =>
      dispatch({
        type: ARTICLE_UPDATE_COMMENTS,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const delete_article_comments = c_id => dispatch => {
  http
    .delete(`/api/comments/article/${c_id}`)
    .then(res =>
      dispatch({
        type: ARTICLE_DELETE_COMMENTS,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
