import {
  MESSAGES_LOADING,
  SET_MESSAGES,
  SEND_MESSAGE,
  READ_MESSAGE,
  UPDATE_MESSAGE,
  DELETE_MESSAGE
} from "../actions/types";

const initialState = {
  loading: false,
  msgs: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MESSAGES_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_MESSAGES:
      return {
        ...state,
        msgs: action.payload,
        loading: false
      };
    case SEND_MESSAGE:
      return {
        ...state,
        msgs: [...state.msgs, action.payload]
      };
    case READ_MESSAGE:
      return {
        ...state,
        msgs: state.msgs.map(m =>
          m._id === action.payload._id ? { ...m, read: !m.read } : m
        )
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        msgs: state.msgs.map(m =>
          m._id === action.payload._id ? { ...action.payload } : m
        )
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        msgs: state.msgs.filter(m => m._id !== action.payload._id)
      };
    default:
      return state;
  }
}
