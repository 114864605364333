import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  FormattedRelative
} from "react-intl";
import { getAvatar, getCover } from "../../../assets/utils/func";

class ActivityDetail extends Component {
  getArticle = ({ _id, title }) => {
    return (
      <Link to={`/articles/${_id}`}>
        <span className="article_title">{title}</span>
      </Link>
    );
  };
  getActor = ({ _id, name, avatar }) => {
    return (
      <Link to={`/profile/${_id}`}>
        <img src={getAvatar(avatar)} className="img-circle" alt={name} />
        <span className="pl-2">{name}</span>
      </Link>
    );
  };
  getMention = mention => {
    if (typeof mention !== "undefined" && mention.length !== 0) {
      const mentioned = mention.map(({ _id, name, avatar }) => (
        <Link to={`/profile/${_id}`}>
          <img src={getAvatar(avatar)} className="img-circle" alt={name} />
        </Link>
      ));
      return (
        <p>
          {" "}
          <FormattedMessage id="activity.mention" defaultMessage="mentioned " />
          {mentioned}
        </p>
      );
    } else {
      return;
    }
  };
  renderActivity = () => {
    const {
      actor,
      verb,
      position,
      target,
      mention,
      createdAt
    } = this.props.activity;
    switch (verb) {
      case "A01":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage id="activity.create" defaultMessage="create " />
            <i>{this.getArticle(position)}</i>
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
          </li>
        );
      case "A02":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage id="activity.updated" defaultMessage="updated " />
            <i>{this.getArticle(position)}</i>
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
          </li>
        );
      case "A03":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage id="activity.read" defaultMessage="read " />
            <i>{this.getArticle(position)}</i>
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
          </li>
        );
      case "A04":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage id="activity.rate" defaultMessage="rated " />
            <i>{this.getArticle(position)}</i>
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
          </li>
        );
      case "C01":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage
              id="activity.comment"
              defaultMessage="has commented on "
            />
            {this.getActor(target)}'s <i>{this.getArticle(position)}</i>
            {this.getMention(mention)}
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
          </li>
        );
      case "F01":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage id="activity.and" defaultMessage="and" />
            {this.getActor(target)}
            {"  "}
            <FormattedMessage
              id="activity.friend"
              defaultMessage="came to be friends"
            />
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
          </li>
        );
      case "P01":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage
              id="activity.avatar"
              defaultMessage="updated avatar"
            />
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
            <br />
            <img
              className="activity-avatar"
              src={getAvatar(actor.avatar)}
              alt={actor.name}
            />
          </li>
        );
      case "P02":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedMessage
              id="activity.cover"
              defaultMessage="updated profile cover"
            />
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
            <br />
            <img
              className="activity-cover"
              src={getCover(actor.cover)}
              alt={actor.name}
            />
          </li>
        );
      case "Z01":
        return (
          <li className="activity-item">
            {this.getActor(actor)}{" "}
            <FormattedHTMLMessage
              id="activity.join"
              defaultMessage="joined community of <i>Donacoo.com</i>"
            />
            <span className="pull-right">
              <FormattedRelative value={createdAt} />
            </span>
          </li>
        );
      default:
        return;
    }
  };
  render() {
    return <React.Fragment>{this.renderActivity()}</React.Fragment>;
  }
}

export default ActivityDetail;
