export const SERVER_API = "https://donacoo.com"; //https://donacoo.com http://localhost:5000
export const PROVIDER = ["facebook", "google"]; //, "weixin", "qq", "twitter"

export const COUNTRIES = [
  { name: "Afghanistan", code: "AF", flag: true },
  { name: "Aland Islands", code: "AX", flag: false },
  { name: "Albania", code: "AL", flag: true },
  { name: "Algeria", code: "DZ", flag: true },
  { name: "American Samoa", code: "AS", flag: false },
  { name: "Andorra", code: "AD", flag: true },
  { name: "Angola", code: "AO", flag: true },
  { name: "Anguilla", code: "AI", flag: false },
  { name: "Antarctica", code: "AQ", flag: false },
  { name: "Antigua and Barbuda", code: "AG", flag: true },
  { name: "Argentina", code: "AR", flag: true },
  { name: "Armenia", code: "AM", flag: true },
  { name: "Aruba", code: "AW", flag: false },
  { name: "Australia", code: "AU", flag: true },
  { name: "Austria", code: "AT", flag: true },
  { name: "Azerbaijan", code: "AZ", flag: true },
  { name: "Bahamas", code: "BS", flag: true },
  { name: "Bahrain", code: "BH", flag: true },
  { name: "Bangladesh", code: "BD", flag: true },
  { name: "Barbados", code: "BB", flag: true },
  { name: "Belarus", code: "BY", flag: true },
  { name: "Belgium", code: "BE", flag: true },
  { name: "Belize", code: "BZ", flag: true },
  { name: "Benin", code: "BJ", flag: true },
  { name: "Bermuda", code: "BM", flag: false },
  { name: "Bhutan", code: "BT", flag: true },
  { name: "Bolivia", code: "BO", flag: true },
  { name: "Bosnia and Herzegovina", code: "BA", flag: true },
  { name: "Botswana", code: "BW", flag: true },
  { name: "Bouvet Island", code: "BV", flag: false },
  { name: "Brazil", code: "BR", flag: true },
  { name: "British Indian Ocean Territory", code: "IO", flag: false },
  { name: "Brunei Darussalam", code: "BN", flag: true },
  { name: "Bulgaria", code: "BG", flag: true },
  { name: "Burkina Faso", code: "BF", flag: true },
  { name: "Burundi", code: "BI", flag: true },
  { name: "Cambodia", code: "KH", flag: true },
  { name: "Cameroon", code: "CM", flag: true },
  { name: "Canada", code: "CA", flag: true },
  { name: "Cape Verde", code: "CV", flag: true },
  { name: "Cayman Islands", code: "KY", flag: false },
  { name: "Central African Republic", code: "CF", flag: true },
  { name: "Chad", code: "TD", flag: true },
  { name: "Chile", code: "CL", flag: true },
  { name: "China", code: "CN", flag: true },
  { name: "Christmas Island", code: "CX", flag: false },
  { name: "Cocos (Keeling) Islands", code: "CC", flag: false },
  { name: "Colombia", code: "CO", flag: true },
  { name: "Comoros", code: "KM", flag: true },
  { name: "Congo", code: "CG", flag: true },
  {
    name: "Congo, The Democratic Republic of the Congo",
    code: "CD",
    flag: true
  },
  { name: "Cook Islands", code: "CK", flag: true },
  { name: "Costa Rica", code: "CR", flag: true },
  { name: "Cote d'Ivoire", code: "CI", flag: true },
  { name: "Croatia", code: "HR", flag: true },
  { name: "Cuba", code: "CU", flag: true },
  { name: "Cyprus", code: "CY", flag: true },
  { name: "Czech Republic", code: "CZ", flag: true },
  { name: "Denmark", code: "DK", flag: true },
  { name: "Djibouti", code: "DJ", flag: true },
  { name: "Dominica", code: "DM", flag: true },
  { name: "Dominican Republic", code: "DO", flag: true },
  { name: "East Timor", code: "TL", flag: true },
  { name: "Ecuador", code: "EC", flag: true },
  { name: "Egypt", code: "EG", flag: true },
  { name: "El Salvador", code: "SV", flag: true },
  { name: "Equatorial Guinea", code: "GQ", flag: true },
  { name: "Eritrea", code: "ER", flag: true },
  { name: "Estonia", code: "EE", flag: true },
  { name: "Ethiopia", code: "ET", flag: true },
  { name: "Falkland Islands (Malvinas)", code: "FK", flag: false },
  { name: "Faroe Islands", code: "FO", flag: false },
  { name: "Fiji", code: "FJ", flag: true },
  { name: "Finland", code: "FI", flag: true },
  { name: "France", code: "FR", flag: true },
  { name: "French Guiana", code: "GF", flag: false },
  { name: "French Polynesia", code: "PF", flag: false },
  { name: "French Southern Territories", code: "TF", flag: false },
  { name: "Gabon", code: "GA", flag: true },
  { name: "Gambia", code: "GM", flag: true },
  { name: "Georgia", code: "GE", flag: true },
  { name: "Germany", code: "DE", flag: true },
  { name: "Ghana", code: "GH", flag: true },
  { name: "Gibraltar", code: "GI", flag: false },
  { name: "Greece", code: "GR", flag: true },
  { name: "Greenland", code: "GL", flag: false },
  { name: "Grenada", code: "GD", flag: true },
  { name: "Guadeloupe", code: "GP", flag: true },
  { name: "Guam", code: "GU", flag: false },
  { name: "Guatemala", code: "GT", flag: true },
  { name: "Guernsey", code: "GG", flag: false },
  { name: "Guinea", code: "GN", flag: true },
  { name: "Guinea Bissau", code: "GW", flag: true },
  { name: "Guyana", code: "GY", flag: true },
  { name: "Haiti", code: "HT", flag: true },
  { name: "Heard Island and Mcdonald Islands", code: "HM", flag: false },
  { name: "Honduras", code: "HN", flag: true },
  { name: "Hong Kong", code: "HK", flag: false },
  { name: "Hungary", code: "HU", flag: true },
  { name: "Iceland", code: "IS", flag: true },
  { name: "India", code: "IN", flag: true },
  { name: "Indonesia", code: "ID", flag: true },
  { name: "Iran", code: "IR", flag: true },
  { name: "Iraq", code: "IQ", flag: true },
  { name: "Ireland", code: "IE", flag: true },
  { name: "Isle of Man", code: "IM", flag: false },
  { name: "Israel", code: "IL", flag: true },
  { name: "Italy", code: "IT", flag: true },
  { name: "Jamaica", code: "JM", flag: true },
  { name: "Japan", code: "JP", flag: true },
  { name: "Jersey", code: "JE", flag: true },
  { name: "Jordan", code: "JO", flag: true },
  { name: "Kazakhstan", code: "KZ", flag: true },
  { name: "Kenya", code: "KE", flag: true },
  { name: "Kiribati", code: "KI", flag: true },
  { name: "Kosovo", code: "XK", flag: true },
  { name: "Kuwait", code: "KW", flag: true },
  { name: "Kyrgyzstan", code: "KG", flag: true },
  { name: "Laos", code: "LA", flag: true },
  { name: "Latvia", code: "LV", flag: true },
  { name: "Lebanon", code: "LB", flag: true },
  { name: "Lesotho", code: "LS", flag: true },
  { name: "Liberia", code: "LR", flag: true },
  { name: "Libyan Arab Jamahiriya", code: "LY", flag: false },
  { name: "Liechtenstein", code: "LI", flag: true },
  { name: "Lithuania", code: "LT", flag: true },
  { name: "Luxembourg", code: "LU", flag: true },
  { name: "Macao", code: "MO", flag: false },
  { name: "Macedonia", code: "MK", flag: true },
  { name: "Madagascar", code: "MG", flag: true },
  { name: "Malawi", code: "MW", flag: true },
  { name: "Malaysia", code: "MY", flag: true },
  { name: "Maldives", code: "MV", flag: true },
  { name: "Mali", code: "ML", flag: true },
  { name: "Malta", code: "MT", flag: true },
  { name: "Marshall Islands", code: "MH", flag: true },
  { name: "Martinique", code: "MQ", flag: false },
  { name: "Mauritania", code: "MR", flag: true },
  { name: "Mauritius", code: "MU", flag: true },
  { name: "Mayotte", code: "YT", flag: false },
  { name: "Mexico", code: "MX", flag: true },
  { name: "Micronesia", code: "FM", flag: true },
  { name: "Moldova", code: "MD", flag: true },
  { name: "Monaco", code: "MC", flag: true },
  { name: "Mongolia", code: "MN", flag: true },
  { name: "Montenegro", code: "ME", flag: true },
  { name: "Montserrat", code: "MS", flag: false },
  { name: "Morocco", code: "MA", flag: true },
  { name: "Mozambique", code: "MZ", flag: true },
  { name: "Myanmar", code: "MM", flag: true },
  { name: "Namibia", code: "NA", flag: true },
  { name: "Nauru", code: "NR", flag: true },
  { name: "Nepal", code: "NP", flag: true },
  { name: "Netherlands", code: "NL", flag: true },
  { name: "Netherlands Antilles", code: "AN", flag: false },
  { name: "New Caledonia", code: "NC", flag: false },
  { name: "New Zealand", code: "NZ", flag: true },
  { name: "Nicaragua", code: "NI", flag: true },
  { name: "Niger", code: "NE", flag: true },
  { name: "Nigeria", code: "NG", flag: true },
  { name: "Niue", code: "NU", flag: true },
  { name: "Norfolk Island", code: "NF", flag: false },
  { name: "North Korea", code: "KP", flag: true },
  { name: "Northern Mariana Islands", code: "MP", flag: false },
  { name: "Norway", code: "NO", flag: true },
  { name: "Oman", code: "OM", flag: true },
  { name: "Pakistan", code: "PK", flag: true },
  { name: "Palau", code: "PW", flag: true },
  { name: "Palestinian Territory", code: "PS", flag: false },
  { name: "Panama", code: "PA", flag: true },
  { name: "Papua New Guinea", code: "PG", flag: true },
  { name: "Paraguay", code: "PY", flag: true },
  { name: "Peru", code: "PE", flag: true },
  { name: "Philippines", code: "PH", flag: true },
  { name: "Pitcairn", code: "PN", flag: false },
  { name: "Poland", code: "PL", flag: true },
  { name: "Portugal", code: "PT", flag: true },
  { name: "Puerto Rico", code: "PR", flag: false },
  { name: "Qatar", code: "QA", flag: true },
  { name: "Reunion", code: "RE", flag: false },
  { name: "Romania", code: "RO", flag: true },
  { name: "Russia", code: "RU", flag: true },
  { name: "Rwanda", code: "RW", flag: true },
  { name: "Saint Helena", code: "SH", flag: false },
  { name: "Saint Kitts and Nevis", code: "KN", flag: true },
  { name: "Saint Lucia", code: "LC", flag: true },
  { name: "Saint Pierre and Miquelon", code: "PM", flag: false },
  { name: "Saint Vincent and the Grenadines", code: "VC", flag: true },
  { name: "Samoa", code: "WS", flag: true },
  { name: "San Marino", code: "SM", flag: true },
  { name: "Sao Tome and Principe", code: "ST", flag: true },
  { name: "Saudi Arabia", code: "SA", flag: true },
  { name: "Senegal", code: "SN", flag: true },
  { name: "Serbia and Montenegro", code: "CS", flag: false },
  { name: "Serbia", code: "RS", flag: true },
  { name: "Seychelles", code: "SC", flag: true },
  { name: "Sierra Leone", code: "SL", flag: true },
  { name: "Singapore", code: "SG", flag: true },
  { name: "Slovakia", code: "SK", flag: true },
  { name: "Slovenia", code: "SI", flag: true },
  { name: "Solomon Islands", code: "SB", flag: true },
  { name: "Somalia", code: "SO", flag: true },
  { name: "South Africa", code: "ZA", flag: true },
  { name: "South Korea", code: "KR", flag: true },
  { name: "South Sudan", code: "SD", flag: true },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    flag: false
  },
  { name: "Spain", code: "ES", flag: true },
  { name: "Sri Lanka", code: "LK", flag: true },
  { name: "Sudan", code: "SD", flag: true },
  { name: "Suriname", code: "SR", flag: true },
  { name: "Svalbard and Jan Mayen", code: "SJ", flag: false },
  { name: "Swaziland", code: "SZ", flag: true },
  { name: "Sweden", code: "SE", flag: true },
  { name: "Switzerland", code: "CH", flag: true },
  { name: "Syria", code: "SY", flag: true },
  { name: "Taiwan", code: "TW", flag: true },
  { name: "Tajikistan", code: "TJ", flag: true },
  { name: "Tanzania", code: "TZ", flag: true },
  { name: "Thailand", code: "TH", flag: true },
  { name: "Togo", code: "TG", flag: true },
  { name: "Tokelau", code: "TK", flag: false },
  { name: "Tonga", code: "TO", flag: true },
  { name: "Trinidad and Tobago", code: "TT", flag: true },
  { name: "Tunisia", code: "TN", flag: true },
  { name: "Turkey", code: "TR", flag: true },
  { name: "Turkmenistan", code: "TM", flag: true },
  { name: "Turks and Caicos Islands", code: "TC", flag: false },
  { name: "Tuvalu", code: "TV", flag: true },
  { name: "Uganda", code: "UG", flag: true },
  { name: "Ukraine", code: "UA", flag: true },
  { name: "United Arab Emirates", code: "AE", flag: true },
  { name: "United Kingdom", code: "GB", flag: true },
  { name: "United States", code: "US", flag: true },
  { name: "United States Minor Outlying Islands", code: "UM", flag: false },
  { name: "Uruguay", code: "UY", flag: true },
  { name: "Uzbekistan", code: "UZ", flag: true },
  { name: "Vanuatu", code: "VU", flag: true },
  { name: "Vatican City", code: "VA", flag: true },
  { name: "Venezuela", code: "VE", flag: true },
  { name: "Vietnam", code: "VN", flag: true },
  { name: "Wallis and Futuna", code: "WF", flag: false },
  { name: "Western Sahara", code: "EH", flag: true },
  { name: "Yemen", code: "YE", flag: true },
  { name: "Zambia", code: "ZM", flag: true },
  { name: "Zimbabwe", code: "ZW", flag: true }
];

export const LANGUAGES = [
  { code: "ab", name: "Abkhaz", nativeName: "аҧсуа" },
  { code: "aa", name: "Afar", nativeName: "Afaraf" },
  { code: "af", name: "Afrikaans", nativeName: "Afrikaans" },
  { code: "ak", name: "Akan", nativeName: "Akan" },
  { code: "sq", name: "Albanian", nativeName: "Shqip" },
  { code: "am", name: "Amharic", nativeName: "አማርኛ" },
  { code: "ar", name: "Arabic", nativeName: "العربية" },
  { code: "an", name: "Aragonese", nativeName: "Aragonés" },
  { code: "hy", name: "Armenian", nativeName: "Հայերեն" },
  { code: "as", name: "Assamese", nativeName: "অসমীয়া" },
  { code: "av", name: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
  { code: "ae", name: "Avestan", nativeName: "avesta" },
  { code: "ay", name: "Aymara", nativeName: "aymar aru" },
  { code: "az", name: "Azerbaijani", nativeName: "azərbaycan dili" },
  { code: "bm", name: "Bambara", nativeName: "bamanankan" },
  { code: "ba", name: "Bashkir", nativeName: "башҡорт теле" },
  { code: "eu", name: "Basque", nativeName: "euskara, euskera" },
  { code: "be", name: "Belarusian", nativeName: "Беларуская" },
  { code: "bn", name: "Bengali", nativeName: "বাংলা" },
  { code: "bh", name: "Bihari", nativeName: "भोजपुरी" },
  { code: "bi", name: "Bislama", nativeName: "Bislama" },
  { code: "bs", name: "Bosnian", nativeName: "bosanski jezik" },
  { code: "br", name: "Breton", nativeName: "brezhoneg" },
  { code: "bg", name: "Bulgarian", nativeName: "български език" },
  { code: "my", name: "Burmese", nativeName: "ဗမာစာ" },
  { code: "ca", name: "Catalan; Valencian", nativeName: "Català" },
  { code: "ch", name: "Chamorro", nativeName: "Chamoru" },
  { code: "ce", name: "Chechen", nativeName: "нохчийн мотт" },
  {
    code: "ny",
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja"
  },
  { code: "zh", name: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語" },
  { code: "cv", name: "Chuvash", nativeName: "чӑваш чӗлхи" },
  { code: "kw", name: "Cornish", nativeName: "Kernewek" },
  { code: "co", name: "Corsican", nativeName: "corsu, lingua corsa" },
  { code: "cr", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { code: "hr", name: "Croatian", nativeName: "hrvatski" },
  { code: "cs", name: "Czech", nativeName: "česky, čeština" },
  { code: "da", name: "Danish", nativeName: "dansk" },
  { code: "dv", name: "Divehi; Dhivehi; Maldivian;", nativeName: "ދިވެހި" },
  { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
  { code: "en", name: "English", nativeName: "English" },
  { code: "eo", name: "Esperanto", nativeName: "Esperanto" },
  { code: "et", name: "Estonian", nativeName: "eesti, eesti keel" },
  { code: "ee", name: "Ewe", nativeName: "Eʋegbe" },
  { code: "fo", name: "Faroese", nativeName: "føroyskt" },
  { code: "fj", name: "Fijian", nativeName: "vosa Vakaviti" },
  { code: "fi", name: "Finnish", nativeName: "suomi, suomen kieli" },
  { code: "fr", name: "French", nativeName: "français, langue française" },
  {
    code: "ff",
    name: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular"
  },
  { code: "gl", name: "Galician", nativeName: "Galego" },
  { code: "ka", name: "Georgian", nativeName: "ქართული" },
  { code: "de", name: "German", nativeName: "Deutsch" },
  { code: "el", name: "Greek, Modern", nativeName: "Ελληνικά" },
  { code: "gn", name: "Guaraní", nativeName: "Avañeẽ" },
  { code: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
  { code: "ht", name: "Haitian; Haitian Creole", nativeName: "Kreyòl ayisyen" },
  { code: "ha", name: "Hausa", nativeName: "Hausa, هَوُسَ" },
  { code: "he", name: "Hebrew (modern)", nativeName: "עברית" },
  { code: "hz", name: "Herero", nativeName: "Otjiherero" },
  { code: "hi", name: "Hindi", nativeName: "हिन्दी, हिंदी" },
  { code: "ho", name: "Hiri Motu", nativeName: "Hiri Motu" },
  { code: "hu", name: "Hungarian", nativeName: "Magyar" },
  { code: "ia", name: "Interlingua", nativeName: "Interlingua" },
  { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
  {
    code: "ie",
    name: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII"
  },
  { code: "ga", name: "Irish", nativeName: "Gaeilge" },
  { code: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
  { code: "ik", name: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
  { code: "io", name: "Ido", nativeName: "Ido" },
  { code: "is", name: "Icelandic", nativeName: "Íslenska" },
  { code: "it", name: "Italian", nativeName: "Italiano" },
  { code: "iu", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
  { code: "ja", name: "Japanese", nativeName: "日本語 (にほんご／にっぽんご)" },
  { code: "jv", name: "Javanese", nativeName: "basa Jawa" },
  {
    code: "kl",
    name: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii"
  },
  { code: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
  { code: "kr", name: "Kanuri", nativeName: "Kanuri" },
  { code: "ks", name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
  { code: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
  { code: "km", name: "Khmer", nativeName: "ភាសាខ្មែរ" },
  { code: "ki", name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
  { code: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
  { code: "ky", name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
  { code: "kv", name: "Komi", nativeName: "коми кыв" },
  { code: "kg", name: "Kongo", nativeName: "KiKongo" },
  {
    code: "ko",
    name: "Korean",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)"
  },
  { code: "ku", name: "Kurdish", nativeName: "Kurdî, كوردی‎" },
  { code: "kj", name: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
  { code: "la", name: "Latin", nativeName: "latine, lingua latina" },
  {
    code: "lb",
    name: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch"
  },
  { code: "lg", name: "Luganda", nativeName: "Luganda" },
  {
    code: "li",
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs"
  },
  { code: "ln", name: "Lingala", nativeName: "Lingála" },
  { code: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
  { code: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
  { code: "lu", name: "Luba-Katanga", nativeName: "" },
  { code: "lv", name: "Latvian", nativeName: "latviešu valoda" },
  { code: "gv", name: "Manx", nativeName: "Gaelg, Gailck" },
  { code: "mk", name: "Macedonian", nativeName: "македонски јазик" },
  { code: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
  { code: "ms", name: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
  { code: "ml", name: "Malayalam", nativeName: "മലയാളം" },
  { code: "mt", name: "Maltese", nativeName: "Malti" },
  { code: "mi", name: "Māori", nativeName: "te reo Māori" },
  { code: "mr", name: "Marathi (Marāṭhī)", nativeName: "मराठी" },
  { code: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ" },
  { code: "mn", name: "Mongolian", nativeName: "монгол" },
  { code: "na", name: "Nauru", nativeName: "Ekakairũ Naoero" },
  { code: "nv", name: "Navajo, Navaho", nativeName: "Diné bizaad, Dinékʼehǰí" },
  { code: "nb", name: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
  { code: "nd", name: "North Ndebele", nativeName: "isiNdebele" },
  { code: "ne", name: "Nepali", nativeName: "नेपाली" },
  { code: "ng", name: "Ndonga", nativeName: "Owambo" },
  { code: "nn", name: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
  { code: "no", name: "Norwegian", nativeName: "Norsk" },
  { code: "ii", name: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
  { code: "nr", name: "South Ndebele", nativeName: "isiNdebele" },
  { code: "oc", name: "Occitan", nativeName: "Occitan" },
  { code: "oj", name: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  {
    code: "cu",
    name:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativeName: "ѩзыкъ словѣньскъ"
  },
  { code: "om", name: "Oromo", nativeName: "Afaan Oromoo" },
  { code: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ" },
  { code: "os", name: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
  { code: "pa", name: "Panjabi, Punjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
  { code: "pi", name: "Pāli", nativeName: "पाऴि" },
  { code: "fa", name: "Persian", nativeName: "فارسی" },
  { code: "pl", name: "Polish", nativeName: "polski" },
  { code: "ps", name: "Pashto, Pushto", nativeName: "پښتو" },
  { code: "pt", name: "Portuguese", nativeName: "Português" },
  { code: "qu", name: "Quechua", nativeName: "Runa Simi, Kichwa" },
  { code: "rm", name: "Romansh", nativeName: "rumantsch grischun" },
  { code: "rn", name: "Kirundi", nativeName: "kiRundi" },
  { code: "ro", name: "Romanian, Moldavian, Moldovan", nativeName: "română" },
  { code: "ru", name: "Russian", nativeName: "русский язык" },
  { code: "sa", name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
  { code: "sc", name: "Sardinian", nativeName: "sardu" },
  { code: "sd", name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
  { code: "se", name: "Northern Sami", nativeName: "Davvisámegiella" },
  { code: "sm", name: "Samoan", nativeName: "gagana faa Samoa" },
  { code: "sg", name: "Sango", nativeName: "yângâ tî sängö" },
  { code: "sr", name: "Serbian", nativeName: "српски језик" },
  { code: "gd", name: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig" },
  { code: "sn", name: "Shona", nativeName: "chiShona" },
  { code: "si", name: "Sinhala, Sinhalese", nativeName: "සිංහල" },
  { code: "sk", name: "Slovak", nativeName: "slovenčina" },
  { code: "sl", name: "Slovene", nativeName: "slovenščina" },
  { code: "so", name: "Somali", nativeName: "Soomaaliga, af Soomaali" },
  { code: "st", name: "Southern Sotho", nativeName: "Sesotho" },
  { code: "es", name: "Spanish; Castilian", nativeName: "español, castellano" },
  { code: "su", name: "Sundanese", nativeName: "Basa Sunda" },
  { code: "sw", name: "Swahili", nativeName: "Kiswahili" },
  { code: "ss", name: "Swati", nativeName: "SiSwati" },
  { code: "sv", name: "Swedish", nativeName: "svenska" },
  { code: "ta", name: "Tamil", nativeName: "தமிழ்" },
  { code: "te", name: "Telugu", nativeName: "తెలుగు" },
  { code: "tg", name: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
  { code: "th", name: "Thai", nativeName: "ไทย" },
  { code: "ti", name: "Tigrinya", nativeName: "ትግርኛ" },
  {
    code: "bo",
    name: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག"
  },
  { code: "tk", name: "Turkmen", nativeName: "Türkmen, Түркмен" },
  { code: "tl", name: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { code: "tn", name: "Tswana", nativeName: "Setswana" },
  { code: "to", name: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
  { code: "tr", name: "Turkish", nativeName: "Türkçe" },
  { code: "ts", name: "Tsonga", nativeName: "Xitsonga" },
  { code: "tt", name: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
  { code: "tw", name: "Twi", nativeName: "Twi" },
  { code: "ty", name: "Tahitian", nativeName: "Reo Tahiti" },
  { code: "ug", name: "Uighur, Uyghur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
  { code: "uk", name: "Ukrainian", nativeName: "українська" },
  { code: "ur", name: "Urdu", nativeName: "اردو" },
  { code: "uz", name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
  { code: "ve", name: "Venda", nativeName: "Tshivenḓa" },
  { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
  { code: "vo", name: "Volapük", nativeName: "Volapük" },
  { code: "wa", name: "Walloon", nativeName: "Walon" },
  { code: "cy", name: "Welsh", nativeName: "Cymraeg" },
  { code: "wo", name: "Wolof", nativeName: "Wollof" },
  { code: "fy", name: "Western Frisian", nativeName: "Frysk" },
  { code: "xh", name: "Xhosa", nativeName: "isiXhosa" },
  { code: "yi", name: "Yiddish", nativeName: "ייִדיש" },
  { code: "yo", name: "Yoruba", nativeName: "Yorùbá" },
  { code: "za", name: "Zhuang, Chuang", nativeName: "Saɯ cueŋƅ, Saw cuengh" }
];

export const CATEGORIES = [
  "Life",
  "Travel & Places",
  "Science & Technology",
  "The Environment",
  "Psychology & Inspiration",
  "Love & Relationship",
  "Health & Food",
  "Success & Failure",
  "War & Peace",
  "Men & Women",
  "Education & Learning",
  "Religion & Race",
  "History & Politics",
  "Entertainment",
  "Hobbies & Interests",
  "Poetry & Essay",
  "Tips & Tricks",
  "Business & Marketing",
  "Animals",
  "Fictions & Fairies"
];
export const CATEGORY = [
  { query: "life", en: "Life", fr: "La vie", es: "Vida" },
  { query: "animals", en: "Animals", fr: "Animaux", es: "Los animales" },
  {
    query: "business-marketing",
    en: "Business & Marketing",
    fr: "Affaires & Marketing",
    es: "Negocios & Marketing"
  },
  {
    query: "education-learning",
    en: "Education & Learning",
    fr: "Education & Apprentissage",
    es: "Educación & Aprendizaje"
  },
  {
    query: "entertainment",
    en: "Entertainment",
    fr: "Divertissement",
    es: "Entretenimiento"
  },
  {
    query: "fictions-fairies",
    en: "Fictions & Fairies",
    fr: "Fictions & Fées",
    es: "Ficciones & Hadas"
  },
  {
    query: "health-food",
    en: "Health & Food",
    fr: "Santé & Nourriture",
    es: "Salud & Comida"
  },
  {
    query: "history-politics",
    en: "History & Politics",
    fr: "Histoire & Politique",
    es: "Historia & Politica"
  },
  {
    query: "hobbies-interests",
    en: "Hobbies & Interests",
    fr: "Loisirs & Intérêts",
    es: "Pasatiempos & Intereses"
  },
  {
    query: "love-relationship",
    en: "Love & Relationship",
    fr: "Amour & Relation",
    es: "Amor & Relación"
  },
  {
    query: "men-women",
    en: "Men & Women",
    fr: "Hommes & Femmes",
    es: "Hombres & Mujeres"
  },
  {
    query: "poetry-essay",
    en: "Poetry & Essay",
    fr: "Poésie & Rédaction",
    es: "Poesía & Ensayo"
  },
  {
    query: "psychology-inspiration",
    en: "Psychology & Inspiration",
    fr: "Psychologie & Inspiration",
    es: "Psicología & Inspiración"
  },
  {
    query: "religion-race",
    en: "Religion & Race",
    fr: "Religion & Race",
    es: "Religión & Raza"
  },
  {
    query: "science-technology",
    en: "Science & Technology",
    fr: "Science & Technologie",
    es: "Ciencia & Tecnología"
  },
  {
    query: "success-failure",
    en: "Success & Failure",
    fr: "Succès & Échec",
    es: "Éxito & Fracaso"
  },
  {
    query: "the-environment",
    en: "The Environment",
    fr: "L'environnement",
    es: "El entorno"
  },
  {
    query: "tips-tricks",
    en: "Tips & Tricks",
    fr: "Conseils & Astuces",
    es: "Consejos & Trucos"
  },
  {
    query: "travel-places",
    en: "Travel & Places",
    fr: "Voyage & Endroit",
    es: "Viajar & Lugar"
  },
  {
    query: "war-peace",
    en: "War & Peace",
    fr: "Guerre & Paix",
    es: "Guerra & Paz"
  }
];

export const seoApp = [
  {
    label: "description",
    value:
      "A website that will infuse your eyes with sentiment wisdom.. Topics on Life,Travel,Poetry,Essay,Education.."
  },
  {
    label: "keywords",
    value:
      "Topics on Life,Travel,Places,The Environment,Psychology,Inspiration,Love,Relationships,Health,Food,Success,Failure,War,Peace,Men,Women,Education,Learning,Religion,Race,History,Politics,Hobbies,Interests,Poetry,Essay,Tips,Tricks,Business,Marketing,Animals,Fictions,Fairies"
  },
  { label: "author", value: "Jean" }
];

export const toastrOptions = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-bottom-right",
  preventDuplicates: true,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};
