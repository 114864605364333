import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import file from "../../../assets/img/file.png";
import { isEmpty } from "../../../assets/utils/func";

class ArticleSlash extends Component {
  getFolders(arts) {
    const folders = _.countBy(arts, "folder");
    const toDash = folderName => {
      return folderName.split(" ").join("-");
    };
    return Object.keys(folders).map(f => (
      <div key={f} className="card folder">
        <Link to={`/dashboard/articles/${toDash(f)}`}>
          <img src={file} alt={f} className="card-img-top article-folder" />
        </Link>
        <div className="card-body">
          <h5 className="card-title">{f}</h5>
          <p className="card-text">
            <FormattedHTMLMessage
              id="dart.num"
              defaultMessage="{num, number} {num, plural, one {article} other {articles}}"
              values={{ num: folders[f] }}
            />
          </p>
        </div>
      </div>
    ));
  }
  render() {
    const { arts } = this.props;
    if (isEmpty(arts)) {
      return (
        <div className="no-articles">
          <div className="jumbotron">
            <h1 className="display-4">
              <FormattedMessage
                id="dart.jumbtron"
                defaultMessage="Welcome to this amazing world, let's try build your first article"
              />
            </h1>
            <p className="lead">
              <FormattedMessage id="dart.lead" defaultMessage="Tips & Tricks" />
            </p>
            <hr className="my-4" />
            <p className="text-muted">
              <FormattedHTMLMessage
                id="dart.jumb"
                defaultMessage="By click the <b>COMPOSE</b> button on the left panel, you may try to write an article there, save to draft and edit later if takes long time.."
              />
            </p>
          </div>
        </div>
      );
    }
    return <div className="article-slash">{this.getFolders(arts)}</div>;
  }
}

export default ArticleSlash;
