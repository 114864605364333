import {
  ARTICLE_COMMENTS_LOADING,
  ARTICLE_SET_COMMENTS,
  ARTICLE_CREATE_COMMENTS,
  ARTICLE_UPDATE_COMMENTS,
  ARTICLE_DELETE_COMMENTS
} from "../actions/types";

const initialState = {
  loading: false,
  comments: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ARTICLE_COMMENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case ARTICLE_SET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
        loading: false
      };
    case ARTICLE_CREATE_COMMENTS:
      return {
        ...state,
        comments: [...state.comments, action.payload]
      };
    case ARTICLE_UPDATE_COMMENTS:
      return {
        ...state,
        comments: state.comments.map(c =>
          c._id === action.payload._id ? { ...action.payload } : c
        )
      };
    case ARTICLE_DELETE_COMMENTS:
      return {
        ...state,
        comments: state.comments.filter(c => c._id !== action.payload._id)
      };
    default:
      return state;
  }
}
