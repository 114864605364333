import { SET_ARTICLE_VISITOR, ARTICLE_LOADING } from "../actions/types";

const initialState = {
  loading: false,
  art: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ARTICLE_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ARTICLE_VISITOR:
      return {
        ...state,
        art: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
