import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { update_cover } from "../../../actions/userActions";
import { getCover } from "../../../assets/utils/func";

class CoverInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverUrls: [
        "lib/cover/default_cover.jpg",
        "lib/cover/default_cover_sky.jpg",
        "lib/cover/default_cover_sand.jpg",
        "lib/cover/default_cover_boat.jpg"
      ],
      cover: "",
      errors: {}
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    this.setState({ cover: user.cover || "" });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  handleSelectPic = async data => {
    const { cover } = this.state;
    this.setState({ cover: data });
    try {
      await this.props.update_cover({ cover: data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Updating cover failed");
      const msg = this.props.intl.formatMessage({
        id: "dset.cover-failure"
      });
      toastr.warning(msg);
      this.setState({ cover });
    }
  };
  render() {
    const { coverUrls, cover, errors } = this.state;
    return (
      <div className="profile-cover">
        <h4>
          <FormattedMessage
            id="dset.cover"
            defaultMessage="Profile cover setting"
          />
        </h4>
        <div className="selectPic">
          {coverUrls.map((c, i) => (
            <div
              className={`tick ${c === cover ? "active" : ""}`}
              key={i}
              onClick={() => this.handleSelectPic(c)}
            >
              <i
                className={`fas fa-check gly ${c === cover ? "active" : ""}`}
              />
              <img className="img-cover" src={getCover(c)} alt="cover" />
            </div>
          ))}
          {errors.cover && (
            <div className="invalid-feedback">{errors.cover}</div>
          )}
        </div>
        <small className="form-text text-muted clear-text">
          <FormattedMessage
            id="dset.cover-info"
            defaultMessage="Select a picture as your profile cover, with certain crediates you may upload private image as cover"
          />
        </small>
      </div>
    );
  }
}
CoverInfo.propTypes = {
  intl: intlShape.isRequired,
  update_cover: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { update_cover }
)(injectIntl(CoverInfo));
