import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { uploadAvatar } from "../../../actions/userActions";
import { dataURLtoFile } from "../../../assets/utils/func";

class Avatar extends Component {
  editor = AvatarEditor;
  constructor() {
    super();
    this.state = {
      preview: "",
      image: {},
      zoom: 1,
      load: false,
      errors: {}
    };
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.errors) this.setState({ errors: nextProps.errors });
  };
  handleAccepted(files) {
    this.setState({
      preview: URL.createObjectURL(files[0]),
      image: files[0],
      load: true
    });
  }
  handleRejected(files) {
    const file = files[0];
    let errors = {};
    errors.file = file.name;
    this.setState({ errors });
  }
  handleZoom = e => {
    e.preventDefault();
    this.setState({ zoom: e.target.value });
    this.handlePositionChange();
  };
  handlePositionChange = () => {
    const canvas = this.editor.getImage().toDataURL();
    let preview;
    fetch(canvas)
      .then(res => res.blob())
      .then(blob => {
        preview = window.URL.createObjectURL(blob);
        this.setState({
          preview
        });
      });
  };
  handleAvatar = async () => {
    const name = this.state.image.name;
    const imageURL = this.editor.getImageScaledToCanvas().toDataURL();
    let file;
    await dataURLtoFile(imageURL, name).then(function(f) {
      file = f;
    });
    let formData = new FormData();
    formData.append("avatar", file);
    try {
      await this.props.uploadAvatar(formData);
      this.setState({
        load: false,
        errors: {
          image: this.props.intl.formatMessage({
            id: "davatar.success"
          })
        }
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Upload failed");
      const msg = this.props.intl.formatMessage({
        id: "davatar.failure"
      });
      toastr.warning(msg);
    }
  };
  componentWillUnmount() {
    const { preview } = this.state;
    URL.revokeObjectURL(preview);
  }
  setEditorRef = editor => {
    if (editor) {
      this.editor = editor;
    }
  };
  render() {
    const dropzoneRef = React.createRef();
    const dropZone = (
      <div className="dropZone">
        <Dropzone
          className="drop-zone"
          multiple={false}
          maxSize={2097152}
          accept="image/jpeg, image/png"
          ref={dropzoneRef}
          onDropAccepted={this.handleAccepted.bind(this)}
          onDropRejected={this.handleRejected.bind(this)}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="select-area">
              <input {...getInputProps()} />
              <p className="text-muted">
                <FormattedMessage
                  id="davatar.info1"
                  defaultMessage="Drop Images here or clicke the Select File button below to choose image"
                />
              </p>
              <p className="text-muted">
                <FormattedMessage
                  id="davatar.info2"
                  defaultMessage="Only support *.jpg, *.jpeg, *.png"
                />
              </p>
              <p className="text-muted">
                <FormattedMessage
                  id="davatar.info3"
                  defaultMessage="Images sizes less than 2 megabytes"
                />
              </p>
              {this.state.errors.file ? (
                <p className="text-danger">
                  <FormattedMessage
                    id="davatar.error"
                    defaultMessage="Error selecting"
                  />
                  <em>{this.state.errors.file}</em>
                </p>
              ) : (
                ""
              )}
              {this.state.errors.image ? (
                <p className="text-success">
                  <em>{this.state.errors.image}</em>
                </p>
              ) : (
                ""
              )}
            </div>
          )}
        </Dropzone>
        <button
          type="button"
          className="btn btn-outline-secondary btn-select"
          onClick={() => {
            dropzoneRef.current.open();
          }}
        >
          <FormattedMessage id="davatar.btn" defaultMessage="Select File" />
        </button>
      </div>
    );
    const avatarEditor = (
      <div className="avatarEditor">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <AvatarEditor
              className="avatar-editor"
              ref={this.setEditorRef}
              image={this.state.image}
              onPositionChange={this.handlePositionChange}
              color={[255, 255, 255, 0.6]}
              scale={this.state.zoom / 10 + 1}
              width={200}
              height={200}
            />
            <div className="form-group">
              <input
                type="range"
                min="1"
                max="10"
                value={this.state.zoom}
                onChange={e => this.handleZoom(e)}
                className="slider"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12">
            <h3>
              <FormattedMessage id="davatar.preview" defaultMessage="Preview" />
            </h3>
            <img
              src={this.state.preview}
              alt="Preview"
              height="200px"
              width="200px"
            />
            <button
              type="button"
              className="btn btn-outline-secondary m-3"
              disabled={this.state.load ? "" : "disabled"}
              onClick={this.handleAvatar}
            >
              <FormattedMessage
                id="davatar.save"
                defaultMessage="Save avatar"
              />
            </button>
          </div>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        <h3>
          <FormattedMessage
            id="davatar.update"
            defaultMessage="Update avatar"
          />
        </h3>
        {!this.state.load && dropZone}
        {this.state.load && avatarEditor}
      </React.Fragment>
    );
  }
}
Avatar.propTypes = {
  intl: intlShape.isRequired,
  uploadAvatar: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { uploadAvatar }
)(injectIntl(Avatar));
