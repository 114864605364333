import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const News = () => {
  return (
    <div className="news" id="news">
      <div className="container">
        <div className="row news-main">
          <div className="col-md-6 news-left">
            <h2>What's the meaning of the life?</h2>
            <div className="read-more">
              <Link
                to="/articles/5c6c5966fa4be107612f3843"
                className="read-more-link"
              >
                <FormattedMessage
                  id="layout.readmore"
                  defaultMessage="Reaad more"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-6 news-right">
            <p>
              <span className="sentence">What’s the meaning of the life</span>
              <br />
              <span className="sentence">
                Ask Mr.Wolf; to hunt and to survive
              </span>
              <br />
              <span className="sentence">
                The day is short and the night is young
              </span>
              <br />
              <span className="sentence">
                The prey is weak and life favours the strong
              </span>
              <br />
              <span className="sentence">
                Carry cruel in mind thus prosper and hunt
              </span>
              <br />
              <span className="sentence">
                Then of the king’s throne you shall mount
              </span>
              <br />
            </p>
            <p>
              <span className="sentence">What’s the meaning of the life</span>
              <br />
              <span className="sentence">
                Ask Mis.Fish; to enjoy and to involve
              </span>
              <br />
              <span className="sentence">
                The world is amazing and the life is long
              </span>
              <br />
              <span className="sentence">
                Of the wondrous matter you better sung
              </span>
              <br />
              <span className="sentence">
                Even to an ephemeral mayfly’s dance, don’t miss
              </span>
              <br />
              <span className="sentence">
                Cause it might bring you a big slobbering kiss
              </span>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
