import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import "./landing.scss";
import News from "./News";
import Statistics from "./Statistics";
import Choose from "./Choose";

class Landing extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="landing">
          <div className="slogon-box">
            <div className="slogon">
              <Link to="/articles" className="btn-special">
                <svg width="277" height="62">
                  <defs>
                    <linearGradient id="grad1">
                      <stop offset="0%" stopColor="#ffad60" />
                      <stop offset="100%" stopColor="#343a40" />
                    </linearGradient>
                  </defs>
                  <rect
                    x="5"
                    y="5"
                    rx="25"
                    fill="none"
                    stroke="url(#grad1)"
                    width="266"
                    height="50"
                  />
                </svg>
                <span className="slogon-words">
                  <FormattedMessage
                    id="layout.slogon"
                    defaultMessage="Life is colorful"
                  />
                </span>
              </Link>
            </div>
            <div className="caption">
              <p className="caption-words">
                <FormattedMessage
                  id="layout.caption"
                  defaultMessage="A website that will infuse your eyes with sentient wisdom .."
                />
              </p>
            </div>
          </div>
        </div>
        <News />
        <Statistics />
        <Choose />
      </React.Fragment>
    );
  }
}

export default Landing;
