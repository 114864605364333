import React, { Component } from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Compose from "./Compose";
import MessageBox from "./MessageBox";
import MessageSingle from "./MessageSingle";
import "./Message.css";

class Message extends Component {
  render() {
    const { match } = this.props;
    return (
      <div className="message">
        <div className="select-box d-inline">
          <NavLink to="/dashboard/messages/" className="box_btn">
            <i className="fas fa-pen-fancy pr-2" />
            <FormattedMessage id="dmsg.compose" defaultMessage="Compose" />
          </NavLink>
          <NavLink to="/dashboard/messages/received" className="box_btn">
            <i className="fas fa-envelope-open pr-2" />
            <FormattedMessage id="dmsg.receive" defaultMessage="Received" />
          </NavLink>
          <NavLink to="/dashboard/messages/sent" className="box_btn">
            <i className="fas fa-envelope pr-2" />
            <FormattedMessage id="dmsg.sent" defaultMessage="Sent" />
          </NavLink>
          <NavLink to="/dashboard/messages/draft" className="box_btn">
            <i className="fas fa-edit pr-2" />
            <FormattedMessage id="dmsg.draft" defaultMessage="Draft" />
          </NavLink>
        </div>
        <div className="message-content">
          <Switch>
            <Route
              path={`${match.path}/:box/:m_id`}
              component={MessageSingle}
            />
            <Route path={`${match.path}/:box`} component={MessageBox} />
            <Route exact path={`${match.path}/`} component={Compose} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Message;
