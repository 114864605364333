import http from "../services/http";
import setAuthToken from "../assets/utils/setAuthToken";
import jwt_decode from "jwt-decode";
import toastr from "toastr";
import { SET_CURRENT_USER } from "./types";
import { handleError } from "../assets/utils/handleError";

//Register user
export const registerUser = (userData, history) => dispatch => {
  http
    .post("/api/users/", userData)
    .then(res => {
      if (res.data["success"]) {
        toastr.success(
          "A verification link has been sent to your mailbox, please verify it in 2 days",
          "Congratuations"
        );
      }
      history.push("/login");
    })
    .catch(err => handleError(err, dispatch));
};

// Login user
export const loginUser = userData => dispatch => {
  http
    .post("/api/users/login", userData)
    .then(res => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => handleError(err, dispatch));
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

// Email verify
export const emailVerify = info => dispatch => {
  http
    .post(`/api/users/email-verify/${info._id}`, { salt: info.salt })
    .then(res => {
      if (res.data["success"]) {
        toastr.success("Account verify success");
      }
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => handleError(err, dispatch));
};

export const thirdLogin = token => dispatch => {
  localStorage.setItem("jwtToken", token);
  setAuthToken(token);
  const decoded = jwt_decode(token);
  dispatch(setCurrentUser(decoded));
};
