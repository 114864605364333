import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import queryString from "query-string";
import toastr from "toastr";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import TextField from "../../common/TextField";
import { send_message } from "../../../actions/messageActions";
import { searchPeopleAsync } from "../../../services/search";
import { getAvatar } from "../../../assets/utils/func";

class Compose extends Component {
  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
      header: "",
      to: "",
      name: "",
      draft: false,
      length: 0,
      filtered: [],
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.searchPeople = this.searchPeople.bind(this);
  }
  componentDidMount() {
    if (this.props.location.search.indexOf("edit") !== -1) {
      const { edit } = queryString.parse(this.props.location.search);
      console.log(edit);
    }
  }
  validate = () => {
    const errors = { ...this.state.errors };
    const { header, to, length } = this.state;
    if (header.trim().length < 2 || header.trim().length > 40) {
      errors.header = this.props.intl.formatMessage({
        id: "dmsg.error-header"
      });
    } else {
      delete errors.header;
    }
    if (to.length !== 24) {
      errors.name = this.props.intl.formatMessage({ id: "dmsg.error-name" });
    } else {
      delete errors.name;
    }
    if (length === 0 || length > 300) {
      errors.content = this.props.intl.formatMessage({
        id: "dmsg.error-content"
      });
    } else {
      delete errors.content;
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };
  onEditorStateChange = editorState => {
    let length = this.count(editorState);
    this.setState({
      editorState,
      length
    });
  };
  count = editorState => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return raw.blocks
      .map(b => b.text.length)
      .reduce((a, b) => {
        return a + b;
      }, 0);
  };
  searchPeople = async e => {
    const name = e.target.value;
    this.setState({ name });
    if (name.trim().length !== 0) {
      const { data } = await searchPeopleAsync(name);
      const errors = { ...this.state.errors };
      if (data.length !== 0) {
        delete errors.name;
        this.setState({ filtered: data, errors });
      } else {
        errors.name = this.props.intl.formatMessage({
          id: "dmsg.error-nomatch"
        });
        this.setState({ filtered: [], errors });
      }
    }
  };
  selectUser = friend => {
    this.setState({
      to: friend._id,
      name: friend.name,
      filtered: []
    });
  };
  getFilterResults = () => {
    const { filtered } = this.state;
    if (filtered.length === 0) return;
    return (
      <div className="filter_results">
        <ul className="list-group">
          {filtered.map(friend => (
            <li
              key={friend._id}
              className="list-group-item target-item"
              onClick={() => this.selectUser(friend)}
            >
              <img
                src={getAvatar(friend.avatar)}
                alt={friend.name}
                className="img-circle"
              />
              {friend.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  handleChange = ({ currentTarget: input }) => {
    const { header, errors } = this.state;
    if (header.trim().length < 2 || header.trim().length > 40) {
      errors.header = this.props.intl.formatMessage({
        id: "dmsg.error-header"
      });
    } else {
      delete errors[input.name];
    }
    this.setState({ [input.name]: input.value, errors });
  };
  handleDraft = () => {
    this.setState({ draft: !this.state.draft });
  };
  handleSend = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };
  doSubmit = async () => {
    const { to, header, editorState, draft } = this.state;
    let message = {};
    message.to = to;
    message.header = header;
    message.content = convertToRaw(editorState.getCurrentContent());
    message.draft = draft;
    try {
      await this.props.send_message(message, this.props.history);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Message send failed");
      const msg = this.props.intl.formatMessage({ id: "dmsg.send-failure" });
      toastr.warning(msg);
    }
  };
  render() {
    const { name, header, editorState, length, draft, errors } = this.state;
    const { intl } = this.props;
    const lang = localStorage.getItem("lang") || "en";
    const namePlaceholder = intl.formatMessage({ id: "dmsg.to" });
    const headerPlaceholder = intl.formatMessage({ id: "dmsg.t-header" });
    return (
      <div className="compose-message">
        <TextField
          name="name"
          placeholder={namePlaceholder}
          type="text"
          value={name}
          onChange={this.searchPeople}
          error={errors.name}
        />
        {this.getFilterResults()}
        <TextField
          name="header"
          placeholder={headerPlaceholder}
          type="text"
          value={header}
          onChange={this.handleChange}
          error={errors.header}
        />
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-message"
          editorClassName="editor-message"
          toolbarClassName="toolbar-message"
          localization={{ locale: lang }}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: ["emoji", "inline", "colorPicker", "image"]
          }}
        />
        {errors.content && (
          <small className="text-danger">{errors.content}</small>
        )}
        <p className="text-muted text-right">
          <span className={length > 300 ? "text-danger" : ""}>{length}</span>
          /300
        </p>

        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="draft"
            onChange={this.handleDraft}
            checked={this.state.draft}
          />
          <label className="form-check-label">
            <FormattedMessage
              id="dmsg.save-draft"
              defaultMessage="Save draft"
            />
          </label>
        </div>
        <button
          type="button"
          onClick={e => this.handleSend(e)}
          className="btn btn-ctl btn-block"
        >
          {draft ? (
            <FormattedMessage
              id="dmsg.save-draft"
              defaultMessage="Save draft"
            />
          ) : (
            <FormattedMessage id="dmsg.send" defaultMessage="Send" />
          )}
        </button>
      </div>
    );
  }
}
Compose.propTypes = {
  intl: intlShape.isRequired,
  send_message: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { send_message }
)(injectIntl(Compose));
