import http from "../services/http";
import {
  SET_MESSAGES,
  MESSAGES_LOADING,
  SEND_MESSAGE,
  READ_MESSAGE,
  UPDATE_MESSAGE,
  DELETE_MESSAGE
} from "./types";
import { handleError } from "../assets/utils/handleError";

export const get_messages = () => dispatch => {
  dispatch({ type: MESSAGES_LOADING });
  http
    .get("/api/messages/")
    .then(res =>
      dispatch({
        type: SET_MESSAGES,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
export const send_message = (data, history) => dispatch => {
  http
    .post("/api/messages/", data)
    .then(res => {
      dispatch({
        type: SEND_MESSAGE,
        payload: res.data
      });
      history.push("/dashboard/messages/send");
    })
    .catch(err => handleError(err, dispatch));
};

export const read_message = m_id => dispatch => {
  http
    .put(`/api/messages/read/${m_id}`)
    .then(res => dispatch({ type: READ_MESSAGE, payload: res.data }))
    .catch(err => handleError(err, dispatch));
};

export const update_message = (m_id, data) => dispatch => {
  http
    .put(`/api/messages/${m_id}`, data)
    .then(res =>
      dispatch({
        type: UPDATE_MESSAGE,
        payload: res.data
      })
    )
    .then(err => handleError(err, dispatch));
};

export const delete_message = m_id => dispatch => {
  http
    .delete(`/api/messages/${m_id}`)
    .then(res =>
      dispatch({
        type: DELETE_MESSAGE,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
