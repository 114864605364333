import React, { Component } from "react";
import CountUp from "react-countup";
import { FormattedMessage } from "react-intl";
import { searchStatics } from "../../services/search";

class Statistics extends Component {
  constructor() {
    super();
    this.state = {
      classic: 0,
      original: 0,
      editor: 0,
      support: 0,
      running: false
    };
    this.getStatics = this.getStatics.bind(this);
  }
  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
    this.getStatics();
  }
  getStatics = async () => {
    try {
      const { data } = await searchStatics();
      if (typeof data.classic !== "undefined") {
        this.setState({
          classic: data.classic,
          original: data.original,
          editor: data.editor,
          support: 15
        });
      }
    } catch (ex) {
      this.setState({ classic: 421, original: 163, editor: 47, support: 15 });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById("counter");
    if (this.isBottom(wrappedElement)) {
      if (!this.state.running) {
        this.setState({ running: true });
      }
      document.removeEventListener("scroll", this.trackScrolling);
    }
  };
  render() {
    const { classic, original, editor, support, running } = this.state;
    return (
      <div className="stats">
        <div className="container">
          <div className="col-md-3 w3_counter_grid">
            <div className="w3_agileits_counter_grid">
              <i className="fa fa-book" aria-hidden="true" />
            </div>
            <p className="counter" id="counter">
              <CountUp
                className="countUp"
                start={0}
                end={running ? classic : 0}
                duration={5}
                decimal=","
              />
            </p>
            <h3>
              <FormattedMessage
                id="layout.classic"
                defaultMessage="Classic Literature"
              />
            </h3>
          </div>
          <div className="col-md-3 w3_counter_grid">
            <div className="w3_agileits_counter_grid">
              <i className="fa fa-desktop" aria-hidden="true" />
            </div>
            <p className="counter">
              <CountUp
                className="countUp"
                start={0}
                end={running ? original : 0}
                duration={5}
                decimal=","
              />
            </p>
            <h3>
              <FormattedMessage
                id="layout.original"
                defaultMessage="Original Creation"
              />
            </h3>
          </div>
          <div className="col-md-3 w3_counter_grid">
            <div className="w3_agileits_counter_grid">
              <i className="fa fa-users" aria-hidden="true" />
            </div>
            <p className="counter">
              <CountUp
                className="countUp"
                start={0}
                end={running ? editor : 0}
                duration={5}
                decimal=","
              />
            </p>
            <h3>
              <FormattedMessage
                id="layout.editor"
                defaultMessage="Learned editors"
              />
            </h3>
          </div>
          <div className="col-md-3 w3_counter_grid">
            <div className="w3_agileits_counter_grid">
              <i className="fa fa-comments" aria-hidden="true" />
            </div>
            <p className="counter">
              <CountUp
                className="countUp"
                start={0}
                end={running ? support : 0}
                duration={5}
                decimal=","
              />
            </p>
            <h3>
              <FormattedMessage
                id="layout.support"
                defaultMessage="Decicated Support"
              />
            </h3>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

export default Statistics;
