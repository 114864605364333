import _ from "lodash";
import { SERVER_API, COUNTRIES, CATEGORIES } from "./unit";
import Validator from "validator";

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items)
    .slice(startIndex)
    .take(pageSize)
    .value();
}
export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function dataURLtoFile(data, filename) {
  const arr = data.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  return fetch(data)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mime });
    });
}

export function getAvatar(avatar) {
  if (avatar.startsWith("lib"))
    return SERVER_API + "/public" + avatar.substring(3);
  return avatar;
}

export function getCover(cover = "lib/cover/default_cover.jpg") {
  if (cover.startsWith("lib"))
    return SERVER_API + "/public" + cover.substring(3);
  return cover;
}

export function getFlag(nation = "earth") {
  const country = COUNTRIES.filter(
    c =>
      Boolean(c.flag) === true && c.code.toLowerCase() === nation.toLowerCase()
  );
  if (country.length !== 0) {
    return (
      SERVER_API + "/public/flag/" + country[0].code.toLowerCase() + ".png"
    );
  }
  return SERVER_API + "/public/flag/earth.png";
}

export function getArticleCover(category = "Life") {
  let cover = SERVER_API + "/public/category/life-cover.jpg";
  if (CATEGORIES.includes(category)) {
    cover =
      SERVER_API +
      "/public/category/" +
      switchCategory(category) +
      "-cover.jpg";
  }
  return cover;
}

export function getArticleBgCover(category = "Life") {
  let cover = SERVER_API + "/public/category/life.jpg";
  if (CATEGORIES.includes(category)) {
    cover =
      SERVER_API + "/public/category/" + switchCategory(category) + ".jpg";
  }
  return cover;
}

export function trunc20(name = "") {
  if (name.length > 20) {
    return name.substring(0, 18) + "..";
  }
  return name;
}
export function trunc18(name = "") {
  if (name.length > 18) {
    return name.substring(0, 16) + "..";
  }
  return name;
}

export function getRealAuthor(first = false, origin = {}, author = "") {
  if (!Boolean(first) && typeof origin.author !== "undefined") {
    return origin.author;
  }
  return author;
}

export function getArticleCharLimit(level = 1) {
  switch (level) {
    case 1:
      return 1200;
    case 2:
      return 2000;
    case 3:
      return 3300;
    case 4:
      return 4900;
    case 5:
      return 6100;
    case 6:
      return 8000;
    case 7:
      return 11000;
    case 8:
      return 20000;
    case 9:
      return 30000;
    case 10:
      return 50000;
    default:
      return 0;
  }
}

export function getCommentCharLimit(level = 1) {
  switch (level) {
    case 1:
      return 140;
    case 2:
      return 220;
    case 3:
      return 300;
    case 4:
      return 450;
    case 5:
      return 540;
    case 6:
      return 780;
    case 7:
      return 1000;
    case 8:
      return 1000;
    case 9:
      return 1500;
    case 10:
      return 1500;
    default:
      return 0;
  }
}

export function strInArray(arr = [], str = "") {
  let res = false;
  arr.forEach(name => {
    if (
      name === str ||
      (str.substring(0, name.length) === name && name.length !== 1)
    )
      res = true;
  });
  return res;
}

export function switchCategory(str = "") {
  return str
    .toLowerCase()
    .split(" ")
    .filter(s => s !== "&")
    .join("-");
}

export function validateURL(value) {
  const str = !isEmpty(value) ? value : "";
  return Validator.isURL(str);
}
export function validateEmail(email) {
  const str = !isEmpty(email) ? email : "";
  return Validator.isEmail(str);
}

export function toDashedLink(param1 = "", param2 = "") {
  if (param2 === "") return toDash(param1);
  return toDash(param1) + "-" + toDash(param2);
}
function toDash(str = "") {
  if (str.length !== 0)
    return str
      .trim()
      .toLowerCase()
      .split(" ")
      .join("-");
  return str;
}
