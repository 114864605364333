import { ACTIVITY_LOADING, SET_ACTIVITY } from "../actions/types";

const initialState = {
  loading: false,
  activities: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIVITY_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ACTIVITY:
      return {
        ...state,
        activities: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
