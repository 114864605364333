import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import artsReducer from "./artsReducer";
import artReducer from "./artReducer";
import comArtReducer from "./comArtReducer";
import msgReducer from "./msgReducer";
import friendReducer from "./friendReducer";
import activityReducer from "./activityReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  arts: artsReducer,
  art: artReducer,
  comArt: comArtReducer,
  msg: msgReducer,
  friendship: friendReducer,
  act: activityReducer
});
