import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import { get_full_activity } from "../../../actions/activityActions";
import ActivityDetail from "./ActivityDetail";
import InfiniteLoading from "../../common/InfiniteLoading";
// import ActivityComment from "./ActivityComment";
// import { get_profile_comments } from "../../../actions/activityActions";

class Activity extends Component {
  state = {};
  async componentDidMount() {
    const { user } = this.props.auth;
    await this.props.get_full_activity(user.id);
  }
  render() {
    const { loading, activities } = this.props.act;
    if (loading) return <InfiniteLoading heightt={120} />;
    if (activities.length === 0)
      return (
        <p>
          <FormattedMessage
            id="activity.load-error"
            defaultMessage="Something was wrong, please refresh the page and try agin .."
          />
        </p>
      );
    return (
      <div className="activity">
        <ul className="list-unstyled">
          {/* activities.map(a => (
            <ActivityComment key={a._id} comment={a} />
          )) */}
          {activities.map(a => (
            <ActivityDetail key={a._id} activity={a} />
          ))}
        </ul>
      </div>
    );
  }
}
Activity.propTypes = {
  get_full_activity: PropTypes.func.isRequired,
  act: PropTypes.object.isRequired,
  errors: PropTypes.object
};
const mapStateToProps = state => ({
  auth: state.auth,
  act: state.act,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { get_full_activity }
)(Activity);
