import {
  ARTICLES_LOADING,
  SET_ARTICLE_PRIVATE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE
} from "../actions/types";

const initialState = {
  loading: false,
  arts: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ARTICLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ARTICLE_PRIVATE:
      return {
        ...state,
        arts: action.payload,
        loading: false
      };
    case CREATE_ARTICLE:
      return {
        ...state,
        arts: state.arts.concat(action.payload)
      };
    case UPDATE_ARTICLE:
      return {
        ...state,
        arts: state.arts.map(a =>
          a._id === action.payload._id ? action.payload : a
        )
      };
    case DELETE_ARTICLE:
      return {
        ...state,
        arts: state.arts.filter(a => a._id !== action.payload._id)
      };
    default:
      return state;
  }
}
