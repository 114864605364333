import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import toastr from "toastr";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import TextModernField from "../common/TextModern";
import { isEmpty } from "../../assets/utils/func";
import InfiniteLoading from "../common/InfiniteLoading";
import http from "../../services/http";
import loader from "../../assets/img/loading.gif";
import "./auth.scss";

class VerificationPwd extends Component {
  constructor() {
    super();
    this.state = {
      verifying: true,
      errors: {},
      verified: false,
      account: {},
      password: "",
      password1: "",
      submit: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    const { jwt } = this.props.match.params;
    const token = "Bearer " + jwt;
    try {
      const info = jwt_decode(token);
      if (typeof info.exp === "undefined" || info.exp < Date.now() / 1000) {
        const error = this.props.intl.formatMessage({
          id: "verify.error-link"
        });
        toastr.warning(error);
        this.setState({ verifying: false, errors: { error } });
      } else {
        this.verifyPwd(info);
      }
    } catch (ex) {
      const er = this.props.intl.formatMessage({ id: "verify.error-invalid" });
      toastr.info(er);
      this.setState({ verifying: false, errors: { error: er } });
    }
  }
  verifyPwd = async (info = {}) => {
    try {
      const { data } = await http.post(`/api/users/pwd-verify/${info._id}`, {
        salt: info.salt
      });
      if (!data.success) {
        this.setState({ verifying: false });
        toastr.warning(data.error);
      } else {
        this.setState({ verifying: false, verified: true, account: data });
      }
    } catch (ex) {
      const error = this.props.intl.formatMessage({
        id: "verify.email-failure"
      });
      toastr.warning(error);
      this.setState({ verifying: false, errors: { error } });
    }
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }
  onSubmit(e) {
    e.preventDefault();
    const { password, password1 } = this.state;
    let errors = {};
    if (password !== password1) {
      errors.password1 = this.props.intl.formatMessage({
        id: "verify.pass-nomatch"
      });
    }
    if (isEmpty(password) || password.trim().length < 6) {
      errors.password = this.props.intl.formatMessage({
        id: "verify.pass-char"
      });
    }
    if (isEmpty(password1) || password1.trim().length < 6) {
      errors.password1 = this.props.intl.formatMessage({
        id: "verify.pass-char"
      });
    }
    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ submit: true });
      this.doSubmit();
    }
  }
  doSubmit = async () => {
    const { password, password1, account } = this.state;
    try {
      const { data } = await http.post(`/api/users/pwd-update/${account._id}`, {
        password,
        password1
      });
      this.setState({ submit: false });
      if (!data.success) {
        toastr.warning(data.error);
      } else {
        toastr.success("Password update success");
        window.location.href = "/login";
      }
    } catch (ex) {
      toastr.warning("Server connection failed:");
      this.setState({ submit: false });
    }
  };
  render() {
    const {
      verifying,
      verified,
      errors,
      account,
      password,
      password1,
      submit
    } = this.state;
    const { intl } = this.props;
    const passPlaceholder = intl.formatMessage({
      id: "verify.pass-placeholder"
    });
    const pass1Placeholder = intl.formatMessage({ id: "verify.pass-confirm" });
    if (verifying) {
      return (
        <InfiniteLoading
          content={intl.formatMessage({ id: "verify.loading" })}
        />
      );
    } else if (!verifying && verified) {
      return (
        <div className="verification">
          <div className="password-reset">
            <form onSubmit={this.onSubmit} noValidate className="pwd-recover">
              <h3>
                <FormattedMessage
                  id="verify.account"
                  defaultMessage="Account: "
                />
                <span className="account">{account.email}</span>
              </h3>
              <TextModernField
                name="password"
                type="password"
                placeholder={passPlaceholder}
                value={password}
                onChange={this.onChange}
                error={errors.password}
              />
              <TextModernField
                name="password1"
                type="password"
                placeholder={pass1Placeholder}
                value={password1}
                onChange={this.onChange}
                error={errors.password1}
              />
              <button type="submit" className="btn btn-ctl btn-block">
                {submit ? (
                  <img className="refresh" src={loader} alt="refresh" />
                ) : (
                  <span className="load">
                    <FormattedMessage
                      id="verify.pass-update"
                      defaultMessage="Update Password"
                    />
                  </span>
                )}
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className="verification">
          <div className="password-reset">
            <h2>
              <FormattedMessage id="verify.error" defaultMessage="Error: " />
              {typeof errors.error !== "undefined" && errors.error}
            </h2>
          </div>
        </div>
      );
    }
  }
}
VerificationPwd.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(VerificationPwd);
