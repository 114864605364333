import http from "../services/http";
import {
  FRIENDS_LOADING,
  SET_FRIENDS,
  SET_FRIEND_REQUEST,
  ANSWER_FRIEND_REQUEST,
  REMOVE_FRIEND,
  MAKE_NEW_FRIEND
} from "./types";
import { handleError } from "../assets/utils/handleError";

export const get_requests_list = () => dispatch => {
  http
    .get("/api/friendships/f_request")
    .then(res =>
      dispatch({
        type: SET_FRIEND_REQUEST,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
export const get_friends_list = () => dispatch => {
  dispatch({ type: FRIENDS_LOADING });
  http
    .get("/api/friendships/")
    .then(res =>
      dispatch({
        type: SET_FRIENDS,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
export const send_friend_request = (f_id, data) => dispatch => {
  return http.post(`/api/friendships/${f_id}`, data);
};

export const answer_friend_request = (
  r_id,
  data,
  request,
  friend
) => dispatch => {
  http
    .put(`/api/friendships/${r_id}`, data)
    .then(res => {
      if (
        typeof res.data.message !== "undefined" &&
        res.data.success &&
        res.data.message === "ACCEPTED"
      ) {
        dispatch({ type: ANSWER_FRIEND_REQUEST, payload: request });
        dispatch({
          type: MAKE_NEW_FRIEND,
          payload: friend
        });
      }
      if (
        typeof res.data.message !== "undefined" &&
        res.data.success &&
        res.data.message === "IGNORED"
      ) {
        dispatch({ type: ANSWER_FRIEND_REQUEST, payload: request });
      }
    })
    .catch(err => handleError(err, dispatch));
};

export const remove_friend = f_id => dispatch => {
  http
    .delete(`/api/friendships/${f_id}`)
    .then(res =>
      dispatch({
        type: REMOVE_FRIEND,
        payload: f_id
      })
    )
    .catch(err => handleError(err, dispatch));
};
