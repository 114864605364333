import React, { Component } from "react";

class Modal extends Component {
  render() {
    const { htmlTitle, htmlContent, htmlID, htmlButton } = this.props;
    return (
      <div
        className="modal fade"
        id={htmlID}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={htmlID + Date.now()}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={htmlID + Date.now()}>
                {htmlTitle}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{htmlContent}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-ctl"
                data-dismiss="modal"
              >
                Close
              </button>
              {htmlButton}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
