import React, { Component } from "react";
import { Link } from "react-router-dom";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import http from "../../services/http";

class Search extends Component {
  constructor() {
    super();
    this.state = {
      word: "",
      articles: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleChange(e) {
    this.setState({ word: e.target.value });
    try {
      const { data } = await http.get(
        `/api/search/article?article=${e.target.value}`
      );
      if (data.length !== 0) this.setState({ articles: data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.log("Search failed");
    }
  }
  getSearchList = () => {
    const { articles } = this.state;
    if (articles.length === 0)
      return <FormattedMessage id="search.nomatch" defaultMessage="No match" />;
    return (
      <div className="search-results">
        {articles.map(a => (
          <Link className="result-item" to={`/articles/${a._id}`} key={a._id}>
            <span className="title">{a.title}</span>
            <span className="pull-right">
              <span className="text-muted mr-2">
                {this.props.intl.formatMessage({ id: "search.by" })}
              </span>
              {a.author.name}
            </span>
          </Link>
        ))}
      </div>
    );
  };
  async handleSubmit(e) {
    e.preventDefault();
    console.log("submitting");
    const { word } = this.state;
    try {
      const { data } = await http.get(`/api/search/article?article=${word}`);
      if (data.length !== 0) this.setState({ articles: data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.log("Search failed");
    }
  }
  render() {
    const { word } = this.state;
    const inputPlaceholder = this.props.intl.formatMessage({
      id: "search.placeholder"
    });
    return (
      <div className="search-box mx-auto">
        <input
          type="text"
          className="search-input"
          id="searchBox"
          placeholder={inputPlaceholder}
          autoComplete="off"
          onChange={this.handleChange}
          value={word}
        />
        {word && this.getSearchList()}
      </div>
    );
  }
}
Search.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(Search);
