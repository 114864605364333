import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title = "", data = [] }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {data.map(d => {
        const name = d.label;
        const content = d.value;
        return <meta name={name} content={content} key={Date.now()} />;
      })}
    </Helmet>
  );
};

export default SEO;
