import React, { Component } from "react";
import PropTypes from "prop-types";
import "./checkboxModen.css";

class CheckBoxModern extends Component {
  render() {
    const { onAgree, checked, content, error, name } = this.props;
    const id = "switch" + Math.random().toString();
    return (
      <div className={`form-modern-checkbox ${error ? "must-agree" : ""}`}>
        <input
          type="checkbox"
          id={id}
          name={name}
          onChange={onAgree}
          checked={checked}
        />
        <label htmlFor={id} className="check-label" />
        <span className="check-content">{content}</span>
      </div>
    );
  }
}
CheckBoxModern.propTypes = {
  onAgree: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};
CheckBoxModern.defaultProps = {
  name: "check"
};

export default CheckBoxModern;
