import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import SelectList from "../../common/SelectList";
import { add_language, remove_language } from "../../../actions/userActions";
import { LANGUAGES } from "../../../assets/utils/unit";

class LanguageInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: [],
      lang: "",
      stage: "",
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const { user } = this.props.auth;
    this.setState({ language: user.language || [] });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.auth !== this.props.auth) {
      this.setState({ language: nextProps.auth.user.language || [] });
    }
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSubmit(e) {
    e.preventDefault();
    const { lang, stage, errors } = this.state;
    if (lang === "" || lang === "0") {
      errors.lang = this.props.intl.formatMessage({ id: "dset.lang-valid" });
    } else {
      delete errors.lang;
    }
    if (stage === "" || stage === "0") {
      errors.stage = this.props.intl.formatMessage({ id: "dset.lang-valid" });
    } else {
      delete errors.stage;
    }
    if (Object.keys(errors).length !== 0) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors, lang: "", stage: "" });
      this.doAddLanguage({ lang, stage });
    }
  }
  doAddLanguage = async data => {
    try {
      await this.props.add_language(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Updating language failed");
      const msg = this.props.intl.formatMessage({
        id: "dset.lang-update-fail"
      });
      toastr.warning(msg);
    }
  };
  doRemoveLanguage = async lang_id => {
    try {
      await this.props.remove_language(lang_id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Delete language failed");
      const msgg = this.props.intl.formatMessage({ id: "dset.lang-error" });
      toastr.warning(msgg);
    }
  };
  render() {
    const { language, errors, lang, stage } = this.state;
    const { intl } = this.props;
    const optionsLang = [
      { label: intl.formatMessage({ id: "dset.lang-select" }), value: 0 }
    ].concat(
      LANGUAGES.map(lang => {
        const label = lang.name + " - " + lang.nativeName;
        const value = lang.name;
        return { label, value };
      })
    );
    const optionsStage = [
      { label: intl.formatMessage({ id: "dset.lang-level-select" }), value: 0 },
      {
        label: intl.formatMessage({ id: "dset.lang-beginning" }),
        value: "Beginning"
      },
      {
        label: intl.formatMessage({ id: "dset.lang-intermediate" }),
        value: "Intermediate"
      },
      {
        label: intl.formatMessage({ id: "dset.lang-fluent" }),
        value: "Fluent"
      },
      { label: intl.formatMessage({ id: "dset.lang-native" }), value: "Native" }
    ];
    const langInfo = intl.formatMessage({ id: "dset.lang-opt-info" });
    const levelInfo = intl.formatMessage({ id: "dset.lang-level-info" });
    return (
      <div className="profile-language">
        <h4>
          <FormattedMessage
            id="dset.lang-title"
            defaultMessage="Language skills"
          />
        </h4>
        <div className="lang-fields">
          {language.length !== 0 &&
            language.map(l => (
              <span key={l._id} className="lang-item">
                <FormattedMessage
                  id="dset.lang-speak"
                  defaultMessage="Speaking: "
                />{" "}
                {l.lang}
                {"  "}
                <FormattedMessage
                  id="dset.lang-level"
                  defaultMessage="Level: "
                />{" "}
                {l.stage}
                <i
                  className="fas fa-times"
                  onClick={() => this.doRemoveLanguage(l._id)}
                />
              </span>
            ))}
        </div>
        <div className="clear-fix" />
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-5 col-sm-5 col-xs-12">
              <SelectList
                name="lang"
                value={lang}
                onChange={this.handleChange}
                error={errors.lang}
                options={optionsLang}
                info={langInfo}
              />
            </div>
            <div className="col-md-5 col-sm-5 col-xs-12">
              <SelectList
                name="stage"
                value={stage}
                onChange={this.handleChange}
                error={errors.stage}
                options={optionsStage}
                info={levelInfo}
              />
            </div>
            <div className="col-md-2 col-sm-2 col-xs-12">
              <button
                type="submit"
                className="btn btn-outline-secondary btn-set"
              >
                <FormattedMessage
                  id="dset.lang-btn"
                  defaultMessage="Add Language"
                />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
LanguageInfo.propTypes = {
  intl: intlShape.isRequired,
  add_language: PropTypes.func.isRequired,
  remove_language: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { add_language, remove_language }
)(injectIntl(LanguageInfo));
