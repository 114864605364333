import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import toastr from "toastr";
import { loginUser } from "../../actions/authActions";
//import { reportOnline } from "../../io/routine";
import Oauth from "./oauth";
import TextModernField from "../common/TextModern";
import CheckBoxModern from "../common/CheckBoxModern";
import { PROVIDER } from "../../assets/utils/unit";
import { socket } from "../../io/routine";
import loader from "../../assets/img/loading.gif";
import "./auth.scss";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      submit: false,
      remember: false,
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.handleAgree = this.handleAgree.bind(this);
  }
  componentDidMount() {
    const { from } = this.props.location.state || {
      from: { pathname: "/dashboard" }
    };
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(from);
    }
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.auth.isAuthenticated) {
      //reportOnline(nextProps.auth.user);
      toastr.info(this.props.intl.formatMessage({ id: "login.success" }));
      this.props.history.push("/dashboard");
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, submit: false });
    }
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }
  handleAgree() {
    this.setState({ remember: !this.state.remember });
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({ submit: true });
    const userData = {
      email: this.state.email,
      password: this.state.password,
      remember: this.state.remember
    };
    try {
      this.props.loginUser(userData);
    } catch (ex) {
      toastr.warning(this.intl.formatMessage({ id: "login.error" }));
    }
  }
  render() {
    const { errors, submit, remember } = this.state;
    const { intl } = this.props;
    const emailPlaceholder = intl.formatMessage({
      id: "login.email-placeholder"
    });
    const passPlaceholder = intl.formatMessage({
      id: "login.password-placeholder"
    });
    const content = intl.formatMessage({ id: "login.agree" });
    return (
      <div className="auth">
        <div className="star" id="stars" />
        <div className="star" id="stars2" />
        <div className="star" id="stars3" />
        <div className="auth-wrapper">
          <div className="social-auth">
            {PROVIDER.map(provider => (
              <Oauth provider={provider} key={provider} socket={socket} />
            ))}
            <div className="fire" />
          </div>
          <div className="customer-auth">
            <h2 className="auth-header">
              <FormattedMessage id="login.login" defaultMessage="Login" />
            </h2>
            <form
              onSubmit={this.onSubmit.bind(this)}
              noValidate
              className="form-signin"
            >
              <TextModernField
                name="email"
                placeholder={emailPlaceholder}
                type="email"
                value={this.state.email}
                onChange={this.onChange}
                error={errors.email}
              />
              <TextModernField
                name="password"
                placeholder={passPlaceholder}
                type="password"
                value={this.state.password}
                onChange={this.onChange}
                error={errors.password}
              />

              <CheckBoxModern
                onAgree={this.handleAgree}
                checked={remember}
                name="remember"
                content={content}
                error={errors.terms}
              />

              <button type="submit" className="btn btn-ctl btn-block">
                {submit ? (
                  <img className="refresh" src={loader} alt="refresh" />
                ) : (
                  <span className="load">
                    <FormattedMessage
                      id="login.submit-btn"
                      defaultMessage="Login"
                    />
                  </span>
                )}
              </button>
              <div className="miscellaneous">
                <p className="text-muted">
                  <FormattedMessage
                    id="login.forget"
                    defaultMessage="forget your passowrd? "
                  />
                  <Link to="/password-recover">
                    <FormattedMessage
                      id="login.forget-click"
                      defaultMessage="Click here"
                    />
                  </Link>
                </p>
                <Link to="/register" className="redirect-btn">
                  <FormattedMessage
                    id="login.signup"
                    defaultMessage="Sign Up"
                  />
                  <i className="fa fa-arrow-right ml-1" />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  intl: intlShape.isRequired,
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { loginUser }
)(injectIntl(Login));
