import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedRelative
} from "react-intl";
import { isEmpty, getAvatar, getArticleBgCover } from "../../assets/utils/func";
import SEO from "../common/SEO";
import ArticleMember from "./ArticleMember";
import CommentArticle from "../comment/CommentArticle";
import RatingArticle from "../rating/RatingArticle";
import { searchAritlceByID } from "../../services/search";
import InfiniteLoading from "../common/InfiniteLoading";
import { toastr } from "toastr";

import shield from "../../assets/img/shield-sm.png";
import "./Article.scss";

class Article extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      article: {}
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    const { a_id } = this.props.match.params;
    if (a_id.length !== 24) {
      toastr.info("Invalid URI " + a_id);
      this.props.history.push("/not-found");
    } else {
      this.fetchArticle(a_id);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
    if (nextProps !== this.props) {
      const { a_id } = nextProps.match.params;
      if (a_id.length !== 24) {
        this.props.history.push("/not-found");
      } else {
        this.fetchArticle(a_id);
      }
    }
  }
  fetchArticle = async a_id => {
    try {
      const { data } = await searchAritlceByID(a_id);
      this.setState({ article: data, loading: false });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.setState({ loading: false });
      }
      const msg = this.props.intl.formatMessage({ id: "app.fetch-fail" });
      toastr.warning(msg);
    }
  };
  renderOrigin = ({ author, link }) => {
    return (
      <div className="article-origin">
        <span className="origin-author">
          <Link
            to={`/redirect/${encodeURIComponent(link.toString())}`}
            className="origin-author-link"
          >
            {author}
          </Link>
        </span>
      </div>
    );
  };

  render() {
    const { a_id } = this.props.match.params;
    const { article, loading } = this.state;
    const load = this.props.intl.formatMessage({ id: "app.loading" });
    if (loading) return <InfiniteLoading heightt={300} content={load} />;
    if (isEmpty(article) && !loading)
      return (
        <div className="article not-found">
          <p>
            <FormattedMessage
              id="article.notfound"
              defaultMessage="Article not found or has been deleted"
            />
          </p>
        </div>
      );
    const {
      author,
      title,
      draft,
      category,
      seo,
      original,
      origin,
      score,
      updatedAt
    } = article;
    const { _id, name, avatar } = author;
    const editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(article.content))
    );
    const meta = [
      { label: "description", value: seo.join(",") },
      { label: "author", value: author.name },
      { label: "keywords", value: seo.join(",") }
    ];
    const coverStyle = {
      backgroundImage: `url(${getArticleBgCover(category)})`
    };
    return (
      <div className={`article ${original ? "protect" : "public"}`}>
        <div className="article-category-picture" style={coverStyle}>
          <SEO title={title} data={meta} />
        </div>
        <div className="article-article shadow mx-auto">
          <div className="heading">
            <h2 className="display-3">{title}</h2>
          </div>

          {original ? (
            <div className="article-origin">
              <img src={shield} className="origin-icon" alt="icon" />
            </div>
          ) : (
            this.renderOrigin(origin)
          )}

          <div className="author">
            <p className="text-right">
              <Link to={`/profile/${_id}`}>
                <img
                  src={getAvatar(avatar)}
                  alt={name}
                  className="img-circle"
                />
                <span className="ml-2">{name}</span>
              </Link>
              <span className="pl-2">
                {updatedAt && <FormattedRelative value={updatedAt} />}
              </span>
            </p>
          </div>
          <div className="article-content">
            <Editor
              editorState={editorState}
              readOnly={true}
              toolbarHidden={true}
            />
          </div>
          {seo.length !== 0 ? (
            <div className="article-seo">
              <h4>
                <FormattedMessage id="article.tag" defaultMessage="Tags" />
              </h4>
              <div className="article-tags">
                {seo.map((s, i) => (
                  <span
                    className="tag-item"
                    key={i.toString() + Date.now().toString()}
                  >
                    {s}
                  </span>
                ))}
                <div className="clearfix" />
              </div>
            </div>
          ) : null}
        </div>
        {!draft ? (
          <React.Fragment>
            <RatingArticle a_id={a_id} score={score} />
            <ArticleMember u_id={_id} category={category} />
            <CommentArticle a_id={a_id} author={author} />
          </React.Fragment>
        ) : null}
        <div className="space" />
      </div>
    );
  }
}
Article.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(Article);
