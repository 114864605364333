import React, { Component } from "react";
import InfiniteLoading from "./InfiniteLoading";

class RedirectURL extends Component {
  constructor(props) {
    super();
    this.state = {
      ...props
    };
  }
  componentDidMount() {
    const { link } = this.props.match.params;
    const outLink = decodeURIComponent(link);
    window.location.replace(outLink);
  }
  render() {
    const content = <h2 className="m-2">Redirecting</h2>;
    return (
      <div className="redirect">
        <InfiniteLoading content={content} />
      </div>
    );
  }
}

export default RedirectURL;
