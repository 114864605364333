import http from "./http";

export const get_comment_rating = c_id => {
  return http.get(`/api/ratings/comment/${c_id}`);
};

export const update_rating = (r_id, data) => {
  return http.put(`/api/ratings/comment/${r_id}`, data);
};

export const get_article_rating = a_id => {
  return http.get(`/api/ratings/article/${a_id}`);
};

export const update_article_rating = (r_id, data) => {
  return http.put(`/api/ratings/article/${r_id}`, data);
};
