import React, { Component } from "react";
import queryString from "query-string";
import Search from "./Search";
import TopSix from "./TopSix";
import ArticleCategories from "./ArticleCategories";
import "./Articles.scss";

class Articles extends Component {
  constructor() {
    super();
    this.state = {
      category: "life"
    };
  }
  componentDidMount() {
    if (this.props.location.search.indexOf("category") !== -1) {
      const { category } = queryString.parse(this.props.location.search);
      this.setState({ category });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { category } = queryString.parse(nextProps.location.search);
      this.setState({ category });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="search-article">
          <Search />
        </div>
        <div className="articles mx-auto">
          <TopSix />
          <div className="article-category">
            <ArticleCategories category={this.state.category} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Articles;
