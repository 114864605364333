import React, { Component } from "react";
import { Link } from "react-router-dom";
import toastr from "toastr";
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedDate
} from "react-intl";
import http from "../../services/http";
import TableModen from "../common/TableModen";
import SEO from "../common/SEO";
import { CATEGORY, CATEGORIES } from "../../assets/utils/unit";
import { getAvatar } from "../../assets/utils/func";
import shield from "../../assets/img/shield-sm.png";

class ArticleCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
      articles: [],
      sortColumn: { path: "createdAt", order: "asc" },
      errors: {}
    };
    this.getColumns = this.getColumns.bind(this);
  }
  async componentDidMount() {
    const { category } = this.props;
    try {
      const { data } = await http.get(`/api/articles/category/${category}`);
      this.setState({ articles: data });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Error fetching articles by category");
      }
      const msg = this.props.intl.formatMessage({ id: "app.fetch-fail" });
      toastr.warning(msg);
    }
  }
  async componentWillReceiveProps(nextProps) {
    const { category } = nextProps;
    if (nextProps !== this.props) {
      this.setState({ animate: true });
      try {
        const { data } = await http.get(`/api/articles/category/${category}`);
        this.setState({ articles: data, animate: false });
      } catch (ex) {
        if (ex.response && ex.response.status === 404) {
          console.log("Error fetching articles by category");
        }
        const msgg = this.props.intl.formatMessage({ id: "app.fetch-fail" });
        this.setState({ animate: false });
        toastr.warning(msgg);
      }
    }
  }
  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };
  getColumns = () => {
    const { intl } = this.props;
    return [
      {
        path: "title",
        label: intl.formatMessage({ id: "cate.label-title" }),
        content: article => (
          <Link to={`/articles/${article._id}`}>{article.title}</Link>
        )
      },
      {
        path: "original",
        label: intl.formatMessage({ id: "cate.label-copyright" }),
        content: article =>
          article.original ? (
            <img src={shield} className="img-circle-sm" alt="quote" />
          ) : (
            ""
          )
      },
      {
        path: "author",
        label: intl.formatMessage({ id: "cate.label-author" }),
        content: article => (
          <Link to={`/profile/${article.author._id}`}>
            <img
              className="img-circle-sm"
              src={getAvatar(article.author.avatar)}
              alt={article.author.name}
            />
            <span className="ml-2">{article.author.name}</span>
          </Link>
        )
      },
      {
        path: "score",
        label: intl.formatMessage({ id: "cate.label-rating" }),
        content: article => <span className="score">{article.score}</span>
      },
      {
        path: "createdAt",
        label: intl.formatMessage({ id: "cate.label-date" }),
        content: article => (
          <FormattedDate
            value={article.createdAt}
            year="numeric"
            month="short"
            day="2-digit"
          />
        )
      }
    ];
  };
  render() {
    const { articles, sortColumn, animate } = this.state;
    const { category } = this.props;
    const lang = localStorage.getItem("lang") || "en";
    const seo = [
      { label: "description", value: CATEGORIES.join(",") },
      { label: "keywords", value: CATEGORIES.join(",") }
    ];
    return (
      <React.Fragment>
        <div className="category">
          <SEO data={seo} />
          <div className="category-category">
            <h2>
              <FormattedMessage
                id="cate.title"
                defaultMessage="View articles by category"
              />
            </h2>
            <div className="category-flex">
              {CATEGORY.map((c, i) => (
                <span
                  className={
                    category === c.query
                      ? "category-item active"
                      : "category-item"
                  }
                  key={i.toString() + Date.now().toString()}
                >
                  <Link
                    to={`/articles?category=${c.query}`}
                    className="category-link"
                  >
                    {c[lang]}
                  </Link>
                </span>
              ))}
            </div>
          </div>
        </div>
        {articles.length !== 0 && (
          <div className={`category-article ${animate ? "animate" : ""}`}>
            <TableModen
              columns={this.getColumns()}
              data={articles}
              sortColumn={sortColumn}
              onSort={this.handleSort}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
ArticleCategories.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(ArticleCategories);
