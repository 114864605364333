import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Joi from "joi-browser";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { updatePassword } from "../../../actions/userActions";
import TextField from "../../common/TextField";

class Password extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password1: "",
      password2: "",
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  schema = {
    password: Joi.string()
      .required()
      .trim()
      .min(6)
      .max(40)
      .label("Current Password"),
    password1: Joi.string()
      .required()
      .trim()
      .min(6)
      .max(40)
      .label("New Password"),
    password2: Joi.string()
      .required()
      .trim()
      .min(6)
      .max(40)
      .label("Confirm New Password")
  };
  validate = () => {
    const options = { abortEarly: false };
    const { password, password1, password2 } = this.state;
    let data = {};
    data.password = password;
    data.password1 = password1;
    data.password2 = password2;
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    if (password1 !== password2)
      errors.password2 = this.props.intl.formatMessage({ id: "dpass.nomatch" });
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }
    this.setState({ [input.name]: input.value, errors });
  };
  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };
  doSubmit = async () => {
    const { password, password1, password2 } = this.state;
    let data = {};
    data.password = password;
    data.password1 = password1;
    data.password2 = password2;
    try {
      await this.props.updatePassword(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Update Password failed");
      const msg = this.props.intl.formatMessage({
        id: "dpass.failure"
      });
      toastr.warning(msg);
    }
  };
  render() {
    const { errors } = this.state;
    const { intl } = this.props;
    const passPlaceholder = intl.formatMessage({ id: "dpass.label1" });
    const pass1Placeholder = intl.formatMessage({ id: "dpass.label2" });
    const pass2Placeholder = intl.formatMessage({ id: "dpass.label3" });
    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          name="password"
          placeholder={passPlaceholder}
          type="password"
          value={this.state.password}
          onChange={this.handleChange}
          error={errors.password}
        />
        <TextField
          name="password1"
          placeholder={pass1Placeholder}
          type="password"
          value={this.state.password1}
          onChange={this.handleChange}
          error={errors.password1}
        />
        <TextField
          name="password2"
          placeholder={pass2Placeholder}
          type="password"
          value={this.state.password2}
          onChange={this.handleChange}
          error={errors.password2}
        />
        <button type="submit" className="btn btn-outline-secondary btn-block">
          <FormattedMessage
            id="dpass.update"
            defaultMessage="Update Password"
          />
        </button>
      </form>
    );
  }
}
Password.propTypes = {
  intl: intlShape.isRequired,
  updatePassword: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { updatePassword }
)(injectIntl(Password));
