import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { logoutUser } from "../../actions/authActions";
import { getAvatar, strInArray } from "../../assets/utils/func";
import logo from "../../assets/img/donacoo_logo.png";
import "./Navbar.scss";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      urlActive: [
        "/",
        "/login",
        "/register",
        "/dashboard",
        "/articles",
        "/profile",
        "/music",
        "/gallery"
      ],
      currentPath: "/"
    };
  }
  componentDidMount() {
    const currentPath = window.location.pathname;
    this.setState({ currentPath });
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.location !== this.props.location) {
      const currentPath = nextProps.location.pathname;
      this.setState({ currentPath });
    }
  };
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    window.location.href = "/login";
  }
  render() {
    const { urlActive, currentPath } = this.state;
    const isActive = strInArray(urlActive, currentPath);
    const { isAuthenticated, user } = this.props.auth;
    if (!isAuthenticated || typeof user.avatar === "undefined")
      user.avatar = "lib/avatar/default_avatar.jpg";
    const authLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown item-7">
          <div
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FormattedMessage id="nav.profile" defaultMessage="Profile" />
          </div>
          <div
            className="dropdown-menu bg-dark drop-transparent"
            aria-labelledby="navbarDropdown"
          >
            <Link className=" dropdown-item" to="/dashboard">
              <FormattedMessage id="nav.dashboard" defaultMessage="Dashboard" />
            </Link>
            <Link className=" dropdown-item" to={`/profile/${user.id}`}>
              <FormattedMessage
                id="nav.view-profile"
                defaultMessage="View Profile"
              />
            </Link>
            <Link className="dropdown-item" to="/dashboard/messages/received">
              <FormattedMessage id="nav.messages" defaultMessage="Messages" />
            </Link>
            <Link className="dropdown-item" to="/dashboard/friends">
              <FormattedMessage id="nav.friends" defaultMessage="Friends" />
            </Link>
            <Link className="dropdown-item" to="/dashboard/settings">
              <FormattedMessage id="nav.settings" defaultMessage="Settings" />
            </Link>
            <div className="dropdown-divider" />
            <button
              className="dropdown-item"
              onClick={this.onLogoutClick.bind(this)}
            >
              <FormattedMessage id="nav.logout" defaultMessage="Logout" />
            </button>
          </div>
        </li>
        <li className="nav-item">
          <Link to={`/profile/${user.id}`}>
            <img
              src={getAvatar(user.avatar)}
              className="nav-avatar mr-5"
              alt={user.name}
            />
          </Link>
        </li>
      </ul>
    );
    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item item-5">
          <NavLink className="nav-link" to="/login">
            <FormattedMessage id="nav.login" defaultMessage="Login" />
          </NavLink>
        </li>
        <li className="nav-item item-6">
          <NavLink className="nav-link" to="/register">
            <FormattedMessage id="nav.signup" defaultMessage="Sign Up" />
          </NavLink>
        </li>
      </ul>
    );
    return (
      <nav
        className={`navbar navbar-expand-lg navbar-stick-top navbar-dark bg-dark nav-transparent ${
          isActive ? "active" : ""
        }`}
      >
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="donacoo_logo" className="nav-logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item item-1">
              <NavLink className="nav-link" to="/">
                <FormattedMessage id="nav.home" defaultMessage="Home" />
              </NavLink>
            </li>
            <li className="nav-item item-2">
              <NavLink className="nav-link" to="/articles">
                <FormattedMessage id="nav.articles" defaultMessage="Articles" />
              </NavLink>
            </li>
            {/*
            <li className="nav-item item-3">
              <NavLink className="nav-link disabled" to="/music">
                Music
              </NavLink>
            </li>
            <li className="nav-item item-4">
              <NavLink className="nav-link disabled" to="/gallery">
                Gallery
              </NavLink>
            </li>
            */}
          </ul>
          {isAuthenticated ? authLinks : guestLinks}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});
export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser }
  )(Navbar)
);
