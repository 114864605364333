import {
  FRIENDS_LOADING,
  SET_FRIENDS,
  SET_FRIEND_REQUEST,
  MAKE_NEW_FRIEND,
  ANSWER_FRIEND_REQUEST,
  REMOVE_FRIEND
} from "../actions/types";

const initialState = {
  loading: false,
  friends: [],
  requests: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FRIENDS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_FRIENDS:
      return {
        ...state,
        friends: action.payload,
        loading: false
      };
    case SET_FRIEND_REQUEST:
      return {
        ...state,
        requests: action.payload
      };
    case MAKE_NEW_FRIEND:
      return {
        ...state,
        friends: state.friends.concat(action.payload)
      };
    case ANSWER_FRIEND_REQUEST:
      return {
        ...state,
        requests: state.requests.filter(r => r._id !== action.payload._id)
      };
    case REMOVE_FRIEND:
      return {
        ...state,
        friends: state.friends.filter(f => f._id !== action.payload)
      };
    default:
      return state;
  }
}
