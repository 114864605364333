import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import moment from "moment";
import toastr from "toastr";
import { injectIntl, intlShape } from "react-intl";
import { delete_article } from "../../../actions/articleActions";
import { getAvatar } from "../../../assets/utils/func";
import Table from "../../common/table";

class ArticleFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arts: props.arts,
      sortColumn: { path: "createdAt", order: "asc" },
      errors: {}
    };
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.errors) this.setState({ errors: nextProps.errors });
  };
  getArticlesByFolder(articles, folder) {
    return articles.filter(a => a.folder === folder);
  }
  toFolder(folder) {
    return folder.split("-").join(" ");
  }
  handleDelete = async article => {
    const arts = [...this.state.arts];
    const filtered = arts.filter(a => a._id !== article._id);
    this.setState({ arts: filtered });
    try {
      await this.props.delete_article(article._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Deletions failed...");
      const msg = this.props.intl.formatMessage({ id: "dart.del-failure" });
      toastr.warning(msg);
      this.setState({ arts });
    }
  };
  handleSort = sortColumn => {
    this.setState({ sortColumn, currentPage: 1 });
  };
  columns = [
    {
      path: "title",
      label: this.props.intl.formatMessage({ id: "dart.title" }),
      content: article => <p>{article.title}</p>
    },
    {
      path: "author",
      label: this.props.intl.formatMessage({ id: "dart.author" }),
      content: article => (
        <Link to={`/profile/${article.author._id}`}>
          <img
            className="img-circle"
            src={getAvatar(article.author.avatar)}
            alt={article.author.name}
          />
          {article.author.name}
        </Link>
      )
    },
    {
      path: "createdAt",
      label: this.props.intl.formatMessage({ id: "dart.date" }),
      content: article => <p>{moment(article.createdAt).toNow(true)}</p>
    },
    {
      key: "view",
      content: article => (
        <Link to={`/articles/${article._id}`}>
          <i className="fas fa-eye" />
        </Link>
      )
    },
    {
      key: "edit",
      content: article => (
        <Link to={`/dashboard/article-create?edit=${article._id}`}>
          <i className="fas fa-edit" />
        </Link>
      )
    },
    {
      key: "delete",
      content: article => (
        <button
          onClick={() => this.handleDelete(article)}
          className="btn btn-danger btn-sm"
        >
          <i className="fas fa-trash" />
        </button>
      )
    }
  ];
  render() {
    const { arts } = this.state;
    const { folder } = this.props.match.params;
    const articles = this.getArticlesByFolder(arts, this.toFolder(folder));
    const { sortColumn } = this.state;
    return (
      <div className="article-folder">
        {this.toFolder(folder)}
        <Table
          columns={this.columns}
          data={articles}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </div>
    );
  }
}
ArticleFolder.propTypes = {
  intl: intlShape.isRequired,
  delete_article: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { delete_article }
)(injectIntl(ArticleFolder));
