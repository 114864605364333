import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Joi from "joi-browser";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { update_profile } from "../../../actions/userActions";
import TextField from "../../common/TextField";
import SelectList from "../../common/SelectList";
import TextArea from "../../common/TextArea";
import SocialInputs from "./SocialInputs";
import { isEmpty } from "../../../assets/utils/func";
import { COUNTRIES } from "../../../assets/utils/unit";

class ProfileInfo extends Component {
  constructor() {
    super();
    this.state = {
      displaySocialInputs: false,
      handle: "",
      gender: "",
      birthday: "",
      nationality: "",
      location: "",
      bio: "",
      facebook: "",
      twitter: "",
      youtube: "",
      instagram: "",
      linkedin: "",
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  mapFieldToState = data => {
    let handle = "",
      gender = "",
      birthday = "",
      nationality = "",
      location = "",
      bio = "",
      facebook = "",
      twitter = "",
      youtube = "",
      instagram = "",
      linkedin = "";
    if (typeof data.handle !== "undefined") {
      handle = data.handle;
    }
    if (typeof data.nationality !== "undefined") {
      nationality = data.nationality;
    }
    if (typeof data.gender !== "undefined") {
      gender = data.gender;
    }
    if (typeof data.birthday !== "undefined") {
      birthday = data.birthday;
    }
    if (typeof data.location !== "undefined") {
      location = data.location;
    }
    if (typeof data.bio !== "undefined") {
      bio = data.bio;
    }
    if (data.social && typeof data.social.facebook !== "undefined") {
      facebook = data.social.facebook;
    }
    if (data.social && typeof data.social.twitter !== "undefined") {
      twitter = data.social.twitter;
    }
    if (data.social && typeof data.social.youtube !== "undefined") {
      youtube = data.social.youtube;
    }
    if (data.social && typeof data.social.linkedin !== "undefined") {
      linkedin = data.social.linkedin;
    }
    if (data.social && typeof data.social.instagram !== "undefined") {
      instagram = data.social.instagram;
    }
    this.setState({
      handle,
      nationality,
      gender,
      birthday,
      location,
      bio,
      facebook,
      twitter,
      youtube,
      linkedin,
      instagram
    });
  };
  componentDidMount() {
    const { user } = this.props.auth;
    this.mapFieldToState(user);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.auth !== this.props.auth) {
      this.mapFieldToState(nextProps.auth.user);
    }
  }
  handleDisplay = () => {
    this.setState({ displaySocialInputs: !this.state.displaySocialInputs });
  };
  mapStateToObject = () => {
    const {
      handle,
      gender,
      birthday,
      location,
      nationality,
      bio,
      twitter,
      facebook,
      linkedin,
      youtube,
      instagram
    } = this.state;
    let data = {};
    if (!isEmpty(handle)) data = { ...data, handle };
    if (!isEmpty(gender)) data = { ...data, gender };
    if (!isEmpty(birthday)) data = { ...data, birthday };
    if (!isEmpty(location)) data = { ...data, location };
    if (!isEmpty(nationality)) data = { ...data, nationality };
    if (!isEmpty(bio)) data = { ...data, bio };
    if (!isEmpty(twitter)) data = { ...data, twitter };
    if (!isEmpty(facebook)) data = { ...data, facebook };
    if (!isEmpty(linkedin)) data = { ...data, linkedin };
    if (!isEmpty(youtube)) data = { ...data, youtube };
    if (!isEmpty(instagram)) data = { ...data, instagram };
    return data;
  };
  schema = {
    handle: Joi.string()
      .required()
      .trim()
      .min(2)
      .max(40)
      .label("Handle"),
    birthday: Joi.date()
      .min("1900-1-1")
      .max("2006-1-1"),
    gender: Joi.string().label("Gender"),
    nationality: Joi.string().label("Nationality"),
    location: Joi.string()
      .trim()
      .min(2)
      .max(40)
      .label("Location"),
    bio: Joi.string()
      .trim()
      .min(6)
      .max(140)
      .label("Biography"),
    facebook: Joi.string()
      .trim()
      .uri()
      .label("facebook"),
    twitter: Joi.string()
      .trim()
      .uri()
      .label("twitter"),
    youtube: Joi.string()
      .trim()
      .uri()
      .label("youtube"),
    instagram: Joi.string()
      .trim()
      .uri()
      .label("instagram"),
    linkedin: Joi.string()
      .trim()
      .uri()
      .label("linkedin")
  };
  validate = () => {
    const options = { abortEarly: false };
    const profile = this.mapStateToObject();
    const { error } = Joi.validate(profile, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }
    this.setState({ [input.name]: input.value, errors });
  };
  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doUpdateProfile();
  };
  doUpdateProfile = async () => {
    const data = this.mapStateToObject();
    try {
      await this.props.update_profile(data);
      const msg = this.props.intl.formatMessage({ id: "dset.profile-success" });
      toastr.success(msg);
    } catch (ex) {
      const msgg = this.props.intl.formatMessage({
        id: "dset.profile-failure"
      });
      toastr.warning(msgg);
      this.setState({ ...this.state, data });
    }
  };
  render() {
    const {
      handle,
      gender,
      birthday,
      location,
      nationality,
      bio,
      errors,
      displaySocialInputs,
      facebook,
      twitter,
      linkedin,
      youtube,
      instagram
    } = this.state;
    const { intl } = this.props;
    const optionsGender = [
      { label: intl.formatMessage({ id: "dset.gender" }), value: 0 },
      { label: intl.formatMessage({ id: "dset.male" }), value: "Male" },
      { label: intl.formatMessage({ id: "dset.female" }), value: "Female" }
    ];
    const optionsCountry = [
      { label: intl.formatMessage({ id: "dset.nationality" }), value: "earth" }
    ].concat(
      COUNTRIES.filter(c => Boolean(c.flag) === true).map(country => {
        const label = country.name;
        const value = country.code.toLowerCase();
        return { label, value };
      })
    );
    const birth =
      birthday !== "" ? new Date(birthday).toISOString().slice(0, 10) : "";
    const handlePlaceholder = intl.formatMessage({ id: "dset.handle" });
    const handleInfo = intl.formatMessage({ id: "dset.handle-info" });
    const birthdayPlaceholder = intl.formatMessage({ id: "dset.birthday" });
    const birthdayInfo = intl.formatMessage({ id: "dset.birthday-info" });
    const genderInfo = intl.formatMessage({ id: "dset.gender-info" });
    const nationInfo = intl.formatMessage({ id: "dset.nation-info" });
    const locPlaceholder = intl.formatMessage({ id: "dset.loc" });
    const locInfo = intl.formatMessage({ id: "dset.loc-info" });
    const bioPlaceholder = intl.formatMessage({ id: "dset.bio" });
    const bioInfo = intl.formatMessage({ id: "dset.bio-info" });
    const profileBtn = intl.formatMessage({ id: "dset.profile-btn" });
    return (
      <div className="profile-info">
        <h4>
          <FormattedMessage
            id="dset.profile-title"
            defaultMessage="Profile information settings"
          />
        </h4>
        <form onSubmit={this.handleSubmit}>
          <TextField
            placeholder={handlePlaceholder}
            name="handle"
            value={handle}
            onChange={this.handleChange}
            error={errors.handle}
            info={handleInfo}
          />
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <TextField
                placeholder={birthdayPlaceholder}
                name="birthday"
                type="date"
                value={birth}
                onChange={this.handleChange}
                error={errors.birthday}
                info={birthdayInfo}
              />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <SelectList
                name="gender"
                value={gender}
                onChange={this.handleChange}
                error={errors.gender}
                options={optionsGender}
                info={genderInfo}
              />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <SelectList
                name="nationality"
                value={nationality}
                onChange={this.handleChange}
                error={errors.nationality}
                options={optionsCountry}
                info={nationInfo}
              />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <TextField
                placeholder={locPlaceholder}
                name="location"
                value={location}
                onChange={this.handleChange}
                error={errors.location}
                info={locInfo}
              />
            </div>
          </div>
          <TextArea
            placeholder={bioPlaceholder}
            name="bio"
            value={bio}
            onChange={this.handleChange}
            error={errors.bio}
            info={bioInfo}
          />
          <SocialInputs
            displaySocialInputs={displaySocialInputs}
            facebook={facebook}
            youtube={youtube}
            twitter={twitter}
            instagram={instagram}
            linkedin={linkedin}
            errors={errors}
            onChange={this.handleChange}
            onDisplay={this.handleDisplay}
          />
          <input
            type="submit"
            value={profileBtn}
            className="btn btn-outline-secondary btn-set mt-4"
          />
        </form>
      </div>
    );
  }
}
ProfileInfo.propTypes = {
  intl: intlShape.isRequired,
  update_profile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { update_profile }
)(injectIntl(ProfileInfo));
