import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initailState = {};
const middleware = [thunk];
const store = createStore(
  rootReducer,
  initailState,
  compose(applyMiddleware(...middleware))
);

export default store;
