import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { FormattedMessage } from "react-intl";
import http from "../../services/http";
import { getCommentCharLimit } from "../../assets/utils/func";
import { create_article_comments } from "../../actions/commentActions";

class CommentBox extends Component {
  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
      length: 0,
      limit: 140,
      errors: {}
    };
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      const { level } = this.props.auth.user;
      const limit = getCommentCharLimit(level);
      this.setState({ limit });
    }
  }
  count = editorState => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return raw.blocks
      .map(b => b.text.length)
      .reduce((a, b) => {
        return a + b;
      }, 0);
  };
  onEditorStateChange = editorState => {
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) return (window.location.href = "/login");
    let length = this.count(editorState);
    this.setState({
      editorState,
      length
    });
  };
  uploadImageCallBack = file => {
    let formData = new FormData();
    formData.append("comment", file);
    return http
      .post("/api/comments/comment/image", formData)
      .then(res => {
        return { data: { link: res.data } };
      })
      .catch(err => console.log(err.message));
  };
  extractMentions = content => {
    const mentions = [];
    Object.values(content.entityMap).forEach(entity => {
      if (entity.type === "MENTION") {
        mentions.push(entity.data);
      }
    });
    return mentions.map(m => m.url.slice(-24));
  };
  handleSaveComment = () => {
    const { editorState, length, limit, errors } = this.state;
    if (length > limit || length === 0) {
      errors.comment = `Content lenght must not be empty and exceed ${limit} characters`;
    } else {
      delete errors.comment;
    }
    if (Object.keys(errors).length !== 0) {
      this.setState({ errors });
      return;
    }
    this.doSubmit(editorState);
  };
  doSubmit = async editorState => {
    let data = {};
    data.content = convertToRaw(editorState.getCurrentContent());
    data.mention = this.extractMentions(data.content);
    const { a_id } = this.props;
    try {
      await this.props.create_article_comments(a_id, data);
      this.setState({ editorState: EditorState.createEmpty() });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("Submit failed");
      }
    }
  };
  render() {
    const { length, editorState, limit, errors } = this.state;
    const { suggestions } = this.props;
    const lang = localStorage.getItem("lang") || "en";
    return (
      <div className="comment-box">
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-comment"
          editorClassName="editor-comment"
          toolbarClassName="toolbar-comment"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: ["inline", "colorPicker", "link", "emoji", "image"],
            link: { options: ["link"] },
            inline: { options: ["bold", "italic"] },
            image: {
              alt: { present: true, mandatory: true },
              previewImage: true,
              uploadCallback: this.uploadImageCallBack
            }
          }}
          localization={{ locale: lang }}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: suggestions
          }}
        />
        {typeof errors.comment !== "undefined" && (
          <p className="text-danger">{errors.comment}</p>
        )}
        <p className="text-muted text-right">
          <span className={length > limit ? "text-danger" : ""}>{length}</span>/
          {limit}
        </p>
        <button
          type="button"
          onClick={this.handleSaveComment}
          disabled={length > 140 ? "disabled" : ""}
          className="btn btn-comment"
        >
          <FormattedMessage id="comment.btn" defaultMessage="Add Comment" />
        </button>
      </div>
    );
  }
}
CommentBox.propTypes = {
  create_article_comments: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { create_article_comments }
)(CommentBox);
