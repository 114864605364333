import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: false
    };
  }
  handleClick = e => {
    e.preventDefault();
    this.setState({
      isCollapse: !this.state.isCollapse
    });
  };
  render() {
    const { isOpen, obj } = this.props;
    const { title, lists } = obj;
    const { isCollapse } = this.state;
    return (
      <div className="menu-nav">
        <div
          className={`nav-container nav-link pl-3 ${
            isCollapse ? "" : "collapsed"
          }`}
          onClick={this.handleClick}
        >
          <i className={`fas fa-${title.tag}`} />{" "}
          <span className={`nav-text pl-3 ${isOpen ? "show" : ""}`}>
            {title.name}
          </span>
        </div>
        <ul
          className={`side-drop collapse ${isCollapse ? "show" : ""}`}
          id={title.tag}
        >
          {lists.map(list => (
            <li key={list.tag} className="menu-item">
              <NavLink className="nav-link pl-3 " to={list.dest}>
                <i className={`fas fa-${list.tag}`} />{" "}
                <span className={`nav-text pl-3 ${isOpen ? "show" : ""}`}>
                  {list.name}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default DropDown;
