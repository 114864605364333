import React, { Component } from "react";
import Rating from "react-rating";
import { connect } from "react-redux";
import {
  get_article_rating,
  update_article_rating
} from "../../services/rating";

class RatingArticle extends Component {
  constructor() {
    super();
    this.state = {
      r_id: "",
      average: 0,
      rating: 4,
      hasRating: false,
      totalRater: 0
    };
  }
  async componentDidMount() {
    const { a_id, auth } = this.props;
    const { data } = await get_article_rating(a_id);
    if (
      typeof data.member === "undefined" ||
      typeof data.visitor === "undefined"
    ) {
      return console.log("get_article_rating error");
    }
    const r_id = data._id;
    let [totalRater, average] = this.getRatingAverage(data);
    let rating = 4;
    let hasRating = false;
    if (auth.isAuthenticated) {
      hasRating = this.checkHasRating(data, auth.user.id);
    }
    if (hasRating) rating = this.getUserRating(data, auth.user.id);
    this.setState({ r_id, average, hasRating, rating, totalRater });
  }
  getRatingAverage = rating => {
    const totalValue =
      rating.member
        .map(m => m.value)
        .reduce((a, b) => {
          return a + b;
        }, 0) +
      rating.visitor
        .map(v => v.value)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    const totalRater = rating.member.length + rating.visitor.length;
    return [
      totalRater,
      totalRater === 0 ? 4.4 : Number((totalValue / totalRater).toFixed(1))
    ];
  };
  getUserRating = (rating, u_id) => {
    const filter = rating.member.filter(m => m.user === u_id)[0];
    return filter.value;
  };
  checkHasRating = (rating, u_id) => {
    return rating.member.map(m => m.user).indexOf(u_id) !== -1;
  };
  handleChange = async rate => {
    const { rating, average, totalRater } = this.state;
    this.setState({
      hasRating: true,
      rating: rate,
      totalRater: totalRater + 1
    });
    const { isAuthenticated, user } = this.props.auth;
    const { a_id } = this.state;
    let data = {};
    data.a_id = a_id;
    data.value = rate;
    data.score = average;
    if (isAuthenticated) {
      data.user = user.id;
    } else {
      data.visitor = "uuidv4";
    }
    try {
      await update_article_rating(this.state.r_id, data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("rating failed");
      }
      this.setState({ hasRating: false, rating });
    }
  };
  render() {
    const { hasRating, average, rating, totalRater } = this.state;
    return (
      <div className="rating-article mx-auto">
        <Rating
          onChange={rate => this.handleChange(rate)}
          readonly={hasRating}
          emptySymbol="far fa-star fa-2x"
          fullSymbol="fas fa-star fa-2x"
          fractions={2}
          initialRating={hasRating ? rating : average}
          placeholderRating={average}
          placeholderSymbol="fas fa-star fa-2x"
        />
        {totalRater === 0 ? (
          ""
        ) : (
          <span className="p-2">
            {average}/{totalRater}
          </span>
        )}
        {hasRating ? <span className="p-2">Your rating: {rating}</span> : ""}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  null
)(RatingArticle);
