import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import http from "../../services/http";
import InfiniteLoading from "../common/InfiniteLoading";
import TopThree from "./TopThree";
import Scroll from "./Scroll";
import "./scroll.scss";

class TopSix extends Component {
  constructor() {
    super();
    this.state = {
      articles: [],
      loading: false
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const { data } = await http.get("/api/articles/topsix");
      if (data.length !== 0) {
        this.setState({ articles: data, loading: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.log("Failed loading articles");
    }
  }

  render() {
    const { articles } = this.state;
    if (articles.length === 0) return <InfiniteLoading heightt={200} />;
    const writers = _.take(_.uniqBy(articles.map(a => a.author), "_id"), 4);
    return (
      <React.Fragment>
        <div className="top-six">
          <div className="article-top-six">
            <h2>
              <FormattedMessage
                id="top.art"
                defaultMessage="Recommend Articles"
              />
            </h2>
            <p className="text-muted">
              <FormattedMessage
                id="top.art-caption"
                defaultMessage="Based on the evaluation in last 30 day"
              />
            </p>
            {articles.length !== 0 && (
              <div className="wrapper" id="wrapper-article">
                {articles.map(article => (
                  <Scroll
                    data={article}
                    key={article._id}
                    pos="wrapper-article"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <TopThree writers={writers} />
      </React.Fragment>
    );
  }
}
export default TopSix;
