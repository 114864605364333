import React, { Component } from "react";
import ProfileInfo from "./ProfileInfo";
import HobbyInfo from "./HobbyInfo";
import LanguageInfo from "./LanguageInfo";
import CoverInfo from "./CoverInfo";
import "./account.scss";

class Setting extends Component {
  render() {
    return (
      <div className="settings">
        <div className="settings-item">
          <ProfileInfo />
        </div>
        <div className="settings-item">
          <HobbyInfo />
        </div>
        <div className="settings-item">
          <LanguageInfo />
        </div>
        <div className="settings-item">
          <CoverInfo />
        </div>
      </div>
    );
  }
}
export default Setting;
