import React, { Component } from "react";
import { connect } from "react-redux";
import { get_comment_rating, update_rating } from "../../services/rating";

class RatingComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      c_id: props.c_id,
      r_id: "",
      hasRating: false,
      vote: "",
      likes: 0,
      dislikes: 0
    };
  }
  async componentDidMount() {
    const { c_id } = this.props;
    const { data } = await get_comment_rating(c_id);
    if (typeof data.success !== "undefined") {
      console.log("Rating ERROR: Rating Comment :21");
      return;
    }
    const r_id = data._id;
    const hasRating = this.hasRating(data);
    const vote = this.checkVoting(data);
    const likes = this.ticketLikes(data);
    const dislikes = this.ticketDislikes(data);
    this.setState({ hasRating, vote, likes, dislikes, r_id });
  }
  hasRating = rating => {
    if (typeof rating.success !== "undefined") return false;
    const { isAuthenticated, user } = this.props.auth;
    if (
      isAuthenticated &&
      (rating.positive.indexOf(user.id) !== -1 ||
        rating.negative.indexOf(user.id) !== -1)
    ) {
      return true;
    }
    return false;
  };
  checkVoting = rating => {
    if (!rating.success) return rating.error;
    const { isAuthenticated, user } = this.props.auth;
    if (isAuthenticated && rating.positive.indexOf(user.id) !== -1) {
      return "like";
    } else if (isAuthenticated && rating.negative.indexOf(user.id) !== -1) {
      return "dislike";
    } else {
      return "unvote";
    }
  };
  ticketLikes = rating => {
    if (typeof rating.success !== "undefined") return 0;
    return rating.positive.length + rating.pos.length;
  };
  ticketDislikes = rating => {
    if (typeof rating.success !== "undefined") return 0;
    return rating.negative.length + rating.neg.length;
  };
  handleLike = async r_id => {
    const originState = { ...this.state };
    this.setState({
      hasRating: true,
      vote: "like",
      likes: this.state.likes + 1
    });
    const { c_id } = this.state;
    let rat = {};
    rat.ticket = "like";
    rat.c_id = c_id;
    const { isAuthenticated, user } = this.props.auth;
    if (isAuthenticated) {
      rat.user = user.id;
    } else {
      rat.visitor = "uuid4";
    }
    try {
      await update_rating(r_id, rat);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Failed like");
      }
      this.setState({ ...originState });
    }
  };
  handleDislike = async r_id => {
    const originState = { ...this.state };
    this.setState({
      hasRating: true,
      vote: "dislike",
      dislikes: this.state.dislikes + 1
    });
    const { c_id } = this.state;
    let rat = {};
    rat.c_id = c_id;
    rat.ticket = "dislike";
    const { isAuthenticated, user } = this.props.auth;
    if (isAuthenticated) {
      rat.user = user.id;
    } else {
      rat.visitor = "uuid4";
    }
    try {
      await update_rating(r_id, rat);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Failed dislike");
      }
      this.setState({ ...originState });
    }
  };
  render() {
    const { hasRating, vote, likes, dislikes, r_id } = this.state;
    return (
      <div className="rating">
        <button
          className="rating-btn btn"
          type="button"
          disabled={hasRating ? "disabled" : ""}
          onClick={() => this.handleLike(r_id)}
        >
          <i
            className={
              hasRating && vote === "like"
                ? "fas fa-thumbs-up"
                : "far fa-thumbs-up"
            }
          />
        </button>
        <span className="pl-2 pr-3">{likes}</span>
        <button
          className="rating-btn btn"
          type="button"
          disabled={hasRating ? "disabled" : ""}
          onClick={() => this.handleDislike(r_id)}
        >
          <i
            className={
              hasRating && vote === "dislike"
                ? "fas fa-thumbs-down"
                : "far fa-thumbs-down"
            }
          />
        </button>
        <span className="pl-2">{dislikes}</span>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  null
)(RatingComment);
