import React, { Component } from "react";
import PropTypes from "prop-types";
import "./form.css";

class TextModernField extends Component {
  constructor() {
    super();
    this.state = {
      focus: false,
      show: false
    };
    this.onFocus = this.onFocus.bind(this);
    this.handleEye = this.handleEye.bind(this);
  }
  handleEye() {
    this.setState({ show: !this.state.show });
  }
  getIcon = name => {
    switch (name) {
      case "password":
        if (this.state.show) {
          return (
            <span className="icon" onClick={this.handleEye}>
              <i className="fas fa-eye" />
            </span>
          );
        } else {
          return (
            <span className="icon" onClick={this.handleEye}>
              <i className="fas fa-eye-slash" />
            </span>
          );
        }
      default:
        return null;
    }
  };
  onFocus() {
    this.setState({ focus: !this.state.focus });
  }
  render() {
    const {
      name,
      placeholder,
      value,
      info,
      type,
      onChange,
      disabled,
      error
    } = this.props;
    const { show, focus } = this.state;
    return (
      <div
        className={`form-modern-group ${error ? "is-invalid" : ""}`}
        data-error={error}
      >
        {this.getIcon(name)}
        <input
          type={show ? "text" : type}
          className={
            focus || value !== "" ? "form-modern hasVal" : "form-modern"
          }
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onFocus}
          disabled={disabled}
        />
        <span className="modern-title" data-title={placeholder} />
        {info && <small className="modern-info">{info}</small>}
      </div>
    );
  }
}
TextModernField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string
};
TextModernField.defaultProps = {
  type: "text"
};
export default TextModernField;
