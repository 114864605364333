import React, { Component } from "react";
import _ from "lodash";
import "./table.scss";

class TableModen extends Component {
  raiseSort = path => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "desc" ? "asc" : "desc";
    else {
      sortColumn.path = path;
      sortColumn.order = "desc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = column => {
    const { sortColumn } = this.props;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
    return <i className="fa fa-sort-desc" />;
  };

  renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    return item._id + (column.path || column.key);
  };
  render() {
    const { columns, data } = this.props;
    return (
      <div className="table-moden">
        <ul className="responsive-table">
          <li className="table-header">
            {this.props.columns.map((column, i) => (
              <div
                className={`coll coll-${i}`}
                key={column.path || column.key}
                onClick={() => this.raiseSort(column.path)}
              >
                {column.label} {this.renderSortIcon(column)}
              </div>
            ))}
            <div className="circles">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </li>
          {data.map(item => (
            <li key={item._id} className="table-row">
              {columns.map((column, i) => (
                <div
                  key={this.createKey(item, column)}
                  className={`coll coll-${i}`}
                  data-label={column.label}
                >
                  {this.renderCell(item, column)}
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default TableModen;
