import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import Joi from "joi-browser";
import toastr from "toastr";
import { registerUser } from "../../actions/authActions";
import { PROVIDER } from "../../assets/utils/unit";
import { socket } from "../../io/routine";
import Oauth from "./oauth";
import TextModernField from "../common/TextModern";
import CheckBoxModern from "../common/CheckBoxModern";
import loader from "../../assets/img/loading.gif";
import "./auth.scss";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      agree: false,
      submit: false,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, submit: false });
    }
  }
  schema = {
    name: Joi.string()
      .required()
      .trim()
      .min(2)
      .max(40)
      .label("Name"),
    email: Joi.string()
      .required()
      .trim()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .trim()
      .min(6)
      .max(40)
      .label("Password"),
    password2: Joi.string()
      .required()
      .trim()
      .min(6)
      .max(40)
      .label("Confirm")
  };
  validate = () => {
    const options = { abortEarly: false };
    const { name, email, password, password2, agree } = this.state;
    let user = {};
    user.name = name;
    user.email = email;
    user.password = password;
    user.password2 = password2;
    const { error } = Joi.validate(user, this.schema, options);
    if (!error && agree) return null;

    const errors = {};
    if (!agree)
      errors.agree = this.props.intl.formatMessage({
        id: "register.error-agree"
      });
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }
    this.setState({ [input.name]: input.value, errors });
  };
  handleAgree = () => {
    this.setState({ agree: !this.state.agree, errors: {} });
  };
  handleSubmit = e => {
    e.preventDefault();
    const { agree } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors || !agree) return;
    this.doSubmit();
  };
  doSubmit = async () => {
    this.setState({ submit: true });
    const { name, email, password, password2 } = this.state;
    let user = {};
    user.name = name;
    user.email = email;
    user.password = password;
    user.password2 = password2;
    try {
      await this.props.registerUser(user, this.props.history);
    } catch (ex) {
      toastr.warning(this.props.intl.formatMessage({ id: "register.error" }));
    }
  };
  render() {
    const { errors, submit, agree } = this.state;
    const { intl } = this.props;
    const namePlaceholder = intl.formatMessage({
      id: "register.name-placeholder"
    });
    const emailPlaceholder = intl.formatMessage({
      id: "register.email-placeholder"
    });
    const emailInfo = intl.formatMessage({
      id: "register.email-info"
    });
    const passPlaceholder = intl.formatMessage({
      id: "register.password-placeholder"
    });
    const pass2Placeholder = intl.formatMessage({
      id: "register.password2-placeholder"
    });
    const content = (
      <span>
        <FormattedMessage id="register.agree" defaultMessage="I agree with " />
        <Link to="/about/terms">
          <FormattedMessage
            id="register.agree-link"
            defaultMessage="Terms of Service"
          />
        </Link>
      </span>
    );
    return (
      <div className="auth">
        <div className="auth-wrapper">
          <div className="social-auth">
            {PROVIDER.map(provider => (
              <Oauth provider={provider} key={provider} socket={socket} />
            ))}
          </div>
          <div className="customer-auth">
            <h2 className="auth-header">
              <FormattedMessage
                id="register.register"
                defaultMessage="Registration"
              />
            </h2>
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
              <TextModernField
                name="name"
                placeholder={namePlaceholder}
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
                error={errors.name}
              />
              <TextModernField
                name="email"
                placeholder={emailPlaceholder}
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
                error={errors.email}
                info={emailInfo}
              />
              <TextModernField
                name="password"
                placeholder={passPlaceholder}
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
                error={errors.password}
              />
              <TextModernField
                name="password2"
                placeholder={pass2Placeholder}
                type="password"
                value={this.state.password2}
                onChange={this.handleChange}
                error={errors.password2}
              />
              <CheckBoxModern
                onAgree={this.handleAgree}
                checked={agree}
                content={content}
                error={errors.agree}
              />
              <button type="submit" className="btn btn-ctl btn-block">
                {submit ? (
                  <img className="refresh" src={loader} alt="refresh" />
                ) : (
                  <span className="submit">
                    <FormattedMessage
                      id="register.submit-btn"
                      defaultMessage="Submit"
                    />
                  </span>
                )}
              </button>
              <div className="miscellaneous">
                <Link to="/login" className="redirect-btn">
                  <FormattedMessage
                    id="register.login"
                    defaultMessage="Login"
                  />
                  <i className="fa fa-arrow-right ml-1" />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  intl: intlShape.isRequired,
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.any
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(injectIntl(Register)));
