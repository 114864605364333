import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { emailVerify } from "../../actions/authActions";
import InfiniteLoading from "../common/InfiniteLoading";
import toastr from "toastr";
import "./auth.scss";

class VerificationEmail extends Component {
  constructor() {
    super();
    this.state = {
      verifying: true,
      errors: {},
      verified: false
    };
  }
  componentDidMount() {
    const { jwt } = this.props.match.params;
    const token = "Bearer " + jwt;
    try {
      const info = jwt_decode(token);
      if (typeof info.exp === "undefined" || info.exp < Date.now() / 1000) {
        const error = this.props.intl.formatMessage({
          id: "verify.error-link"
        });
        toastr.warning(error);
        this.setState({
          verifying: false,
          errors: { error }
        });
      } else {
        this.props.emailVerify(info);
      }
    } catch (ex) {
      const er = this.props.intl.formatMessage({ id: "verify.error-invalid" });
      toastr.warning(er);
      this.setState({
        verifying: false,
        errors: { error: er }
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.setState({ verifying: false, verified: true });
    }
    if (nextProps.errors !== this.props.errors) {
      toastr.warning(nextProps.errors.error);
      this.setState({ errors: nextProps.errors, verifying: false });
    }
  }
  render() {
    const { verifying, verified, errors } = this.state;
    const { intl } = this.props;
    if (verifying) {
      return (
        <InfiniteLoading
          content={intl.formatMessage({ id: "verify.loading" })}
        />
      );
    } else if (!verifying && verified) {
      return (
        <div className="verification">
          <div className="verify-content">
            <h2>
              <FormattedMessage
                id="verify.email-success"
                defaultMessage="Account verify Success"
              />
            </h2>
            <Link to="/dashboard" className="btn btn-ctl m-3">
              <FormattedMessage
                id="verify.dashboard"
                defaultMessage="Dashboard"
              />
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="verification">
          <div className="verify-content">
            <h2>
              <FormattedMessage id="verify.error" defaultMessage="Error: " />
              {typeof errors.error !== "undefined" && errors.error}
            </h2>
          </div>
        </div>
      );
    }
  }
}
VerificationEmail.propTypes = {
  intl: intlShape.isRequired,
  emailVerify: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.any
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { emailVerify }
)(injectIntl(VerificationEmail));
