import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import toastr from "toastr";
import messages from "./lang";

import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./assets/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { seoApp, toastrOptions } from "./assets/utils/unit";

import SEO from "./components/common/SEO";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import NotFound from "./components/common/NotFound";
import Register from "./components/auth/register";
import Login from "./components/auth/login";
import Dashboard from "./components/dashboard/Dashboard";
import Article from "./components/article/Article";
import Articles from "./components/article/Articles";
import Music from "./components/music/Music";
import Gallery from "./components/gallery/Gallery";
import Profile from "./components/profile/Profile";
import About from "./components/about/About";
import Feedback from "./components/about/Feedback";
import Privacy from "./components/policy/Privacy";
import CookiePolicy from "./components/policy/CookiePolicy";
import Terms from "./components/policy/Terms";
import Support from "./components/support/Support";
import PasswordRecover from "./components/auth/passwordrecover";
import VerificationEmail from "./components/auth/verifyEmail";
import VerificationPwd from "./components/auth/verifyPwd";
import RedirectURL from "./components/common/Redirect";

import "./App.scss";
import "../node_modules/toastr/build/toastr.css";

toastr.options = toastrOptions;

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    toastr.info("Session expired, please login");
    window.location.href = "/login";
  }
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      lang: "en"
    };
  }
  componentDidMount() {
    let lang = localStorage.getItem("lang");
    if (lang) {
      this.setState({ lang });
    } else {
      const language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
      lang = language.toLowerCase().split(/[_-]+/)[0];
      localStorage.setItem("lang", lang);
      this.setState({ lang });
    }
  }
  switchLang = lang => {
    this.setState({ lang });
    localStorage.setItem("lang", lang);
  };
  render() {
    const { lang } = this.state;
    return (
      <Provider store={store}>
        <IntlProvider locale={lang} messages={messages[lang] || messages["en"]}>
          <Router>
            <div className="App">
              <Navbar />
              <SEO title="Donacoo" data={seoApp} />
              <div className="main">
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route path="/register" component={Register} />
                  <Route path="/login" component={Login} />
                  <Route path="/profile/:u_id" component={Profile} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/articles/:a_id/:a_title" component={Article} />
                  <Route path="/articles/:a_id" component={Article} />
                  <Route path="/articles" component={Articles} />
                  <Route path="/music" component={Music} />
                  <Route path="/gallery" component={Gallery} />
                  <Route path="/about/privacy" component={Privacy} />
                  <Route path="/about/cookie" component={CookiePolicy} />
                  <Route path="/about/terms" component={Terms} />
                  <Route path="/about" component={About} />
                  <Route path="/feedback" component={Feedback} />
                  <Route path="/support" component={Support} />
                  <Route
                    path="/password-recover/:jwt"
                    component={VerificationPwd}
                  />
                  <Route path="/password-recover" component={PasswordRecover} />
                  <Route
                    path="/email-verify/:jwt"
                    component={VerificationEmail}
                  />
                  <Route path="/redirect/:link" component={RedirectURL} />
                  <Route path="/not-found" component={NotFound} />
                  <Redirect to="/not-found" />
                </Switch>
              </div>
              <Footer switchLang={this.switchLang} lang={lang} />
            </div>
          </Router>
        </IntlProvider>
      </Provider>
    );
  }
}

export default App;
