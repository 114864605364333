import "core-js/es6/map";
import "core-js/es6/set";
import "raf/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/fr";
import "intl/locale-data/jsonp/es";

ReactDOM.render(<App />, document.getElementById("root"));
//ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
