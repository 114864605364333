import React, { Component } from "react";
import "./gallery.css";

class Gallery extends Component {
  render() {
    return (
      <div className="gallery">
        <div className="slogon-box">
          <div className="slogon">
            <h1 className="slogon-words">Gallery</h1>
          </div>
          <div className="caption">
            <p className="caption-words">
              This page reserved for future developing
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
