import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import toastr from "toastr";
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedDate
} from "react-intl";
import { read_message } from "../../../actions/messageActions";
import { getAvatar } from "../../../assets/utils/func";

class MessageSingle extends Component {
  handleReadMessage = async m_id => {
    try {
      await this.props.read_message(m_id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Reading Message failed");
      }
      const msg = this.props.intl.formatMessage({ id: "dmsg.read-fail" });
      toastr.warning(msg);
    }
  };
  getIcon = box => {
    if (box === "received") {
      return <i className="fas fa-envelope-open" />;
    } else if (box === "sent") {
      return <i className="fas fa-envelope" />;
    } else {
      return <i className="fas fa-edit" />;
    }
  };
  render() {
    const { box, m_id } = this.props.match.params;
    const { msgs } = this.props.msg;
    const message = msgs.filter(m => m._id === m_id);
    if (message.length < 1)
      return (
        <p>
          <FormattedMessage
            id="dmsg.notfound"
            defaultMessage="Message {msg} not found"
            values={{ msg: m_id }}
          />
        </p>
      );
    const msg = message[0];
    if (box === "received" && msg.read === false)
      this.handleReadMessage(msg._id);
    const editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(msg.content))
    );
    return (
      <div className="message_single">
        <p className="subject text-muted text-right">
          <span className="pr-2">{this.getIcon(box)}</span>
          {msg.header}
        </p>
        <Editor
          editorState={editorState}
          readOnly={true}
          toolbarHidden={true}
        />
        {box !== "draft" && (
          <div className="signature">
            <div className="sign">
              <img
                src={getAvatar(msg.from.avatar)}
                alt={msg.from.name}
                className="img-circle"
              />
              <span className="sign-name">{msg.from.name}</span>
              <br />
              <span className="sign-date">
                <FormattedDate
                  value={msg.updatedAt}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
MessageSingle.propTypes = {
  intl: intlShape.isRequired,
  read_message: PropTypes.func.isRequired,
  auth: PropTypes.object,
  msg: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  msg: state.msg
});
export default connect(
  mapStateToProps,
  { read_message }
)(injectIntl(MessageSingle));
