import http from "./http";

export const searchPeopleAsync = name => {
  return http.get(`/api/search/people?people=${name}`);
};

export const searchMemberArticle = u_id => {
  return http.get(`/api/search/article/${u_id}`);
};

export const searchCategoryArticle = category => {
  return http.get(`/api/articles/category/${category}`);
};

export const searchAritlceByID = a_id => {
  return http.get(`/api/articles/${a_id}`);
};

export const searchStatics = () => {
  return http.get("/api/search/statics");
};
