import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import InfiniteLoading from "../../common/InfiniteLoading";
import FriendRequest from "./FriendRequest";
import FriendListItem from "./FriendListItem";
import {
  get_friends_list,
  get_requests_list
} from "../../../actions/friendActions";

class Friend extends Component {
  async componentDidMount() {
    try {
      await this.props.get_friends_list();
      await this.props.get_requests_list();
    } catch (ex) {
      const msg = this.props.intl.formatMessage({ id: "app.fetch-fail" });
      toastr.warning(msg);
    }
  }
  showFriendRequest = requests => {
    if (requests.length === 0) return;
    return requests.map(r => <FriendRequest key={r._id} request={r} />);
  };
  showFriendList = friends => {
    if (friends.length === 0) {
      return (
        <div className="jumbotron">
          <h1 className="display-4">
            <FormattedMessage
              id="dfriend.title"
              defaultMessage="You don't have any friends yet, try make some"
            />
          </h1>
          <p className="lead">
            <FormattedMessage
              id="dfriend.lead"
              defaultMessage="Tips & Tricks"
            />
          </p>
          <hr className="my-4" />
          <p className="text-muted">
            <FormattedMessage
              id="dfriend.caption"
              defaultMessage="View the articles, you may make friends with them by visiting their profile and sending a friend request.."
            />
          </p>
        </div>
      );
    }
    return friends.map(friend => (
      <FriendListItem key={friend._id} friend={friend} />
    ));
  };
  render() {
    const { loading, friends, requests } = this.props.friendship;
    const { intl } = this.props;
    const load = intl.formatMessage({ id: "dfriend.loading" });
    if (loading) return <InfiniteLoading content={load} heightt={30} />;
    return (
      <div className="friend">
        <div className="friend-request">
          {requests.length !== 0 && (
            <h3>
              <FormattedMessage
                id="dfriend.request"
                defaultMessage="You have {num} new friend requests"
                values={{ num: requests.length }}
              />
            </h3>
          )}
          <ul className="list-group">{this.showFriendRequest(requests)}</ul>
        </div>
        <div className="friend-list">
          <ul className="list-group">{this.showFriendList(friends)}</ul>
        </div>
      </div>
    );
  }
}
Friend.propTypes = {
  intl: intlShape.isRequired,
  get_friends_list: PropTypes.func.isRequired,
  get_requests_list: PropTypes.func.isRequired,
  friendship: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  friendship: state.friendship,
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { get_friends_list, get_requests_list }
)(injectIntl(Friend));
