import React from "react";
import { FormattedMessage } from "react-intl";
import aboutus from "../../assets/img/aboutus.png";
import "./about.scss";

const About = () => {
  return (
    <div className="about">
      <div className="about-about mx-auto">
        <img src={aboutus} class="img-about" alt="AboutUs" />
        <div className="goals" id="goals">
          <div className="jumbotron">
            <h1 className="display-4">
              <FormattedMessage id="about.goal" defaultMessage="Our goals" />
            </h1>
            <p className="lead">
              <FormattedMessage
                id="about.goal-lead"
                defaultMessage="To present the best mankind spirits in front of the public, a guidance to imbue the souls and transcends them"
              />
            </p>
            <hr className="my-4" />
            <p className="text-muted">
              <FormattedMessage
                id="about.goal-quote"
                defaultMessage="Beauty awakens the soul to act, time flies by, the truth and memory endures"
              />
            </p>
          </div>
        </div>
        <div className="team" id="team">
          <div className="jumbotron">
            <h1 className="display-4">
              <FormattedMessage id="about.team" defaultMessage="Team" />
            </h1>
            <p className="lead">
              <FormattedMessage
                id="about.team-lead"
                defaultMessage="'Talent wins games, but teamwork and intelligence win championships.' --Michael Jordan"
              />
            </p>
            <hr className="my-4" />
            <p className="text-muted">
              <FormattedMessage
                id="about.team-quote"
                defaultMessage="Everyone with the love to your beings, to your family, to this world are welcome to join this community"
              />
            </p>
          </div>
        </div>
        <div className="help" id="help">
          <div className="jumbotron">
            <h1 className="display-4">
              <FormattedMessage id="about.help" defaultMessage="Help Us" />
            </h1>
            <p className="lead">
              <FormattedMessage
                id="about.help-lead"
                defaultMessage="To help us with the translation please send email to support@donacoo.com with your brief information (e.g your native language and the language your specialized in)"
              />
            </p>
            <hr className="my-4" />
            <p className="text-muted">
              <FormattedMessage
                id="about.help-quote"
                defaultMessage="One is limit no matter in time or strength, but together dream can be built"
              />
            </p>
          </div>
        </div>
      </div>
      <div className="space" />
    </div>
  );
};

export default About;
