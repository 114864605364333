import { GET_ERRORS } from "../../actions/types";

export const handleError = (error, dispatch) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data
    });
  } else if (error.request) {
    // The request was made but no response was received
    dispatch({
      type: GET_ERRORS,
      payload: { success: false, request: error.request }
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    dispatch({
      type: GET_ERRORS,
      payload: { success: false, error: error.message, config: error.config }
    });
  }
};
