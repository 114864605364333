import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import moment from "moment";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { remove_friend } from "../../../actions/friendActions";
import { getAvatar, getFlag } from "../../../assets/utils/func";

class FriendListItem extends Component {
  handleRemoveFriend = async f_id => {
    try {
      await this.props.remove_friend(f_id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Remove failed");
      }
      const msg = this.props.intl.formatMessage({
        id: "dfriend.fail-del"
      });
      toastr.warning(msg);
    }
  };
  render() {
    const {
      name,
      avatar,
      _id,
      gender,
      birthday,
      nationality,
      location,
      bio
    } = this.props.friend;
    return (
      <li className="list-group-item friend-item">
        <Link to={`/profile/${_id}`} className="friend-avatar">
          <img className="img-media" src={getAvatar(avatar)} alt={name} />
        </Link>
        <div className="friend-info">
          <h5 className="mb-1">
            {name}
            {birthday && (
              <span className="age-info text-muted ml-2">
                {moment(birthday).toNow(true)}{" "}
              </span>
            )}
            {gender === "Male" ? (
              <span className="gender-info">
                <i className="fas fa-mars male" />
              </span>
            ) : (
              <span className="gender-info">
                <i className="fas fa-venus female" />
              </span>
            )}
            {nationality && (
              <span className="nationality-info mr-2 ml-2">
                <img
                  src={getFlag(nationality)}
                  alt="nation"
                  className="img-flag"
                />
              </span>
            )}
            {location && (
              <span className="age-info text-muted">{location}</span>
            )}
          </h5>
          {bio && <p className="bio text-muted">{bio}</p>}
        </div>
        <div className="ctl">
          <Link to={`/profile/${_id}`} className="btn btn-ctl">
            <FormattedMessage id="dfriend.visit" defaultMessage="Visit" />
          </Link>
          <button
            type="button"
            className="btn btn-ctl"
            onClick={() => this.handleRemoveFriend(_id)}
          >
            <FormattedMessage id="dfriend.unfriend" defaultMessage="Unfriend" />
          </button>
        </div>
      </li>
    );
  }
}
FriendListItem.propTypes = {
  intl: intlShape.isRequired,
  remove_friend: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { remove_friend }
)(injectIntl(FriendListItem));
