import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import toastr from "toastr";
import Logo from "../../assets/img/favicon.png";
import "./footer.css";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      lang: "en"
    };
  }
  componentDidMount() {
    const { lang } = this.props;
    this.setState({ lang });
    this.showCookie();
  }
  showCookie = () => {
    let cookieShow = localStorage.getItem("cs");
    if (!cookieShow) {
      const { intl } = this.props;
      const messageTitle = intl.formatMessage({ id: "app.cookieTitle" });
      const message = intl.formatMessage({ id: "app.cookie" });

      toastr.warning(message, messageTitle, {
        timeOut: 15000,
        onclick: toastr.clear()
      });
      localStorage.setItem("cs", true);
    }
  };
  switchLangFailure = lang => {
    switch (lang) {
      case "en":
        return "Switch language failed";
      case "fr":
        return "Le changement de langue a échoué";
      case "es":
        return "El cambio de idioma falló";
      default:
        return "Switch language failed";
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.props.lang) {
      this.setState({ lang: nextProps.lang });
    }
  }
  handleChange = e => {
    e.preventDefault();
    const { lang } = this.props;
    try {
      this.props.switchLang(e.target.value);
    } catch (ex) {
      toastr.error(this.switchLangFailure(lang));
    }
  };
  render() {
    const { lang } = this.state;
    return (
      <footer className="foot-wrapper">
        <div className="row">
          <div className="col-12 col-md-4 foot-panel">
            <div className="foot-box">
              <div className="box-left">
                <Link to="/">
                  <img src={Logo} alt="donacoo-logo" />
                </Link>
              </div>
              <div className="box-right">
                <div className="box-up select">
                  <select
                    name="selectLang"
                    onChange={e => this.handleChange(e)}
                    value={lang}
                  >
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="es">Español</option>
                  </select>
                </div>
                <div className="box-down">
                  <small className="d-block mb-3 text-muted">
                    &copy;{" "}
                    <FormattedMessage
                      id="foot.copyright"
                      defaultMessage="All rights reserved"
                    />
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-2 foot-panel">
            <h5>
              <FormattedMessage id="foot.features" defaultMessage="Features" />
            </h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link className="text-muted" to="/">
                  <FormattedMessage id="foot.home" defaultMessage="Home" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/articles">
                  <FormattedMessage
                    id="foot.article"
                    defaultMessage="Articles"
                  />
                </Link>
              </li>
              {/*
              <li>
                <Link className="text-muted" to="/music">
                  <FormattedMessage id="foot.music" defaultMessage="Music" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/gallery">
                  <FormattedMessage id="foot.gallery" defaultMessage="Gallery" />
                </Link>
              </li>
              */}
            </ul>
          </div>
          <div className="col-6 col-md-2 foot-panel">
            <h5>
              <FormattedMessage id="foot.access" defaultMessage="Access" />
            </h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link className="text-muted" to="/policy/users?access=editors">
                  <FormattedMessage id="foot.editor" defaultMessage="Editors" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/policy/users?access=premium">
                  <FormattedMessage id="foot.member" defaultMessage="Members" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/policy/users?access=visitors">
                  <FormattedMessage
                    id="foot.visitor"
                    defaultMessage="Visitors"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2 foot-panel">
            <h5>
              <FormattedMessage id="foot.policy" defaultMessage="Policy" />
            </h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link className="text-muted" to="/about/privacy">
                  <FormattedMessage
                    id="foot.privacy"
                    defaultMessage="Privacy"
                  />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/about/cookie">
                  <FormattedMessage id="foot.cookie" defaultMessage="Cookie" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/about/terms">
                  <FormattedMessage id="foot.term" defaultMessage="Terms" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/support">
                  <FormattedMessage
                    id="foot.support"
                    defaultMessage="Support"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2 foot-panel">
            <h5>
              <FormattedMessage id="foot.about" defaultMessage="About Us" />
            </h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link className="text-muted" to="/about#goals">
                  <FormattedMessage id="foot.goal" defaultMessage="Our goals" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/about#team">
                  <FormattedMessage id="foot.team" defaultMessage="Team" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/about#help">
                  <FormattedMessage id="foot.help" defaultMessage="Help Us" />
                </Link>
              </li>
              <li>
                <Link className="text-muted" to="/feedback">
                  <FormattedMessage
                    id="foot.feedback"
                    defaultMessage="Feedback"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
Footer.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(Footer);
