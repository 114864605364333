import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import DropDown from "../common/DropDown";
import "./SideNav.css";

class SideNav extends Component {
  articleList = {
    title: {
      tag: "newspaper",
      name: this.props.intl.formatMessage({ id: "sidenav.articles" })
    },
    lists: [
      {
        name: this.props.intl.formatMessage({ id: "sidenav.folder" }),
        tag: "layer-group",
        dest: "/dashboard/articles"
      },
      {
        name: this.props.intl.formatMessage({ id: "sidenav.compose" }),
        tag: "drafting-compass",
        dest: "/dashboard/article-create"
      }
    ]
  };
  accountList = {
    title: {
      tag: "cogs",
      name: this.props.intl.formatMessage({ id: "sidenav.setting" })
    },
    lists: [
      {
        name: this.props.intl.formatMessage({ id: "sidenav.basic" }),
        tag: "cog",
        dest: "/dashboard/settings"
      },
      {
        name: this.props.intl.formatMessage({ id: "sidenav.account" }),
        tag: "toolbox",
        dest: "/dashboard/account/"
      }
    ]
  };
  render() {
    const { isOpen, onOpen } = this.props;
    return (
      <div className="page-sidebar">
        <div className={`menu-btn ${isOpen ? "" : "close"}`} onClick={onOpen}>
          <i className="fas fa-chevron-left ctl-btn" />
        </div>
        <div className={`menu ${isOpen ? "show" : ""}`}>
          <div className="menu-nav">
            <NavLink className="nav-link pl-3 " to="/dashboard">
              <i className="fas fa-home" />{" "}
              <span className={`nav-text pl-3 ${isOpen ? "show" : ""}`}>
                <FormattedMessage
                  id="sidenav.profile"
                  defaultMessage="My Profile"
                />
              </span>
            </NavLink>
          </div>
          <DropDown obj={this.articleList} isOpen={isOpen} />
          <div className="menu-nav">
            <NavLink className="nav-link pl-3 " to="/dashboard/messages">
              <i className="fas fa-envelope-open-text" />{" "}
              <span className={`nav-text pl-3 ${isOpen ? "show" : ""}`}>
                <FormattedMessage
                  id="sidenav.message"
                  defaultMessage="Messages"
                />
              </span>
            </NavLink>
          </div>
          <div className="menu-nav">
            <NavLink className="nav-link pl-3 " to="/dashboard/friends">
              <i className="fas fa-user-friends" />{" "}
              <span className={`nav-text pl-3 ${isOpen ? "show" : ""}`}>
                <FormattedMessage
                  id="sidenav.friend"
                  defaultMessage="Friends"
                />
              </span>
            </NavLink>
          </div>
          <DropDown obj={this.accountList} isOpen={isOpen} />
        </div>
      </div>
    );
  }
}
SideNav.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(SideNav);
