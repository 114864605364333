import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import http from "../../services/http";
import Cover from "../dashboard/Cover";
import Card from "./Card";
import Content from "./Content";
import InfiniteLoading from "../common/InfiniteLoading";
import { isEmpty } from "../../assets/utils/func";
import "./Profile.scss";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
      activities: [],
      friends: [],
      errors: {}
    };
  }
  componentDidMount() {
    const { u_id } = this.props.match.params;
    this.getUserInfo(u_id);
  }
  componentWillReceiveProps = async nextProps => {
    if (nextProps.match.params.u_id !== this.props.match.params) {
      const { u_id } = nextProps.match.params;
      this.getUserInfo(u_id);
    }
  };
  getUserInfo = async u_id => {
    let errors = {};
    const userResponse = await http.get(`/api/users/${u_id}`);
    const friendResponse = await http.get(`/api/friendships/${u_id}`);
    const activityResponse = await http.get(`/api/activity/full/${u_id}`);
    if (
      !userResponse.data.success &&
      !friendResponse.data.success &&
      !activityResponse.data.success
    ) {
      this.setState({
        user: userResponse.data,
        friends: friendResponse.data,
        activities: activityResponse.data
      });
    } else {
      errors.user = userResponse.data.error;
      errors.friends = friendResponse.data.error;
      errors.activities = activityResponse.data.error;
      this.setState({ errors });
    }
  };
  render() {
    const { user, activities, friends, errors } = this.state;
    if (!isEmpty(errors) || isEmpty(user) || isEmpty(activities)) {
      return (
        <InfiniteLoading
          heightt={300}
          content={this.props.intl.formatMessage({ id: "profile.loading" })}
        />
      );
    }
    return (
      <div className="profile">
        <Cover user={user} />
        <div className="profile-body mx-auto">
          <div className="row">
            <div className="col-md-4 col-sm-5 col-xs-12">
              <Card user={user} friends={friends} />
            </div>
            <div className="col-md-8 col-sm-7 col-xs-12">
              <Content activities={activities} user={user} />
            </div>
          </div>
        </div>
        <div className="space" />
      </div>
    );
  }
}
Profile.protoTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(Profile);
