import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Route, Switch } from "react-router-dom";
import toastr from "toastr";
import { injectIntl, intlShape } from "react-intl";
import ArticleFolder from "./ArticleFolder";
import ArticleSlash from "./ArticleSlash";
import InfiniteLoading from "../../common/InfiniteLoading";
import { get_personal_articles } from "../../../actions/articleActions";
import "./Article.scss";

class ArticleUser extends Component {
  async componentDidMount() {
    try {
      await this.props.get_personal_articles();
    } catch (ex) {
      const msg = this.props.intl.formatMessage({ id: "dash.fail" });
      toastr.warning(msg);
    }
  }
  render() {
    const { match } = this.props;
    const { arts, loading } = this.props.arts;
    const loadmsg = this.props.intl.formatMessage({ id: "dart.loading" });
    if (loading) return <InfiniteLoading content={loadmsg} />;
    return (
      <Switch>
        <Route
          path={`${match.path}/:folder`}
          render={props => <ArticleFolder {...props} arts={arts} />}
        />
        <Route
          exact
          path={`${match.path}/`}
          render={props => <ArticleSlash {...props} arts={arts} />}
        />
      </Switch>
    );
  }
}
ArticleUser.propTypes = {
  intl: intlShape.isRequired,
  get_personal_articles: PropTypes.func.isRequired,
  arts: PropTypes.object.isRequired,
  auth: PropTypes.object
};
const mapStateToProps = state => ({
  arts: state.arts,
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { get_personal_articles }
)(injectIntl(ArticleUser));
