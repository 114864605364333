import React, { Component } from "react";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";

import "./NotFound.css";
class NotFound extends Component {
  goBack = e => {
    e.preventDefault();
    this.props.history.goBack();
  };
  render() {
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>
            <FormattedMessage
              id="notf.title"
              defaultMessage="Oops! This Page Could Not Be Found"
            />
          </h2>
          <p>
            <FormattedMessage
              id="notf.description"
              defaultMessage="Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable"
            />
          </p>
          <button type="button" className="back" onClick={e => this.goBack(e)}>
            <FormattedMessage id="notf.btn" defaultMessage="Go back" />
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(NotFound);
