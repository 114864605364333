import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Joi from "joi-browser";
import CheckBoxModern from "../common/CheckBoxModern";
import http from "../../services/http";
import "./about.scss";

class Feedback extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      issue: "",
      details: "",
      agree: false,
      success: false,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }
  schema = {
    email: Joi.string()
      .required()
      .trim()
      .email()
      .label("Email"),
    issue: Joi.string()
      .required()
      .trim()
      .label("Issue"),
    details: Joi.string()
      .required()
      .trim()
      .max(1000)
      .label("Content")
  };
  validate = () => {
    const options = { abortEarly: false };
    const { email, issue, details } = this.state;
    let feedback = {};
    feedback.issue = issue;
    feedback.email = email;
    feedback.details = details;
    const { error } = Joi.validate(feedback, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }
    this.setState({ [input.name]: input.value, errors });
  };
  handleAgree = () => {
    const { errors } = this.state;
    if (errors.agree) delete errors.agree;
    this.setState({ agree: !this.state.agree, errors });
  };
  handleSubmit = e => {
    e.preventDefault();

    const { agree } = this.state;
    const errors = this.validate();
    if (!agree) errors.agree = "error";
    this.setState({ errors: errors || {} });
    if (errors || !this.state.agree) return;
    this.doSubmit();
  };
  doSubmit = async () => {
    const { email, issue, details } = this.state;
    const { isAuthenticated, user } = this.props.auth;
    let feedback = {};
    feedback.email = email;
    feedback.issue = issue;
    feedback.details = details;
    feedback.visitor = navigator.userAgent || "browser";
    if (isAuthenticated) {
      feedback.from = user.id;
    }
    try {
      const res = await http.post("/api/feedback/", feedback);
      if (res.data.success) {
        this.setState({
          success: true,
          email: "",
          issue: "",
          details: "",
          agree: false
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("something went wrong");
      }
    }
  };
  render() {
    const { email, issue, details, agree, success, errors } = this.state;
    const { intl } = this.props;
    const emailPlaceholder = intl.formatMessage({
      id: "feed.email-placeholder"
    });
    const selectIssue = intl.formatMessage({
      id: "feed.issue-opt-0"
    });
    const selectPolicy = intl.formatMessage({
      id: "feed.issue-opt-policy"
    });
    const selectCopyright = intl.formatMessage({
      id: "feed.issue-opt-copyright"
    });
    const selectPayment = intl.formatMessage({
      id: "feed.issue-opt-payment"
    });
    const selectAdvertise = intl.formatMessage({
      id: "feed.issue-opt-ad"
    });
    const selectSuggestion = intl.formatMessage({
      id: "feed.issue-opt-suggestion"
    });
    const selectBug = intl.formatMessage({
      id: "feed.issue-opt-bug"
    });
    const content =
      intl.formatMessage({ id: "feed.agree" }) +
      intl.formatMessage({ id: "feed.agree-link" });
    return (
      <div className="feedback">
        <div className="feedback-feedback mx-auto">
          <div className="jumbotron">
            <h1 className="display-4">
              <FormattedMessage id="feed.feedback" defaultMessage="Feedback" />
            </h1>
            <p className="lead">
              <FormattedMessage
                id="feed.lead"
                defaultMessage="We've listened many of advices, suggestions, it really encourages us and we will continue to do so."
              />
            </p>
            <hr className="my-4" />
            <p className="text-muted">
              <FormattedMessage
                id="feed.quote"
                defaultMessage="Please include detailed information such as your company, title, etc. if the feedback is related to the Advertisement, Copyright. The page URL, precise time for the Bug report."
              />
            </p>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="feedback-email">
                <FormattedMessage
                  id="feed.email"
                  defaultMessage="Your email address"
                />
              </label>
              <input
                type="email"
                name="email"
                className={
                  errors.email ? "form-control is-invalid" : "form-control"
                }
                id="feedback-email"
                aria-describedby="emailHelp"
                placeholder={emailPlaceholder}
                value={email}
                onChange={this.handleChange}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="feedback-issue">
                <FormattedMessage
                  id="feed.issue"
                  defaultMessage="Relative Issue"
                />
              </label>
              <select
                name="issue"
                id="feedback-issue"
                className={
                  errors.issue ? "form-control is-invalid" : "form-control"
                }
                onChange={this.handleChange}
                value={issue}
              >
                <option value="0">{selectIssue}</option>
                <option value="Policy">{selectPolicy}</option>
                <option value="Copyright">{selectCopyright}</option>
                <option value="Payment">{selectPayment}</option>
                <option value="Advertisement">{selectAdvertise}</option>
                <option value="Suggestions">{selectSuggestion}</option>
                <option value="Bug">{selectBug}</option>
              </select>
              {errors.issue && (
                <div className="invalid-feedback">{errors.issue}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="feedback-details">
                <FormattedMessage
                  id="feed.describe"
                  defaultMessage="Please describe the details below"
                />
              </label>
              <textarea
                name="details"
                id="feedback-details"
                cols="30"
                rows="10"
                className={
                  errors.details ? "form-control is-invalid" : "form-control"
                }
                onChange={this.handleChange}
                value={details}
              />
              {errors.details && (
                <div className="invalid-feedback">{errors.details}</div>
              )}
            </div>
            <CheckBoxModern
              onAgree={this.handleAgree}
              checked={agree}
              name="agree"
              error={errors.agree}
              content={content}
            />
            <button type="submit" className="btn btn-ctl">
              {success ? (
                "Sending Success, Thanks for your feedback"
              ) : (
                <FormattedMessage id="feed.send-btn" defaultMessage="Send" />
              )}
            </button>
          </form>
        </div>
        <div className="space" />
      </div>
    );
  }
}
Feedback.propTypes = {
  intl: intlShape.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  {}
)(injectIntl(Feedback));
