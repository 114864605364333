import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import toastr from "toastr";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { add_hobbies, remove_hobbies } from "../../../actions/userActions";
import TextField from "../../common/TextField";

class HobbyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hobbies: "",
      hobby: [],
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const { user } = this.props.auth;
    this.setState({ hobby: user.hobbies || [] });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.auth !== this.props.auth) {
      this.setState({ hobby: nextProps.auth.user.hobbies || [] });
    }
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSubmit(e) {
    e.preventDefault();
    const { hobbies, errors } = this.state;
    if (hobbies.trim().length === 0) {
      errors.hobbies = this.props.intl.formatMessage({
        id: "dset.hobby-invalid"
      });
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: {}, hobbies: "" });
      this.doAddHobbies({ hobbies: hobbies });
    }
  }
  doAddHobbies = async data => {
    try {
      await this.props.add_hobbies(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Add hobbies failed");
      const msg = this.props.intl.formatMessage({ id: "dset.hobby-add-fail" });
      toastr.warning(msg);
    }
  };
  doRemoveHobbies = async data => {
    try {
      await this.props.remove_hobbies(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Delete hobbies failed");
      const msgg = this.props.intl.formatMessage({ id: "dset.hobby-del-fail" });
      toastr.warning(msgg);
    }
  };
  render() {
    const { hobbies, hobby, errors } = this.state;
    const hobbyPlaceholder = this.props.intl.formatMessage({
      id: "dset.hobby-placeholder"
    });
    const hobbyInfo = this.props.intl.formatMessage({ id: "dset.hobby-info" });
    const hobbyBtn = this.props.intl.formatMessage({ id: "dset.hobby-btn" });
    return (
      <div className="profile-hobbies">
        <h4>
          <FormattedMessage
            id="dset.hobby-title"
            defaultMessage="Hobbies setting"
          />
        </h4>
        <div className="hobby-fields">
          {hobby.length !== 0 &&
            hobby.map(h => (
              <span className="hobby-item" key={h}>
                {h}
                <i
                  className="fas fa-times"
                  onClick={() => this.doRemoveHobbies(h)}
                />
              </span>
            ))}
        </div>
        <form onSubmit={this.handleSubmit}>
          <TextField
            name="hobbies"
            onChange={this.handleChange}
            placeholder={hobbyPlaceholder}
            value={hobbies}
            info={hobbyInfo}
            error={errors.hobbies}
          />
          <input
            type="submit"
            value={hobbyBtn}
            className="btn btn-outline-secondary btn-set"
          />
        </form>
      </div>
    );
  }
}
HobbyInfo.propTypes = {
  intl: intlShape.isRequired,
  add_hobbies: PropTypes.func.isRequired,
  remove_hobbies: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { add_hobbies, remove_hobbies }
)(injectIntl(HobbyInfo));
