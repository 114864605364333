import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getAvatar, getFlag } from "../../assets/utils/func";

class TopThree extends Component {
  render() {
    const { writers } = this.props;
    return (
      <div className="top-three container">
        <div className="top-three-header">
          <h2>
            <FormattedMessage
              id="top.writer"
              defaultMessage="Meet our writer"
            />
          </h2>
          <p className="text-muted">
            <FormattedMessage
              id="top.writer-caption"
              defaultMessage="Quality, quantity, popularity, comments"
            />
          </p>
        </div>
        <div className="row">
          {writers.map((w, i) => (
            <div className="col col-sm-6 col-md-3" key={w._id}>
              <div className={`top-three-item top-${i + 1}`}>
                <div className="inner-wrapper">
                  <Link to={`/profile/${w._id}`} className="best-writer-item">
                    <img
                      src={getAvatar(w.avatar)}
                      alt={w.name}
                      className="writer-avatar"
                    />
                  </Link>
                  <p className="text-muted loc-info">
                    {w.location && (
                      <React.Fragment>
                        <i className="fas fa-map" />
                        <span className="location ml-1 mr-1">{w.location}</span>
                      </React.Fragment>
                    )}
                    {w.nationality && (
                      <span>
                        <img
                          src={getFlag(w.nationality)}
                          alt={w.nationality}
                          className="nationality"
                        />
                      </span>
                    )}
                  </p>
                </div>
                <h5 className="text-writer">{w.name}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default TopThree;
