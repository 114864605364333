import http from "../services/http";
import {
  ARTICLES_LOADING,
  SET_ARTICLE_PRIVATE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  SET_ARTICLE_VISITOR,
  ARTICLE_LOADING
} from "./types";
import { handleError } from "../assets/utils/handleError";

export const create_article = (data, history) => dispatch => {
  http
    .post("/api/articles/", data)
    .then(res => {
      dispatch({
        type: CREATE_ARTICLE,
        payload: res.data
      });
      history.push("/dashboard/articles");
    })
    .catch(err => handleError(err, dispatch));
};

export const get_personal_articles = () => dispatch => {
  dispatch({
    type: ARTICLES_LOADING
  });
  http
    .get("/api/articles/")
    .then(res => dispatch({ type: SET_ARTICLE_PRIVATE, payload: res.data }))
    .catch(err => handleError(err, dispatch));
};

export const update_article = (a_id, data, history) => dispatch => {
  http
    .put(`/api/articles/${a_id}`, data)
    .then(res => {
      dispatch({
        type: UPDATE_ARTICLE,
        payload: res.data
      });
      history.push("/dashboard/articles");
    })
    .catch(err => handleError(err, dispatch));
};
export const delete_article = a_id => dispatch => {
  http
    .delete(`/api/articles/${a_id}`)
    .then(res =>
      dispatch({
        type: DELETE_ARTICLE,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const get_public_article = a_id => dispatch => {
  dispatch({
    type: ARTICLE_LOADING
  });
  http
    .get(`/api/articles/${a_id}`)
    .then(res =>
      dispatch({
        type: SET_ARTICLE_VISITOR,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
