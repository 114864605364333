import React, { Component } from "react";
import "./music.css";

class Music extends Component {
  render() {
    return (
      <div className="music">
        <div className="slogon-box">
          <div className="slogon">
            <h1 className="slogon-words">Music</h1>
          </div>
          <div className="caption">
            <p className="caption-words">
              This page reserved for future developing
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Music;
