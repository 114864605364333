export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOAD_CURRENT_USER = "LOAD_CURRENT_USER";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const UPDATE_COVER = "UPDATE_COVER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const ADD_HOBBIES = "ADD_HOBBIES";
export const REMOVE_HOBBIES = "REMOVE_HOBBIES";
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const REMOVE_LANGUAGE = "REMOVE_LANGUAGE";

export const ARTICLES_LOADING = "ARTICLES_LOADING";
export const SET_ARTICLE_PRIVATE = "SET_ARTICLE_PRIVATE";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";

export const ARTICLE_LOADING = "ARTICLE_LOADING";
export const SET_ARTICLE_VISITOR = "SET_ARTICLE_VISITOR";

export const ARTICLE_COMMENTS_LOADING = "ARTICLE_COMMENTS_LOADING";
export const ARTICLE_SET_COMMENTS = "ARTICLE_SET_COMMENTS";
export const ARTICLE_CREATE_COMMENTS = "ARTICLE_CREATE_COMMENTS";
export const ARTICLE_UPDATE_COMMENTS = "ARTICLE_UPDATE_COMMENTS";
export const ARTICLE_DELETE_COMMENTS = "ARTICLE_DELETE_COMMENTS";

export const MESSAGES_LOADING = "MESSAGE_LOADING";
export const SET_MESSAGES = "SET_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const READ_MESSAGE = "READ_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const FRIENDS_LOADING = "FRIENDS_LOADING";
export const SET_FRIENDS = "SET_FRIENDS";
export const SET_FRIEND_REQUEST = "SET_FRIEND_REQUEST";
export const MAKE_NEW_FRIEND = "MAKE_NEW_FRIEND";
export const ANSWER_FRIEND_REQUEST = "ANSWER_FRIEND_REQUEST";
export const REMOVE_FRIEND = "REMOVE_FRIEND";

export const ACTIVITY_LOADING = "ACTIVITY_LOADING";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
