import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import TextIcon from "../../common/TextIcon";

class SocialInputs extends Component {
  render() {
    const {
      displaySocialInputs,
      facebook,
      twitter,
      youtube,
      instagram,
      linkedin,
      onChange,
      onDisplay,
      errors
    } = this.props;
    return (
      <div className="social">
        <div className="mb-3">
          <button type="button" onClick={onDisplay} className="btn btn-light">
            <FormattedMessage
              id="dset.social"
              defaultMessage="Add Social Network Links"
            />
          </button>
          <span className="text-muted">
            <FormattedMessage id="dset.social-opt" defaultMessage="Optional" />
          </span>
        </div>
        {displaySocialInputs && (
          <React.Fragment>
            <TextIcon
              placeholder="Twitter URL"
              name="twitter"
              icon="fab fa-twitter"
              value={twitter}
              onChange={onChange}
              error={errors.twitter}
            />
            <TextIcon
              placeholder="Facebook URL"
              name="facebook"
              icon="fab fa-facebook"
              value={facebook}
              onChange={onChange}
              error={errors.facebook}
            />
            <TextIcon
              placeholder="LinkedIn URL"
              name="linkedin"
              icon="fab fa-linkedin"
              value={linkedin}
              onChange={onChange}
              error={errors.linkedin}
            />
            <TextIcon
              placeholder="Youtube URL"
              name="youtube"
              icon="fab fa-youtube"
              value={youtube}
              onChange={onChange}
              error={errors.youtube}
            />
            <TextIcon
              placeholder="Instagram URL"
              name="instagram"
              icon="fab fa-instagram"
              value={instagram}
              onChange={onChange}
              error={errors.instagram}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default SocialInputs;
