import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getArticleCover,
  getRealAuthor,
  trunc18,
  trunc20,
  toDashedLink
} from "../../assets/utils/func";
import shield from "../../assets/img/shield-sm.png";
import "./scroll.scss";

class Scroll extends Component {
  constructor() {
    super();
    this.trackScrolling = this.trackScrolling.bind(this);
  }

  componentDidMount() {
    const scroller = document.getElementById(this.props.pos);
    scroller.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    const scroller = document.getElementById(this.props.pos);
    scroller.removeEventListener("scroll", this.trackScrolling);
  }
  trackScrolling = () => {
    const { data, pos } = this.props;
    const item = document.getElementById(data._id);
    const wrapper = document.getElementById(pos);
    this.isInWrapper(item, wrapper)
      ? item.classList.remove("scale")
      : item.classList.add("scale");
  };
  isInWrapper(itm, wrapper) {
    const wrap = wrapper.getBoundingClientRect();
    const item = itm.getBoundingClientRect();
    const case1 = item.x - wrap.x >= 40 && wrap.right - item.right >= 40;
    const case2 = item.left - wrap.left >= 40 && wrap.right - item.right >= 40;
    return case1 || case2;
  }
  getTitleLink(article) {
    if (article.original) {
      return toDashedLink(article.title, article.author.name);
    } else {
      return toDashedLink(article.title, article.origin.author);
    }
  }
  render() {
    const { data: d } = this.props;
    return (
      <div className="scroll-item" id={d._id} ref={this.scroller}>
        <div className="scroll-link">
          <div className="scroll-header">
            <Link to={`/articles/${d._id}/${this.getTitleLink(d)}`}>
              <img
                src={getArticleCover(d.category)}
                className="scroll-cover"
                alt={d.title}
              />
            </Link>
            <span className="scroll-score">{d.score}</span>
          </div>
          <div className="scroll-info">
            <p className="text-title">{trunc20(d.title)}</p>
            <p className="text-author">
              {trunc18(getRealAuthor(d.original, d.origin, d.author.name))}
            </p>
            {Boolean(d.original) ? (
              <img
                src={shield}
                alt={`copyright-${d.author.name}`}
                className="corner"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Scroll;
