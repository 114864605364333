import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { thirdLogin } from "../../actions/authActions";
import { SERVER_API } from "../../assets/utils/unit";
import sorry from "../../assets/img/page_sorry.png";
import "./auth.scss";

class Oauth extends Component {
  state = {
    fail: false,
    disabled: ""
  };
  componentDidMount() {
    const { socket, provider } = this.props;

    socket.on(provider, token => {
      this.popup.close();
      if (!token) {
        this.setState({ fail: true });
      } else {
        this.props.thirdLogin(token);
      }
    });
  }

  checkPopup() {
    const check = setInterval(() => {
      const { popup } = this;
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(check);
        this.setState({ disabled: "" });
      }
    }, 1000);
  }

  openPopup() {
    const { provider, socket } = this.props;
    const width = 600,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const url = `${SERVER_API}/oauth2/${provider}?socketId=${socket.id}`;

    return window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
  }
  startAuth(e) {
    if (!this.state.disabled) {
      e.preventDefault();
      this.popup = this.openPopup();
      this.checkPopup();
      this.setState({ disabled: "disabled" });
    }
  }
  closeCard() {
    this.setState({ fail: false });
  }

  render() {
    const { provider } = this.props;
    const { disabled, fail } = this.state;

    return (
      <div>
        {fail ? (
          <div className="fail">
            <div className="fail-wrapper">
              <img className="card-img-top" src={sorry} alt="page_error" />

              <span>Page Error</span>
              <i
                className="fa fa-times-circle"
                onClick={this.closeCard.bind(this)}
              />
            </div>
          </div>
        ) : (
          <button
            className={`btn-${provider} ${disabled} btn-social ${provider}`}
            onClick={this.startAuth.bind(this)}
          >
            <i className={`fab fa-${provider} fa-2x`} />
          </button>
        )}
      </div>
    );
  }
}
Oauth.propTypes = {
  auth: PropTypes.object.isRequired,
  thirdLogin: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { thirdLogin }
)(Oauth);
