import io from "socket.io-client";
import { SERVER_API } from "../assets/utils/unit";

export const socket = io.connect(SERVER_API, {
  rejectUnauthorized: false
});

export const reportOnline = user => {
  socket.emit("online", {
    name: user.name,
    _id: user._id || user.id,
    avatar: user.avatar,
    sid: socket.id,
    lang: localStorage.getItem("lang") || "en"
  });
};
