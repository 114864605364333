import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { FormattedMessage, FormattedDate } from "react-intl";
import Table from "../../common/table";
import { getAvatar } from "../../../assets/utils/func";

class MessageTable extends Component {
  columns = [
    { key: "icon", content: message => this.getIcon(message) },
    {
      path: "header",
      label: <FormattedMessage id="dmsg.t-header" defaultMessage="Subject" />,
      content: message => (
        <Link to={`/dashboard/messages/${message.label}/${message._id}`}>
          {_.truncate(message.header)}
        </Link>
      )
    },
    {
      path: "updatedAt",
      label: <FormattedMessage id="dmsg.t-date" defaultMessage="Date" />,
      content: message => (
        <p>
          <FormattedDate
            value={message.updatedAt}
            year="numeric"
            month="short"
            day="2-digit"
          />
        </p>
      )
    },
    {
      key: "delete",
      content: message => (
        <button
          onClick={() => this.props.onDelete(message)}
          className="btn btn-danger btn-sm"
        >
          <i className="fas fa-trash" />
        </button>
      )
    }
  ];
  from = {
    path: "friend",
    lable: <FormattedMessage id="dmsg.t-fr" defaultMessage="Friend" />,
    content: message => (
      <span>
        <Link to={`/profile/${message.from._id}`}>
          <img
            className="img-msg-circle"
            src={getAvatar(message.from.avatar)}
            alt={message.from.name}
          />
          {message.from.name}
        </Link>
      </span>
    )
  };
  to = {
    path: "friend",
    lable: "Friend",
    content: message => (
      <span>
        <Link to={`/profile/${message.to._id}`}>
          <img
            className="img-msg-circle"
            src={getAvatar(message.to.avatar)}
            alt={message.to.name}
          />
          {message.to.name}
        </Link>
      </span>
    )
  };

  getIcon = message => {
    if (message.label === "received") {
      if (message.read) {
        return <i className="fas fa-envelope-open" />;
      }
      return <i className="fas fa-envelope" />;
    }
    if (message.label === "sent") return <i className="fas fa-envelope" />;
    return <i className="fas fa-edit" />;
  };

  render() {
    const { messages, onSort, sortColumn } = this.props;
    let columns = [...this.columns];
    if (messages[0].label === "received") {
      columns.unshift(this.from);
    } else {
      columns.unshift(this.to);
    }
    return (
      <Table
        columns={columns}
        data={messages}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}
export default MessageTable;
