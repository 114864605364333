import React, { Component } from "react";
import { getCover } from "../../assets/utils/func";

class Cover extends Component {
  render() {
    const { user } = this.props;
    const coverStyle = {
      backgroundImage: `url(${getCover(user.cover)})`
    };
    return (
      <div className="dashboard-cover" style={coverStyle}>
        <div className="container">
          <div className="row">
            <div className="col col-sm-8">
              <h1 className="display-4 member">{user.name}</h1>
              {user.handle && <p className="handle">{user.handle}</p>}
            </div>
            <div className="col col-sm-4">
              <div className="member-badge">
                <i className="fab fa-bitcoin fa-3x mr-2" />
                <span className="display-4">10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cover;
