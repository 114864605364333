import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import ActivityDetail from "../dashboard/content/ActivityDetail";

class Content extends Component {
  render() {
    const { activities, user } = this.props;
    return (
      <div className="profile-content">
        <div className="member-activity">
          <h2>
            <FormattedMessage
              id="profile.recent"
              defaultMessage="Recent activities about"
            />
            {user.name}
          </h2>
          <ul className="list-unstyled">
            {activities.map(activity => (
              <ActivityDetail key={activity._id} activity={activity} />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Content;
