import http from "../services/http";
import {
  LOAD_CURRENT_USER,
  UPDATE_AVATAR,
  UPDATE_COVER,
  UPDATE_PROFILE,
  ADD_HOBBIES,
  REMOVE_HOBBIES,
  ADD_LANGUAGE,
  REMOVE_LANGUAGE
} from "./types";
import { logoutUser } from "./authActions";
import { handleError } from "../assets/utils/handleError";

export const loadProfileField = u_id => dispatch => {
  http
    .get(`/api/users/${u_id}`)
    .then(res =>
      dispatch({
        type: LOAD_CURRENT_USER,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const uploadAvatar = img => dispatch => {
  http
    .put("/api/users/avatar", img)
    .then(res =>
      dispatch({
        type: UPDATE_AVATAR,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const updatePassword = data => dispatch => {
  http
    .put("/api/users/password", data)
    .then(res => {
      dispatch(logoutUser());
      window.location.href = "/login";
    })
    .catch(err => handleError(err, dispatch));
};

export const update_cover = data => dispatch => {
  http
    .post("/api/users/profile", data)
    .then(res =>
      dispatch({
        type: UPDATE_COVER,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const update_profile = data => dispatch => {
  http
    .put("/api/users/profile", data)
    .then(res =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const add_hobbies = data => dispatch => {
  http
    .put("/api/users/hobbies", data)
    .then(res =>
      dispatch({
        type: ADD_HOBBIES,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const remove_hobbies = data => dispatch => {
  http
    .delete(`/api/users/hobbies?hobby=${data}`)
    .then(res =>
      dispatch({
        type: REMOVE_HOBBIES,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const add_language = data => dispatch => {
  http
    .put("/api/users/language", data)
    .then(res =>
      dispatch({
        type: ADD_LANGUAGE,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};

export const remove_language = lang_id => dispatch => {
  http
    .delete(`/api/users/language/${lang_id}`)
    .then(res =>
      dispatch({
        type: REMOVE_LANGUAGE,
        payload: res.data
      })
    )
    .catch(err => handleError(err, dispatch));
};
