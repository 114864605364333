import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Choose = () => {
  return (
    <div className="choose">
      <div className="container">
        <div className="row choose-main">
          <div className="col-md-5 choose-left">
            <h2>
              <FormattedMessage
                id="layout.choose"
                defaultMessage="Life is short"
              />
            </h2>
          </div>
          <div className="col-md-7 choose-right">
            <div className="col-md-6 choose-right-top">
              <h4>
                <Link to="/articles/5c7c45ad2708974e09e40d51/of-studies-Francis-Bacon">
                  <FormattedMessage
                    id="layout.lead1"
                    defaultMessage="Reading"
                  />
                </Link>
              </h4>
              <p>
                <FormattedMessage
                  id="layout.read"
                  defaultMessage="Reading is a great way to exercise the brain. Read every day and you will see yourself improving."
                />
              </p>
            </div>
            <div className="col-md-6 choose-right-top">
              <h4>
                <Link to="/articles/5c98a0ba8315d0536f950db7/i-have-a-dream-Martin-Luther-King-Jr">
                  <FormattedMessage
                    id="layout.lead2"
                    defaultMessage="Confluence"
                  />
                </Link>
              </h4>
              <p>
                <FormattedMessage
                  id="layout.confluence"
                  defaultMessage="Among the mountaineous knowledge hill, here we only serve the most essential part."
                />
              </p>
            </div>
            <div className="col-md-6 choose-right-top">
              <h4>
                <Link to="/articles/5c98b0218315d0536f950dba/the-republic-Plato">
                  <FormattedMessage
                    id="layout.lead3"
                    defaultMessage="Communication"
                  />
                </Link>
              </h4>
              <p>
                <FormattedMessage
                  id="layout.communication"
                  defaultMessage="Learn and practice, the golden rule of powering yourself up"
                />
              </p>
            </div>
            <div className="col-md-6 choose-right-top">
              <h4>
                <Link to="/articles/5c989dbc8315d0536f950db5/theory-of-colours-Leonardo-Da-Vinci">
                  <FormattedMessage
                    id="layout.lead4"
                    defaultMessage="Thinking"
                  />
                </Link>
              </h4>
              <p>
                <FormattedMessage
                  id="layout.thinking"
                  defaultMessage="Be yourself, things exist for a reason. And you're the one in the million"
                />
              </p>
            </div>
            <div className="clearfix"> </div>
          </div>
          <div className="clearfix"> </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
