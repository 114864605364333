import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import _ from "lodash";
import toastr from "toastr";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { get_messages, delete_message } from "../../../actions/messageActions";
import { paginate } from "../../../assets/utils/func";
import MessageTable from "./MessageTable";
import Pagination from "../../common/pagination";
import InfiniteLoading from "../../common/InfiniteLoading";

class MessageBox extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: 1,
      pageSize: 10,
      sortColumn: { path: "date", order: "asc" }
    };
  }
  async componentDidMount() {
    try {
      await this.props.get_messages();
    } catch (ex) {
      const msg = this.props.intl.formatMessage({ id: "dmsg.fail" });
      toastr.warning(msg);
    }
  }
  handleDelete = async message => {
    try {
      await this.props.delete_message(message._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        console.log("Deleting Message failed");
      }
      const msgg = this.props.intl.formatMessage({ id: "dmsg.fail-del" });
      toastr.warning(msgg);
    }
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handleSort = sortColumn => {
    this.setState({ sortColumn, currentPage: 1 });
  };
  getPagedData = (data, label) => {
    const { currentPage, pageSize, sortColumn } = this.state;
    const { user } = this.props.auth;
    let messages = [];
    if (label === "received") {
      messages = data.filter(d => d.to._id === user.id);
    } else if (label === "draft") {
      messages = data.filter(d => d.draft === true);
    } else {
      messages = data.filter(d => d.from._id === user.id && d.draft === false);
    }
    messages.forEach((m, i) => {
      messages[i] = { ...m, label };
    });
    const sorted = _.orderBy(messages, [sortColumn.path], [sortColumn.order]);
    const msg_page = paginate(sorted, currentPage, pageSize);
    return { totalCount: messages.length, messages: msg_page };
  };
  render() {
    const { box } = this.props.match.params;
    const { loading, msgs } = this.props.msg;
    const load = this.props.intl.formatMessage({ id: "dmsg.loading" });
    if (loading) return <InfiniteLoading heightt={100} content={load} />;
    const { currentPage, pageSize, sortColumn } = this.state;
    const { totalCount, messages } = this.getPagedData(msgs, box);
    if (totalCount === 0) {
      return (
        <div className="results">
          <p>
            <FormattedMessage
              id="dmsg.no-record"
              defaultMessage="No messages found"
            />
          </p>
        </div>
      );
    }
    return (
      <div className="results">
        <MessageTable
          messages={messages}
          sortColumn={sortColumn}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}
MessageBox.propTypes = {
  intl: intlShape.isRequired,
  get_messages: PropTypes.func.isRequired,
  delete_message: PropTypes.func.isRequired,
  auth: PropTypes.object,
  msg: PropTypes.object.isRequired,
  errors: PropTypes.object
};
const mapStateToProps = state => ({
  auth: state.auth,
  msg: state.msg,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { get_messages, delete_message }
)(injectIntl(MessageBox));
