import React from "react";
import PropTypes from "prop-types";

const TextArea = ({ name, placeholder, value, info, onChange, error }) => {
  return (
    <div className="form-group">
      <textarea
        className={error ? "form-control is-invalid" : "form-control"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
export default TextArea;
