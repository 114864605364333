import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import queryString from "query-string";
import toastr from "toastr";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import TextField from "../../common/TextField";
import SelectList from "../../common/SelectList";
import CheckBoxModern from "../../common/CheckBoxModern";
import {
  create_article,
  get_public_article,
  update_article
} from "../../../actions/articleActions";
import http from "../../../services/http";
import { CATEGORY, LANGUAGES } from "../../../assets/utils/unit";
import {
  isEmpty,
  validateURL,
  getArticleCharLimit
} from "../../../assets/utils/func";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class ArticleCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      title: "",
      category: "Life",
      original: "true",
      author: "",
      link: "",
      seo: [],
      draft: false,
      lang: "en",
      priv: false,
      length: 0,
      limit: 1000,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSEO = this.handleSEO.bind(this);
    this.handleDraft = this.handleDraft.bind(this);
    this.handlePriv = this.handlePriv.bind(this);
  }
  componentDidMount() {
    if (this.props.location.search.indexOf("edit") !== -1) {
      const { edit } = queryString.parse(this.props.location.search);
      this.props.get_public_article(edit);
    }
    if (this.props.auth.isAuthenticated) {
      const { level } = this.props.auth.user;
      const limit = getArticleCharLimit(level);
      this.setState({ limit });
    }
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.art !== this.props.art && !nextProps.art.loading) {
      const {
        title,
        content,
        draft,
        category,
        original,
        origin,
        seo,
        lang,
        priv
      } = nextProps.art.art;
      const editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );
      const length = this.count(editorState);
      if (original !== "true" && typeof origin !== "undefined") {
        this.setState({
          title,
          draft,
          editorState,
          length,
          category,
          seo: seo || [],
          original: original.toString(),
          author: origin.author || "",
          link: origin.link || "",
          lang: lang || "en",
          priv: priv || false,
          errors: {}
        });
      } else {
        this.setState({
          title,
          draft,
          editorState,
          category,
          seo: seo || [],
          original: original.toString(),
          author: "",
          link: "",
          lang: lang || "en",
          priv: priv || false,
          length,
          errors: {}
        });
      }
    }
    if (nextProps.auth.isAuthenticated) {
      const { level } = nextProps.auth.user;
      const limit = getArticleCharLimit(level);
      this.setState({ limit });
    }
  };
  count = editorState => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return raw.blocks
      .map(b => b.text.length)
      .reduce((a, b) => {
        return a + b;
      }, 0);
  };
  validate = () => {
    const { title, length, original, author, link, limit } = this.state;
    let errors = {};
    if (length > limit || length === 0)
      errors.edit = this.props.intl.formatMessage({
        id: "dart.error-length"
      });
    if (title.trim().length < 2 || title.trim().length > 40) {
      errors.title = this.props.intl.formatMessage({ id: "dart.error-title" });
      if (title.trim().length === 0)
        errors.title = this.props.intl.formatMessage({
          id: "dart.title-require"
        });
    }
    if (original !== "true" && isEmpty(author.trim())) {
      errors.author = this.props.intl.formatMessage({
        id: "dart.error-author"
      });
    }
    if (original !== "true" && isEmpty(link.trim())) {
      errors.link = this.props.intl.formatMessage({ id: "dart.error-link" });
    }
    if (
      original !== "true" &&
      !isEmpty(link.trim()) &&
      !validateURL(link.trim())
    ) {
      errors.link = this.props.intl.formatMessage({
        id: "dart.error-invalid-link"
      });
    }
    if (isEmpty(errors)) return null;
    return errors;
  };
  onEditorStateChange = editorState => {
    let length = this.count(editorState);
    this.setState({
      editorState,
      length,
      errors: {}
    });
  };
  handleChange = ({ currentTarget: input }) => {
    const { title, original, author, link, errors } = this.state;
    if (
      input.name === "title" &&
      (title.trim().length < 2 || title.trim().length > 40)
    ) {
      errors.title = this.props.intl.formatMessage({ id: "dart.error-title" });
    } else {
      delete errors.title;
    }
    if (original === "false" && isEmpty(author.trim())) {
      errors.author = this.props.intl.formatMessage({
        id: "dart.error-author"
      });
    } else {
      delete errors.author;
    }
    if (
      original === "false" &&
      (isEmpty(link.trim()) || !validateURL(link.trim()))
    ) {
      errors.link = this.props.intl.formatMessage({
        id: "dart.error-invalid-link"
      });
    } else {
      delete errors.link;
    }
    this.setState({ [input.name]: input.value, errors });
  };
  handleSEO = e => {
    const { errors } = this.state;
    const seoStr = e.target.value;
    const seoArr = seoStr.split(",").map(s => s.trim());
    if (
      seoStr.length !== 0 &&
      (seoArr.filter(s => s.length > 40).length >= 1 || seoArr.length > 40)
    ) {
      errors.seo = this.props.intl.formatMessage({ id: "dart.error-seo" });
    } else {
      delete errors.seo;
    }
    this.setState({ seo: seoArr, errors });
  };
  handleDraft = () => {
    this.setState({ draft: !this.state.draft });
  };
  handlePriv = () => {
    this.setState({ priv: !this.state.priv });
  };

  uploadImageCallBack = file => {
    let formData = new FormData();
    formData.append("article", file);
    return http
      .post("/api/articles/image", formData)
      .then(res => {
        return { data: { link: res.data } };
      })
      .catch(err => {
        const msg = this.props.intl.formatMessage({ id: "dart.error-img" });
        toastr.warning(msg);
      });
  };
  handleSave = e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (!isEmpty(errors)) return;
    const {
      title,
      editorState,
      draft,
      seo,
      category,
      original,
      author,
      link,
      priv,
      lang
    } = this.state;
    let article = {};
    article.title = title;
    article.content = convertToRaw(editorState.getCurrentContent());
    article.draft = draft;
    article.seo = seo;
    article.priv = priv;
    article.lang = lang;
    article.category = category;
    article.original = original;
    if (original !== "true") {
      article.origin = { author, link };
    }
    if (this.props.location.search.indexOf("edit") !== -1) {
      this.doUpdate(article);
    } else {
      this.doSubmit(article);
    }
  };
  doSubmit = async article => {
    try {
      await this.props.create_article(article, this.props.history);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Upload Article failed");
      const warnn = this.props.intl.formatMessage({ id: "dart.error-create" });
      toastr.warning(warnn);
    }
  };
  doUpdate = async article => {
    const { edit } = queryString.parse(this.props.location.search);
    try {
      await this.props.update_article(edit, article, this.props.history);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        console.error("Upload Article failed");
      const warn = this.props.intl.formatMessage({ id: "dart.error-update" });
      toastr.warning(warn);
    }
  };
  render() {
    const {
      editorState,
      errors,
      draft,
      title,
      length,
      category,
      original,
      author,
      link,
      limit,
      seo,
      lang,
      priv
    } = this.state;
    const { intl } = this.props;
    const optionsOriginal = [
      { label: intl.formatMessage({ id: "dart.origin" }), value: "true" },
      { label: intl.formatMessage({ id: "dart.non-origin" }), value: "false" }
    ];
    const optionsLang = LANGUAGES.map(l => {
      const label = l.name + " - " + l.nativeName;
      const value = l.code;
      return { label, value };
    });
    const langLocal = localStorage.getItem("Local") || "en";
    const optionsCategory = CATEGORY.map(c => {
      const label = c[langLocal];
      const value = c["en"];
      return { label, value };
    });
    const titlePlaceholder = intl.formatMessage({ id: "dart.title" });
    const copyrightInfo = intl.formatMessage({ id: "dart.copyright" });
    const authorPlaceholder = intl.formatMessage({ id: "dart.art-author" });
    const authorInfo = intl.formatMessage({ id: "dart.author-origin" });
    const linkPlaceholder = intl.formatMessage({ id: "dart.author-link" });
    const linkInfo = intl.formatMessage({ id: "dart.link-origin" });
    const categorySelect = intl.formatMessage({ id: "dart.category" });
    const seoPlaceholder = intl.formatMessage({ id: "dart.tag" });
    const seoInfo = intl.formatMessage({ id: "dart.tag-seo" });
    const draftInfo = intl.formatMessage({ id: "dart.draft" });
    const privInfo = intl.formatMessage({ id: "dart.priv" });
    const langSelect = intl.formatMessage({ id: "dart.lang" });
    return (
      <div className="article-create">
        <TextField
          name="title"
          placeholder={titlePlaceholder}
          type="text"
          value={title}
          onChange={this.handleChange}
          error={errors.title}
        />
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <SelectList
              name="original"
              value={original}
              onChange={this.handleChange}
              error={errors.original}
              options={optionsOriginal}
              info={copyrightInfo}
            />
          </div>
          <div
            className={`col-md-3 col-sm-6 col-xs-12 ${
              original === "true" ? "active" : ""
            }`}
          >
            <TextField
              name="author"
              placeholder={authorPlaceholder}
              type="text"
              value={author}
              onChange={this.handleChange}
              error={errors.author}
              info={authorInfo}
            />
          </div>
          <div
            className={`col-md-3 col-sm-6 col-xs-12 ${
              original === "true" ? "active" : ""
            }`}
          >
            <TextField
              name="link"
              placeholder={linkPlaceholder}
              type="text"
              value={link}
              onChange={this.handleChange}
              error={errors.link}
              info={linkInfo}
            />
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <SelectList
              name="category"
              value={category}
              onChange={this.handleChange}
              error={errors.category}
              options={optionsCategory}
              info={categorySelect}
            />
          </div>
        </div>
        <TextField
          name="seo"
          onChange={this.handleSEO}
          placeholder={seo.join(",") || seoPlaceholder}
          value={seo.join(",")}
          info={seoInfo}
          error={errors.seo}
        />

        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-draft"
          editorClassName="editor-draft shadow"
          toolbarClassName="toolbar-draft"
          onEditorStateChange={this.onEditorStateChange}
          localization={{ locale: langLocal }}
          toolbar={{
            options: [
              "inline",
              "textAlign",
              "fontSize",
              "fontFamily",
              "colorPicker",
              "link",
              "emoji",
              "image",
              "history"
            ],
            link: { options: ["link"] },
            image: {
              alt: { present: true, mandatory: true },
              previewImage: true,
              uploadCallback: this.uploadImageCallBack
            }
          }}
        />
        {errors.edit && <p className="text-danger">{errors.edit}</p>}
        <p className="text-muted text-right">
          <span className={length > limit ? "text-danger" : ""}>{length}</span>/
          {limit}
        </p>
        <div className="row">
          <div className="col-md-4 col-sm-4 col-xs-6">
            <CheckBoxModern
              onAgree={this.handleDraft}
              checked={draft}
              content={draftInfo}
              name="draft"
              error={errors.draft}
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-6">
            <CheckBoxModern
              onAgree={this.handlePriv}
              checked={priv}
              content={privInfo}
              name="priv"
              error={errors.priv}
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-6">
            <SelectList
              name="lang"
              value={lang}
              onChange={this.handleChange}
              error={errors.lang}
              options={optionsLang}
              info={langSelect}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={e => this.handleSave(e)}
          className="btn btn-outline-secondary btn-block"
        >
          {draft ? (
            <FormattedMessage id="dart.draft" defaultMessage="Save draft" />
          ) : (
            <FormattedMessage
              id="dart.publish"
              defaultMessage="Save & Publish"
            />
          )}
        </button>
      </div>
    );
  }
}
ArticleCreate.propTypes = {
  intl: intlShape.isRequired,
  create_article: PropTypes.func.isRequired,
  get_public_article: PropTypes.func,
  update_article: PropTypes.func,
  art: PropTypes.object,
  auth: PropTypes.object,
  errors: PropTypes.object
};
const mapStateToProps = state => ({
  errors: state.errors,
  art: state.art,
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { create_article, get_public_article, update_article }
)(withRouter(injectIntl(ArticleCreate)));
